import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';

import SolutionDetail from '../sections/SolutionDetail';

import EtexApi from '../../services/EtexApi';
import { LangContext } from '../../context/lang/langState';


const SingleSolution = () => {
  const { solutionId } = useParams();
  const { apiLanguage } = useContext(LangContext);
  const systemVariantId = solutionId;

  const [showLoader, setShowLoader] = useState(true);
  const [systemVariant, setSystemVariant] = useState(null);

  useEffect(() => {
    const getSystemVariantData = async () => {
      const systemVariantData = await EtexApi.getSystemVariant(systemVariantId, apiLanguage);
      setSystemVariant(systemVariantData);
      setShowLoader(false);
    }

    getSystemVariantData()
  }, [systemVariantId, apiLanguage]);

  return(
    <Fragment>
      <div className={"steps-wrapper"}>
        {showLoader || !systemVariant ? <div className="loading"></div> : <SolutionDetail singleSystemVariant={systemVariant} />}
      </div>
    </Fragment>
  );
}

export default SingleSolution;
