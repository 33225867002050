import React from 'react';
import PropTypes from 'prop-types';
import { MdInfoOutline, MdErrorOutline } from 'react-icons/md';

const Alert = ({ type, message }) => {
  let alertClasses = 'alert';
  let icon;

  if(type === 'success') {
    alertClasses += ' alert--success';
    icon = <i className="custom-icon-success"></i>;
  }else if(type === 'warning') {
    alertClasses += ' alert--warning';
    icon = <i className="custom-icon-warning"></i>;
  }else if(type === 'error') {
    alertClasses += ' alert--error';
    icon = <MdErrorOutline />;
  }else if(type === 'info') {
    alertClasses += ' alert--info';
    icon = <MdInfoOutline />;
  }

  return(
    <div className={alertClasses}>
      <div className="alert__icon">
        {icon}
      </div>
      {message}
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default Alert;