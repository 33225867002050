const axios = require('axios').default;
const config = require('../config.json')

export const FILTER_PARAM_KEYS = [
  "fireStoppingType",
  "compartmentationType",
  "compartmentationThickness",
  "fireResistance",
  "penetrationPosition",
  "penetrationProductType",
  "penetrationMaterial",
  "diameter",
  "insulationType"
]

export const API_KEY_MAP = {
  fireStoppingType: "SysVarFireStoppingType",
  compartmentationType: "SysVarCompartmentationType",
  compartmentationThickness: "SysVarCompartmentationThickness",
  fireResistance: "SysVarFireResistance",
  penetrationPosition: "SysVarPenetrationPosition",
  penetrationProductType: "SysVarPenetrationProductType",
  penetrationMaterial: "SysVarPenetrationMaterial",
  diameter: "SysVarProductDiameter",
  insulationType: "SysVarPipeInsulationType",
  market: "SysVarMarket",
  // product: "product"
}

export const API_KEY_SYSTEM_MAP = {
  fireStoppingType: "FireStoppingType",
  compartmentationType: "compartmentationType",
  compartmentationThickness: "compartmentationThickness",
  fireResistance: "fireResistance",
  penetrationPosition: "penetrationPosition",
  penetrationProductType: "penetrationProductType",
  penetrationMaterial: "penetrationMaterial",
  diameter: "productDiameter",
  insulationType: "pipeInsulationType",
  market: "market",
  name: "name"
  // product: "product"
}

export const API_KEY_REVERSE_MAP = {
  sysVarFireStoppingType: "fireStoppingType",
  sysVarCompartmentationType: "compartmentationType",
  sysVarCompartmentationThickness: "compartmentationThickness",
  sysVarFireResistance: "fireResistance",
  sysVarPenetrationPosition: "penetrationPosition",
  sysVarPenetrationProductType: "penetrationProductType",
  sysVarPenetrationMaterial: "penetrationMaterial",
  sysVarProductDiameter: "diameter",
  sysVarPipeInsulationType: "insulationType",
  sysVarMarket: "market",
  // product: "product"
}

// export const API_KEY_SYSTEM_REVERSE_MAP = { // for system instead of systemvariant
//   fireStoppingType: "FireStoppingType",
//   compartmentationType: "compartmentationType",
//   compartmentationThickness: "compartmentationThickness",
//   fireResistance: "fireResistance",
//   penetrationPosition: "penetrationPosition",
//   penetrationProductType: "penetrationProductType",
//   penetrationMaterial: "penetrationMaterial",
//   productDiameter: "diameter",
//   pipeInsulationType: "insulationType",
//   market: "market",
//   name: "name"
//   // product: "product"
// }
export const API_KEY_SYSTEM_REVERSE_MAP = { // for system instead of systemvariant
  systemFireStoppingType: "systemFireStoppingType",
  systemCompartmentationType: "systemCompartmentationType",
  systemCompartmentationThickness: "systemCompartmentationThickness",
  systemFireResistance: "systemFireResistance",
  systemPenetrationPosition: "systemPenetrationPosition",
  systemPenetrationProductType: "systemPenetrationProductType",
  systemPenetrationMaterial: "systemPenetrationMaterial",
  systemProductDiameter: "systemDiameter",
  systemPipeInsulationType: "systemInsulationType",
  systemMarket: "systemMarket",
  name: "name"
  // product: "product"
}

export const NAME_TO_PARAM_KEY = { // for system instead of systemvariant
  systemFireStoppingType: "FireStoppingType",
  systemCompartmentationType: "systemCompartmentationType",
  systemCompartmentationThickness: "systemCompartmentationThickness",
  systemFireResistance: "fireResistance",
  systemPenetrationPosition: "penetrationPosition",
  systemPenetrationProductType: "penetrationProductType",
  systemPenetrationMaterial: "penetrationMaterial",
  systemProductDiameter: "diameter",
  systemPipeInsulationType: "insulationType",
  systemMarket: "market",
  name: "name"
  // product: "product"
}

const CLOUD_FUNCTIONS_URL = 'https://us-central1-promat-product-selector-web.cloudfunctions.net'
// const CLOUD_FUNCTIONS_URL = 'http://localhost:5001/promat-product-selector-web/us-central1'

// const FIREBASE_SEARCH_SYSTEMVARIANT_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_systemvariant_dev`;
const FIREBASE_GET_SYSTEMVARIANTS_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/get_systemvariants`;
const FIREBASE_GET_SYSTEMS_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/get_systems`;
// const FIREBASE_GET_PRODUCTS_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/get_products`;
const FIREBASE_FETCH_ASSET_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/fetch_asset`;
const FIREBASE_SEND_EMAIL_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/send_email`;
const FIREBASE_SUBMIT_CONSENT_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/submit_consent`;
const FIREBASE_DOWNLOAD_ASSET_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/download_asset`
// const FIREBASE_SEARCH_SYSTEM_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_system`;
// const FIREBASE_SEARCH_SYSTEM_FACET_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_system_for_facet_query`
const FIREBASE_SEARCH_SYSTEM_FACET_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_systemvariants_for_facet_query`
const FIREBASE_SEARCH_SYSTEM_OVERVIEW_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_system_for_product_overview`
const FIREBASE_SEARCH_SYSTEMVARIANT_FOR_SOLUTIONS_ENDPOINT = `${CLOUD_FUNCTIONS_URL}/search_systemvariants_for_solutions`
const FIREBASE_FETCH_FACET_OPTIONS = `${CLOUD_FUNCTIONS_URL}/get_field_options`

const filterAttributes = (steps, type = 'systemvariant') => {
  const attributes = [];

  let dictionary = type === 'system' ? API_KEY_SYSTEM_MAP : API_KEY_MAP 

  for (const step of steps) {
    // if(step.stepKey === 'country') {
    //   // let country = 'BE';
    //   // if(step.key === 'Luxembourg') {
    //   //   country = 'LU';
    //   // }else if(step.key === 'Nederland' || step.key === 'Netherlands') {
    //   //   country = 'NL';
    //   // }
    //   let country = step.key.split('-')[1]
    //   attributes.push(`${API_KEY_MAP.market}.eq.${country}`);
    //   continue;
    // }
    // special handling of compartmentation
    if (step.stepKey === 'compartmentation') {
      attributes.push(`${dictionary.compartmentationType}.eq.${step.key.compartmentationType}`);
      attributes.push(`${dictionary.compartmentationThickness}.eq.${step.key.compartmentationThickness}`);
      continue;
    }
    
    // if (!Object.keys(PARAMS).includes(step.stepKey)) continue;
    if (!FILTER_PARAM_KEYS.includes(step.stepKey)) continue;
    
    attributes.push(`${dictionary[step.stepKey]}.eq.${step.key}`);
  }

  return attributes.join('/');
}

const facetAttributes = (steps) => {
  const stepKey = steps[steps.length - 1].stepKey;
  // let dictionary = API_KEY_SYSTEM_MAP
  let dictionary = API_KEY_MAP

  if(stepKey === 'country') {
    return [{ attribute: dictionary['fireStoppingType']}]
  }

  if (stepKey === 'fireStoppingType') {
    return [{ attribute: dictionary['compartmentationType'] }, { attribute: dictionary['compartmentationThickness'] }];
  }

  if (stepKey === 'compartmentation') {
    return [{ attribute: dictionary['fireResistance'] }];
  }

  if (stepKey === 'fireResistance') {
    return [{ attribute: dictionary['penetrationProductType'] }];
  }

  if (stepKey === 'penetrationProductType') {
    return [{ attribute: dictionary['penetrationMaterial'] }];
  }

  // throw 'Unknown facet attribute';
  return []
}

const prepareFacetQuery = (steps) => {
  return {
    filter: filterAttributes(steps),
    facets: facetAttributes(steps),
    pageSize: 0,
  }
}

const prepareFinalSearchQuery = (steps, page = 1) => {
  return {
    filter: filterAttributes(steps, 'system'),
    // pageSize: 48, // largest number smaller than 50 and divisible by 4
    pageSize: 50,
    page: page,
  }
}

const parseSystemVariant = (systemVariant, language = 'en') => {
  const parsed = {
    id: systemVariant.id,
    name: systemVariantName(parsedSysVarName(systemVariant.name, language), systemVariant.fields, language),
    sysVarName: parsedSysVarName(systemVariant.name, language),
    relatedProductIds: systemVariant.relations.sysVarProducts,
    relatedSystemIds: systemVariant.relations.systems,
    relatedResourceIds: systemVariant.relations.sysVarResources,
    relatedDocumentIds: systemVariant.relations.sysVarDocuments,
    fields: systemVariant.fields,
  }
  return parsed
}

const parsedSysVarName = (sysVarName, apiLanguage) => {
  if(!sysVarName) {
    return ''
  }
  let name = sysVarName
  if(typeof name === 'string') {
    return name
  } else if(typeof name === 'object' && name[apiLanguage]) {
    name = name[apiLanguage]
  } else if(typeof name === 'object' && name.en) {
    name = name.en
  } else if(typeof name === 'object' && name.value?.rawValue) {
    name = name.value.rawValue
  }
  if(!name || typeof name !== 'string') {
    return ''
  }
  return name
}

const systemVariantName = (sysVarName, fields, language) => {
  let commercialName = fields.sysVarCommercialName?.value.displayValue || { [language]: '' }
  // let servicePart = fields.sysVarServicePartType?.value.displayValue || { [language]: '' }
  let aperturePart = fields.sysVarAperturePartType?.value.displayValue || { [language]: '' }
  let penetrationMaterial = fields.sysVarPenetrationMaterial?.value.displayValue || { [language]: '' }
  let productDiameter = fields.sysVarProductDiameter?.value || {}
  let insulationInPlace = fields.sysVarInsulationInPlaceType?.value.displayValue || { [language]: '' }

  let name = ''
  if(commercialName[language] || commercialName.en) {
    name += commercialName[language] || commercialName.en
  } else if(sysVarName) {
    name += sysVarName
  }
  // if(servicePart[language] || servicePart.en) {
  //   name += name ? ', ' : ''
  //   name += (servicePart[language] || servicePart.en)
  // }
  if(aperturePart[language] || aperturePart.en) {
    name += name ? ', ' : ''
    name += (aperturePart[language] || aperturePart.en)
  }
  if(penetrationMaterial[language] || penetrationMaterial.en) {
    name += name ? ', ' : ''
    name += (penetrationMaterial[language] || penetrationMaterial.en)
  }
  if(productDiameter.rawValue && productDiameter.unit) {
    name += name ? ' ' : ''
    name += `ø${productDiameter.rawValue}${productDiameter.unit}`
  }
  if(insulationInPlace[language] || insulationInPlace.en) {
    name += name ? ' + ' : ''
    name += (insulationInPlace[language] || insulationInPlace.en)
  }
  return { [language]: name }
}

// TODO: add try/catch
const getFacets = async (steps, language, country, customFacets) => {
  try {
    const facetQuery = prepareFacetQuery(steps);
    if(customFacets) {
      facetQuery.facets = []
      for(let f of customFacets) {
        // facetQuery.facets.push({ attribute: API_KEY_SYSTEM_MAP[f] })
        facetQuery.facets.push({ attribute: f })
      }
    }
    facetQuery.language = language
    facetQuery.market = country

    const apiResponse = await postRequest(FIREBASE_SEARCH_SYSTEM_FACET_ENDPOINT, facetQuery);
    const facets = {}

    apiResponse.data.facets.forEach((facetData) => {
      let name = facetData.name
      facets[API_KEY_REVERSE_MAP[name]] = facetData.values ? facetData.values.map(facetValue => facetValue.value) : [];
    });

    return { facets: facets, count: apiResponse.data.totalItemCount };
  } catch(err) {
    console.log('err 0')
    console.log(err)
  }
}

const systemVariantsCache = {};
const systemsCache = {};

const getSystems = async (steps, language, country) => {
    try {
    const facetQuery = prepareFinalSearchQuery(steps, 1);
    facetQuery.language = language
    facetQuery.market = country

    const apiResponse = await postRequest(FIREBASE_SEARCH_SYSTEM_OVERVIEW_ENDPOINT, facetQuery);
    const facets = {}
    const systems = {}

    apiResponse.data.facets.forEach((facetData) => {
      const paramKey = API_KEY_SYSTEM_REVERSE_MAP[facetData.name];
      facets[paramKey] = facetData.values?.map(facetValue => facetValue.value);
    });

    if(apiResponse.data.results) {
      apiResponse.data.results.forEach((systemResultData) => {
        const item = systemResultData.item
        if(!item) {
          return
        }
        systems[item.itemId] = item
      })
    }

    return { facets: facets, count: apiResponse.data.totalItemCount, systems };
  } catch(err) {
    console.log('err 0')
    console.log(err)
  }
}

const getSystem = async (systemId, language) => {
  if(!systemsCache[language]) {
    systemsCache[language] = {}
  }
  if (!systemsCache[language][systemId]) {
    const systemResponse = await postRequest(FIREBASE_GET_SYSTEMS_ENDPOINT, { systemIds: [systemId] });
    let systemData = systemResponse.data[0]
    // const parsedSystemVariant = parseSystemVariant(systemVariantDetails.data[0]);
    systemsCache[language][systemId] = systemData;
  }

  return systemsCache[language][systemId];
}

const getSystemVariants = async (steps, language, country, productId) => {
  let page = 1;
  // const systemVariantDetails = fakeSystemVariants();

  const finalSearchQuery = prepareFinalSearchQuery(steps, page);
  finalSearchQuery.language = language
  finalSearchQuery.market = country
  let apiResponse
  try {
    apiResponse = await postRequest(FIREBASE_SEARCH_SYSTEMVARIANT_FOR_SOLUTIONS_ENDPOINT, { ...finalSearchQuery, productId });
  } catch (err) {
    console.log('err')
    console.log(err)
  }
  
  let systemVariantIds = [...new Set(apiResponse.data.results?.map((resultRow) => resultRow.item.itemId))];

  const totalPageCount = apiResponse.data.totalPageCount
  if (totalPageCount > 1) {
    let nextRequests = [];
    let nextPage = 2;

    while (nextPage <= totalPageCount) {
      let nextQuery = prepareFinalSearchQuery(steps, nextPage);
      nextQuery.language = language
      nextQuery.market = country
      nextRequests.push(postRequest(FIREBASE_SEARCH_SYSTEMVARIANT_FOR_SOLUTIONS_ENDPOINT, { ...nextQuery, productId, language }))

      nextPage += 1;
    }

    let nextApiResponses
    try {
      nextApiResponses = await axios.all(nextRequests)
    } catch(err) {
      console.log('err 2')
      console.log(err)
    }

    if(nextApiResponses) {
      for (let nextResponse of nextApiResponses) {
        systemVariantIds = [...new Set(systemVariantIds.concat(nextResponse.data.results.map((resultRow) => resultRow.item.itemId)))];
      }
    }
  }

  if(!systemVariantsCache[language]) {
    systemVariantsCache[language] = {}
  }

  let existingIds = systemVariantIds.filter(systemVariantId => Object.keys(systemVariantsCache[language]).includes(systemVariantId));
  let newIds = systemVariantIds.filter(systemVariantId => !Object.keys(systemVariantsCache[language]).includes(systemVariantId));

  let parsedSystemVariantData = []
  const getSystemvariantsMaxSize = 100;
  if (newIds.length > 0) {
    const detailsPromises = []
    const pages = Math.ceil(newIds.length / getSystemvariantsMaxSize)
    for(let i = 0; i < pages; i++) {
      detailsPromises.push(postRequest(FIREBASE_GET_SYSTEMVARIANTS_ENDPOINT, { systemVariantIds: newIds.slice(i*getSystemvariantsMaxSize, Math.min((i+1) * getSystemvariantsMaxSize, newIds.length)), language }))
    }
    const detailsResults = await Promise.all(detailsPromises)
    for(let i = 0; i < detailsResults.length; i++) {
      parsedSystemVariantData = [...parsedSystemVariantData, ...detailsResults[i].data.map((systemVariant) => parseSystemVariant(systemVariant, language))];
    }
  }

  let systemVariantResults = systemVariantIds.map((systemVariantId) => {
    if (!existingIds.includes(systemVariantId)) {
      systemVariantsCache[language][systemVariantId] = parsedSystemVariantData.find((systemVariant) => systemVariant.id === systemVariantId);
    }

    return systemVariantsCache[language][systemVariantId];
  })

  // let productIds = [];
  // for (const systemVariant of systemVariantResults) {
  //   productIds = [...new Set(productIds.concat(systemVariant.relatedProductIds ? systemVariant.relatedProductIds : []))];
  // }

  // let existingProductIds = productIds.filter(productId => Object.keys(productsCache).includes(productId));
  // let newProductIds = productIds.filter(productId => !Object.keys(productsCache).includes(productId));
  // let parsedProductsData = []
  // if (newProductIds.length > 0) {
  //   const productDetails = await postRequest(FIREBASE_GET_PRODUCTS_ENDPOINT, { productIds: newProductIds });
  //   parsedProductsData = productDetails.data.map((product) => product);
  // }

  // let productResults = productIds.map((productId) => {
  //   if (!existingProductIds.includes(productId)) {
  //     productsCache[productId] = parsedProductsData.find((product) => product.id === productId);
  //   }

  //   return productsCache[productId];
  // })

  return { variants: systemVariantResults.filter(f => !!f).sort((systemVariant) => systemVariant.id) };
}

const getSystemVariant = async (systemVariantId, language) => {
  if(!systemVariantsCache[language]) {
    systemVariantsCache[language] = {}
  }
  if (!systemVariantsCache[language][systemVariantId]) {
    const systemVariantDetails = await postRequest(FIREBASE_GET_SYSTEMVARIANTS_ENDPOINT, { systemVariantIds: [systemVariantId] });
    const parsedSystemVariant = parseSystemVariant(systemVariantDetails.data[0], language);
    systemVariantsCache[language][systemVariantId] = parsedSystemVariant;
  }

  return systemVariantsCache[language][systemVariantId];
}

const assetUrl = (resourceId, assetType) => {
  if (!resourceId) return null;

  return `${FIREBASE_FETCH_ASSET_ENDPOINT}?assetId=${resourceId}&assetType=${assetType}`
}

const downloadAssetUrl = (resourceId, assetType) => {
  if (!resourceId) return null;
  return `${FIREBASE_DOWNLOAD_ASSET_ENDPOINT}?assetId=${resourceId}&assetType=${assetType}`
}

const fetchDocument = async (resourceId, assetType) => {
  // var fetchFunction = firebase.functions().httpsCallable('fetch_content_headers');
  // return await fetchFunction({ assetId: resourceId, assetType })

  try {
    // let result = await fetchFunction({ assetId: resourceId, assetType })
    let response = await postRequest(`${CLOUD_FUNCTIONS_URL}/fetch_document`, { 
      assetId: resourceId,
      assetType
    })
    return response 
  } catch(err) {
    console.log('fetch document error', err)
    return { error: err }
  }
}

const sendEmail = async (name, email, message, language) => {
  await postRequest(FIREBASE_SEND_EMAIL_ENDPOINT, { name, email, message, language, environment: config.env });
  return true;
}

const submitConsent = async (email, marketing, language) => {
  await postRequest(FIREBASE_SUBMIT_CONSENT_ENDPOINT, { email, marketing, language, environment: config.env })
  return true;
}

const getFieldOptions = async (type) => {
  try {
    const result = await axios.post(FIREBASE_FETCH_FACET_OPTIONS, { facetType: type })
    return result.data
  } catch(err) {
    console.log(err)
  }
}

const postRequest = async (url, body) => {
  return await axios.post(url, {
    ...body,
    environment: config.env !== 'development' ? 'production' : 'development'
  })
}

const EtexApi = {
  getFacets,
  getSystems,
  getSystemVariant,
  getSystemVariants,
  assetUrl,
  sendEmail,
  fetchDocument,
  submitConsent,
  downloadAssetUrl,
  getFieldOptions,
  getSystem
}

export default EtexApi
