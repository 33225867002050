import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MdReplay, MdSearch, MdKeyboardArrowLeft } from 'react-icons/md';

import Button from '../UI/Button';
import Translate from '../../i18n/Translate';
import { StepsContext } from '../../context/stepsState';

const Intro = ({ title, subtitle, showButtons, step, onResetSelector }) => {
  const { stepLoading, setStep } = useContext(StepsContext);

  return(
    <section className={showButtons ? step === 8 ? "intro with-buttons--2" : "intro with-buttons" : "intro"}>
      <div className="intro__left">
        <h1 className="intro__title">{title}</h1>
        <p className="intro__subtitle is-primary">{subtitle}</p>
      </div>
      {showButtons &&
        <div className="intro__right">
          {step === 8 && <Button
              text={<Translate name="backToSolutions" />}
              onButtonClick={() => setStep(7)}
              className="mr-1 btn--icon-animate"
              iconEl={<MdKeyboardArrowLeft />}
            />}
          {step > 1 && step < 9 &&
            <div className="d-flex justify-content-end">
              <Button
                text={<Translate name="resetTheSelector" />}
                iconEl={<MdReplay />}
                onButtonClick={onResetSelector}
                rotateIconOnHover
                className="hide-text-xs"
                disabled={stepLoading}
              />
            </div>
          }
        </div>
      }
    </section>
  );
}

Intro.defaultProps = {
  title: <Translate name="appTitle" />,
  subtitle: <Translate name="appSubtitle" />
}

Intro.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showButtons: PropTypes.bool,
  step: PropTypes.number,
  onResetSelector: PropTypes.func,
}

export default Intro;