import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ small, primary, normal, normalWhite }) => {
  let loaderClasses = 'loader';
  if(small) {
    loaderClasses += ' loader--small';
  }

  if(primary) {
    loaderClasses += ' loader--primary';
  }

  if(normal) {
    loaderClasses += ' loader--normal';
  }

  if(normalWhite) {
    loaderClasses += ' loader--normal-white';
  }

  return(
    <div className={loaderClasses}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

Loader.propTypes = {
  small: PropTypes.bool,
  primary: PropTypes.bool,
  normal: PropTypes.bool
}

export default Loader;