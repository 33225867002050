import React, { createContext, useReducer, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie'

import { SET_LANGUAGE } from '../types';
import de from '../../i18n/de.json'
import da_dk from '../../i18n/da-dk.json'
import en from '../../i18n/en.json';
import es_es from '../../i18n/es-es.json';
import fr_be from '../../i18n/fr-be.json';
import hu_hu from '../../i18n/hu-hu.json';
import it from '../../i18n/it.json';
import nl_be from '../../i18n/nl-be.json';
import nl_nl from '../../i18n/nl-nl.json';
import pl from '../../i18n/pl.json';
import ro from '../../i18n/ro.json';
import sv_se from '../../i18n/sv-se.json';
import pt_br from '../../i18n/pt-br.json';

import austria from '../../assets/images/flags/austria.svg';
import belgium from '../../assets/images/flags/belgium.svg';
import germany from '../../assets/images/flags/germany.svg';
import netherlands from '../../assets/images/flags/netherlands.svg';
import poland from '../../assets/images/flags/poland.svg';
import italy from '../../assets/images/flags/italy.svg';
import spain from '../../assets/images/flags/spain.svg';
import luxembourg from '../../assets/images/flags/luxembourg.svg'
import brazil from '../../assets/images/flags/brazil.svg'
import argentina from '../../assets/images/flags/argentina.svg'
import chile from '../../assets/images/flags/chile.svg'
import colombia from '../../assets/images/flags/colombia.svg'
import peru from '../../assets/images/flags/peru.svg'
import denmark from '../../assets/images/flags/denmark.svg'
import sweden from '../../assets/images/flags/sweden.svg'
import croatia from '../../assets/images/flags/croatia.svg'
import hungary from '../../assets/images/flags/hungary.svg'
import romania from '../../assets/images/flags/romania.svg'
import slovakia from '../../assets/images/flags/slovakia.svg'
import slovenia from '../../assets/images/flags/slovenia.svg'
import serbia from '../../assets/images/flags/serbia.svg'
import lithuania from '../../assets/images/flags/lithuania.svg'
import latvia from '../../assets/images/flags/latvia.svg'
import ecuador from '../../assets/images/flags/ecuador.svg'
import australia from '../../assets/images/flags/australia.svg'

import config from '../../config.json'
import { PROMAT_LANGUAGE } from '../../constants/constants';

const langReducer = (state, action) => {
  switch(action.type) {
    case SET_LANGUAGE:
      return {
        language: action.payload.language,
        transformedLanguage: transformBrowserLanguage(action.payload.language),
        country: action.payload.country
      }
    default:
      return state;
  }
};

export const LangContext = createContext();

const countries = [];
countries.push({ name: 'Belgium', flag: <img src={belgium} alt="Belgium" />, lang: 'en-BE', region: 'europe' })
countries.push({ name: 'België', flag: <img src={belgium} alt="Belgium" />, lang: 'nl-BE', region: 'europe' })
countries.push({ name: 'Belgique', flag: <img src={belgium} alt="Belgium" />, lang: 'fr-BE', region: 'europe' })
countries.push({ name: 'Luxembourg', flag: <img src={luxembourg} alt="Luxembourg" />, lang: 'en-LU', region: 'europe' })
countries.push({ name: 'Luxembourg', flag: <img src={luxembourg} alt="Luxembourg" />, lang: 'fr-LU', region: 'europe' })
countries.push({ name: 'Netherlands', flag: <img src={netherlands} alt="Netherlands" />, lang: 'en-NL', region: 'europe' })
countries.push({ name: 'Nederland', flag: <img src={netherlands} alt="Netherlands" />, lang: 'nl-NL', region: 'europe' })
countries.push({ name: 'Austria', flag: <img src={austria} alt="Austria" />, lang: 'en-AT', region: 'europe' })
countries.push({ name: 'Österreich', flag: <img src={austria} alt="Austria" />, lang: 'de-AT', region: 'europe' })
countries.push({ name: 'Brasil', flag: <img src={brazil} alt="Brasil" />, lang: 'en-BR', region: 'latin_america' })
countries.push({ name: 'Italy', flag: <img src={italy} alt="Italy" />, lang: 'en-IT', region: 'europe' })
countries.push({ name: 'Italia', flag: <img src={italy} alt="Italy" />, lang: 'it-IT', region: 'europe' })
countries.push({ name: 'Poland', flag: <img src={poland} alt="Poland" />, lang: 'en-PL', region: 'europe' })
countries.push({ name: 'Polska', flag: <img src={poland} alt="Poland" />, lang: 'pl-PL', region: 'europe' })
countries.push({ name: 'Argentina', flag: <img src={argentina} alt="Argentina" />, lang: 'en-AR', region: 'latin_america' })
countries.push({ name: 'Argentina', flag: <img src={argentina} alt="Argentina" />, lang: 'es-AR', region: 'latin_america' })
countries.push({ name: 'Chile', flag: <img src={chile} alt="Chile" />, lang: 'en-CL', region: 'latin_america' })
countries.push({ name: 'Chile', flag: <img src={chile} alt="Chile" />, lang: 'es-CL', region: 'latin_america' })
countries.push({ name: 'Colombia', flag: <img src={colombia} alt="Colombia" />, lang: 'en-CO', region: 'latin_america' })
countries.push({ name: 'Colombia', flag: <img src={colombia} alt="Colombia" />, lang: 'es-CO', region: 'latin_america' })
countries.push({ name: 'Peru', flag: <img src={peru} alt="Peru" />, lang: 'en-PE', region: 'latin_america' })
countries.push({ name: 'Perú', flag: <img src={peru} alt="Peru" />, lang: 'es-PE', region: 'latin_america' })
countries.push({ name: 'Germany', flag: <img src={germany} alt="Germany" />, lang: 'en-DE', region: 'europe' })
countries.push({ name: 'Deutschland', flag: <img src={germany} alt="Germany" />, lang: 'de-DE', region: 'europe' })
countries.push({ name: 'Denmark', flag: <img src={denmark} alt="Denmark" />, lang: 'en-DK', region: 'europe' })
countries.push({ name: 'Danmark', flag: <img src={denmark} alt="Denmark" />, lang: 'da-DK', region: 'europe' })
countries.push({ name: 'Sweden', flag: <img src={sweden} alt="Sweden" />, lang: 'en-SE', region: 'europe' })
countries.push({ name: 'Sverige', flag: <img src={sweden} alt="Sweden" />, lang: 'sv-SE', region: 'europe' })
countries.push({ name: 'Croatia', flag: <img src={croatia} alt="Croatia" />, lang: 'en-HR', region: 'europe' })
countries.push({ name: 'Hungary', flag: <img src={hungary} alt="Hungary" />, lang: 'en-HU', region: 'europe' })
countries.push({ name: 'Magyarország', flag: <img src={hungary} alt="Hungary" />, lang: 'hu-HU', region: 'europe' })
countries.push({ name: 'Romania', flag: <img src={romania} alt="Romania" />, lang: 'en-RO', region: 'europe' })
countries.push({ name: 'România', flag: <img src={romania} alt="Romania" />, lang: 'ro-RO', region: 'europe' })
countries.push({ name: 'Slovakia', flag: <img src={slovakia} alt="Slovakia" />, lang: 'en-SK', region: 'europe' })
countries.push({ name: 'Slovenia', flag: <img src={slovenia} alt="Slovenia" />, lang: 'en-SI', region: 'europe' })
countries.push({ name: 'Serbia', flag: <img src={serbia} alt="Serbia" />, lang: 'en-RS', region: 'europe' })
countries.push({ name: 'España', flag: <img src={spain} alt="Spain" />, lang: 'es-ES', region: 'europe'})
countries.push({ name: 'Ecuador', flag: <img src={ecuador} alt="Ecuador" />, lang: 'en-EC', region: 'latin_america'})
countries.push({ name: 'Ecuador', flag: <img src={ecuador} alt="Ecuador" />, lang: 'es-EC', region: 'latin_america'})
countries.push({ name: 'Lithuania', flag: <img src={lithuania} alt="Lithuania" />, lang: 'en-LT', region: 'europe'})
countries.push({ name: 'Latvia', flag: <img src={latvia} alt="Latvia" />, lang: 'en-LV', region: 'europe'})
countries.push({ name: 'Brasil', flag: <img src={brazil} alt="Brasil" />, lang: 'pt-BR', region: 'latin_america' })

if(config.env !== 'production') {
  countries.push({ name: 'Australia', flag: <img src={australia} alt="Australia" />, lang: 'en-AU', region: 'asia_pacific'})
}

const transformBrowserLanguage = (language) => {
  if(language.split('-')[0] === 'en') {
    return 'en'
  }

  return language;
}

const LangState = (props) => {
  const initialState = {
    language: 'en-BE',
    transformedLanguage: transformBrowserLanguage('en-BE'),
    country: 'Belgium'
  }

  const [cookies] = useCookies([PROMAT_LANGUAGE])
  const [languageSelected, setLanguageSelected] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [state, dispatch] = useReducer(langReducer, initialState);

  const supportedLocales = countries.map(c => c.lang)

  const setLanguage = (lang, country) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: {
        language: lang,
        country: country
      }
    });
  }

  // Set languageSelected to true if lang is already saved in a cookie
  useEffect(() => {
    if(cookies[PROMAT_LANGUAGE]) {
      setLanguageSelected(true)
    }
  }, [])

  const translate = (key) => {
    // const lang = {...state}.language.split('-')[0];
    const langFull = {...state}.language
    const lang = langFull.split('-')[0]
    let langData;

    if(lang === 'en') {
      langData = en;
    }else if(lang === 'de') {
      langData = de;
    }else if(lang === 'fr') {
      langData = fr_be;
    }else if(langFull === 'nl-BE') {
      langData = nl_be
    } else if(langFull === 'nl-NL') {
      langData = nl_nl
    }else if(lang === 'pl') {
      langData = pl;
    }else if(lang === 'es') {
      langData = es_es;
    }else if(lang === 'it') {
      langData = it;
    }else if(lang === 'sv') {
      langData = sv_se
    }else if(lang === 'da') {
      langData = da_dk
    }else if(lang === 'hu') {
      langData = hu_hu
    }else if(lang === 'ro') {
      langData = ro
    }else if(lang === 'es') {
      langData = es_es
    }else if(lang === 'pt') {
      langData = pt_br
    } else {
      langData = en;
    }

    return langData[key] || key;
  }

  const apiTranslate = (langObject) => {
    const lang = {...state}.transformedLanguage;
    return langObject[lang] || langObject['en'];
  }

  return(
    <LangContext.Provider
      value={{
        language: state.language,
        apiLanguage: state.transformedLanguage,
        country: state.country,
        countries,
        languageSelected,
        selectedLanguage,
        supportedLocales,
        setLanguage,
        translate,
        apiTranslate,
        setLanguageSelected,
        setSelectedLanguage,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
}

export default LangState;