import { useState, useCallback, useContext } from 'react';
import { LangContext } from '../context/lang/langState';

const useForm = (initialState, validate) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { translate } = useContext(LangContext);

  const isValuesTouched = useCallback((name) => {
    for(let key in formData) {
      if(!formData[key].touched && key !== name) {
        return false;
      }
    }
    return true;
  }, [formData]);

  const setDataAndErrors = useCallback((data, name) => {
    setFormData(data);
    const errors = validate(data, false, translate);
    
    if(Object.keys(errors).length === 0 && isValuesTouched(name)) {
      setIsValid(true);
    }else {
      setIsValid(false);
    }
    setErrors(errors);
  }, [validate, isValuesTouched, translate]);

  const changeHandler = useCallback((e) => {
    e.persist();
    let updatedData;

    if(e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.checked,
          touched: true
        }
      }
    }else if(e.target.tagName === 'INPUT' && e.target.type === 'file') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: Object.keys(e.target.files).length > 0 ? e.target.files : {},
          touched: true
        }
      }
    }else {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
          touched: true
        }
      }
    }
    setDataAndErrors(updatedData, e.target.name);
  }, [setDataAndErrors, formData]);

  const customSelectChangeHandler = useCallback((name, value) => {
    const updatedData = {
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        touched: true
      }
    }
    
    setDataAndErrors(updatedData, name);
  }, [setDataAndErrors, formData]);

  return { formData, errors, changeHandler, customSelectChangeHandler, setErrors, isValid, setDataAndErrors };
}

export default useForm;