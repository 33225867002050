import React, { useContext } from 'react';
import { MdDone } from 'react-icons/md';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie'

import Button from '../UI/Button';
import TextLink from '../UI/TextLink';
import Translate from '../../i18n/Translate';
import { COOKIE_NAME, PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE } from '../../constants/constants';
import { LangContext } from '../../context/lang/langState'

const CookiesSection = ({ onCookieReply }) => {
  const { language, country, languageSelected } = useContext(LangContext)
  const [cookies, setCookie] = useCookies([PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE])

  const setCookieHandler = (accepted) => {
    let cookieObj = {};

    if(accepted) {
      cookieObj.consent = true;
      cookieObj.accepted = true;
      if(languageSelected) {
        setCookie(PROMAT_COUNTRY_COOKIE, country, { path: '/' })
        setCookie(PROMAT_LANGUAGE, language, { path: '/' })
      }
    }else {
      cookieObj.consent = true;
      cookieObj.accepted = false;
    }
    
    onCookieReply();
    Cookies.set(COOKIE_NAME, JSON.stringify(cookieObj), { expires: 180, path: '/' });
    window.location.reload();
  }

  return(
    <div className="cookies">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h6 className="cookies__title"><Translate name="cookiesTitle" /></h6>
            <div className="cookies__text"><Translate name="cookiesDesc" /></div>
            <div className="cookies__check">
              <div>
                <MdDone />
                <span><Translate name="cookieNecessary" /></span>
              </div>
              <div>
                <MdDone />
                <span><Translate name="cookiePreferences" /></span>
              </div>
              <div>
                <MdDone />
                <span><Translate name="cookieStatistics" /></span>
              </div>
            </div>
            <div className="cookies__actions">
              <Button text={<Translate name="cookieAccepted" />} primary onButtonClick={() => setCookieHandler(true)} />
              <Button text={<Translate name="cookieRejected" />} onButtonClick={() => setCookieHandler(false)} />
              <TextLink text={<Translate name="privacyPolicy" />} to="/cookie-policy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CookiesSection.propTypes = {
  onCookieReply: PropTypes.func.isRequired
}

export default CookiesSection;