import React, { useContext, useEffect } from 'react';

import AccountReadGroup from '../UI/AccountReadGroup';
import AccountReadField from '../UI/AccountReadField';
import Translate from '../../i18n/Translate';
import { UserContext } from '../../context/userState';
import countries from '../../constants/countries.json'

const MyCompanyRead = () => {

  const { user } = useContext(UserContext);

  const countryNameWithCode = (code) => {
    for(let i in countries) {
      if(countries[i].value === code) {
        return countries[i].label
      }
    }
    return ''
  }

  return(
    <div className="account-read">
      <div className="account-read__section">
        <AccountReadGroup>
          <AccountReadField label={<Translate name="name" />} value={user.company?.name} />
          <AccountReadField label={<Translate name="contactEmail" />} value={user.company?.email} />
        </AccountReadGroup>
        <AccountReadGroup>
          <AccountReadField label={<Translate name="phoneNumber" />} value={user.company?.phone} />
          <AccountReadField label={<Translate name="vatNumber" />} value={user.company?.vat_number} />
        </AccountReadGroup>
      </div>
      <div className="account-read__section">
        <AccountReadGroup>
          <AccountReadField label={<Translate name="street" />} value={user.company?.address_street} />
          <AccountReadField label={<Translate name="streetNumber" />} value={user.company?.address_number} />
        </AccountReadGroup>
        <AccountReadGroup>
          <AccountReadField label={<Translate name="city" />} value={user.company?.address_city} />
          <AccountReadField label={<Translate name="zipCode" />} value={user.company?.address_zip} />
        </AccountReadGroup>
        <AccountReadGroup>
          <AccountReadField label={<Translate name="country" />} value={countryNameWithCode(user.company?.address_country)} />
        </AccountReadGroup>
      </div>
      <div className="account-read__section">
        <AccountReadGroup>
          {/* <AccountReadField label={<Translate name="type" />} value={user.company?.type} /> */}
          <AccountReadField label={<Translate name="website" />} value={user.company?.website} />
        </AccountReadGroup>
      </div>
    </div>
  );
}

export default MyCompanyRead;