import firebase from "firebase/app";
import "firebase/storage";

const uploadFile = async (data, name, format, type, path) => {
  try {
    let fileRef = firebase.storage().ref().child(`${path}/${name}.${format}`)
    let parts = data.split(',')
    let base64data
    if(parts.length == 2) {
      base64data = parts[1]
    } else {
      base64data = data
    }
    await fileRef.putString(base64data, 'base64', { contentType: type })
    let downloadUrl = await fileRef.getDownloadURL()
    return { url: downloadUrl }
  } catch(err) {
    console.log('image upload error', err)
    return { error: err }
  }
}

export {
  uploadFile
}