import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { MdAccountCircle, MdExpandMore, MdDashboard, MdLockOpen } from 'react-icons/md';

import SideMenu from '../sections/SideMenu';
import Button from '../UI/Button';
import selectorIcon from '../../assets/images/selector-icon.svg';
import DashboardMobileMenu from '../sections/DashboardMobileMenu';
import DashboardLoader from '../UI/DashboardLoader';
import logo from '../../assets/images/logo.svg';
import imagesLoaded from '../../helpers/imagesLoaded';
import PageLoader from '../UI/PageLoader';
import Translate from '../../i18n/Translate';
import Footer from '../sections/Footer';
import { UserContext } from '../../context/userState';

const Dashboard = (props) => {
  const history = useHistory();
  const [isMenuShrinked, setIsMenuShrinked] = useState(false);
  const { user, logout } = useContext(UserContext);
  const [showLoader, setShowLoader] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [icons, setIcons] = useState([
    { icon: <img src={logo} alt="" onLoad={() => imageLoadHandler(icons[0])} />, loading: true }
  ])
  let timeout = useRef();
  const userDropdownEl = useRef();

  // useEffect(() => {
  //   timeout.current = setTimeout(() => {
  //     setShowLoader(false);
  //   }, 4000);

  //   return () => {
  //     clearTimeout(timeout.current);
  //   }
  // }, []);

  useEffect(() => {
    if(imagesLoaded(icons, 'loading')) {
      setIsLoading(false);
    }
  }, [icons]);

  const handleClickOutside = useCallback((e) => {
    if(showUserDropdown && e.target.closest('.user_dropdown') !== userDropdownEl.current) {
      setShowUserDropdown(false);
    }
  }, [showUserDropdown]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [handleClickOutside]);

  const imageLoadHandler = (info) => {
    const iconsClone = [...icons];
    const infoObj = icons.filter(obj => obj.icon === info.icon)[0];
    infoObj.loading = false;
    setIcons(iconsClone);
  }

  const menuToggleHandler = () => {
    setIsMenuShrinked(!isMenuShrinked);
  }

  const logoutHandler = async (e) => {
    e.preventDefault();
    clearTimeout(timeout.current);

    await logout();
  }

  return(
    <div className={isMenuShrinked ? "dashboard dashboard--menu-shrinked" : "dashboard"}>
      {isLoading && <PageLoader />}
      <SideMenu
        shrinked={isMenuShrinked}
        onMenuToggle={menuToggleHandler}
        icons={icons}
        numberOfProjects={user?.folders?.length || 0}
      />
      <DashboardMobileMenu />
      <div className="dashboard__content">
        <div className="dashboard__content_top">
          {showLoader && <DashboardLoader />}
          <div className="dashboard__content_top--inner">
            <h1><Translate name="appTitle" /></h1>
            <div className="dashboard__content_top-right">
              <Button
                text={<Translate name="selector" />}
                iconEl={<i className="custom-icon-selector"><span className="path1"></span><span className="path2"></span></i>}
                onButtonClick={() => history.push('/')}
              />
              <div className={showUserDropdown ? "user active" : "user"} onClick={() => setShowUserDropdown(!showUserDropdown)} ref={userDropdownEl}>
                <MdAccountCircle />
                <span>{ user.firstName } { user.lastName }</span>
                <MdExpandMore />

                {showUserDropdown && <ul className="user__dropdown">
                  <li><Link to="/dashboard"><MdDashboard /> <Translate name="myProjects" /></Link></li>
                  <li><Link to="/dashboard/account"><i className="custom-icon-contact-page"></i> <Translate name="myAccount" /></Link></li>
                  <li><Link to="" onClick={logoutHandler}><MdLockOpen /> <Translate name="logout" /></Link></li>
                </ul>}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard__content_main">
          {props.children}
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Dashboard;