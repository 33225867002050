import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Modal from '../UI/Modal';
import DashboardBox from '../UI/DashboardBox';
import Input from '../UI/Input';
import Textarea from '../UI/Textarea';
import Button from '../UI/Button';
import Alert from '../UI/Alert';
import Translate from '../../i18n/Translate';
import { LangContext } from '../../context/lang/langState';
import { cookiesAccepted } from '../../utils';
import EtexApi from '../../services/EtexApi';

const initialData = {
  name: {
    required: true,
    value: ''
  },
  email: {
    email: true,
    value: ''
  },
  message: {
    required: true,
    value: ''
  }
}

const ContactUsModal = ({ onModalClose, solutionId = null }) => {
  const { formData, errors, changeHandler, setErrors } = useForm(initialData, validate);
  const { translate, language } = useContext(LangContext);

  const [sendDisabled, setSendDisabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      let name = formData.name.value;
      let email = formData.email.value;
      let message = formData.message.value;

      name = name.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      message = message.replace(/</g, "&lt;").replace(/>/g, "&gt;");

      setSendDisabled(true);
      let newAlertMessage = translate('sendEmailSuccess')
      let newAlertType = 'success';

      if(!!solutionId) {
        message = `
          <div>
          <p style="display:inline-flex;border:1px solid #B8E5F5;border-radius:3px;line-height:1.857;"><span style="padding: 2px 15px 3px;
          display: block;color: #0BA1E2;">Solution:</span> <a href="https://selector.promat.com/solution/${solutionId}" style="padding: 2px 18px 3px;display: block;background-color: #B8E5F5;color: #222469;flex: 1 1;">https://selector.promat.com/solution/${solutionId}</a></p>
          </div>
          ${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}
        `;
      }

      try {
        await EtexApi.sendEmail(name, email, message, language);
        if(cookiesAccepted()) {
          ReactGA.event({
            category: 'Contact',
            action: 'Email sent'
          });
        }
      } catch {
        newAlertMessage = translate('sendEmailError')
        newAlertType = 'error';
      }

      setSendDisabled(false);

      setAlertMessage(newAlertMessage);
      setAlertType(newAlertType);
      setTimeout(() => {
        setAlertMessage('');
        setAlertType('');
        onModalClose()
      }, 1800);
    }
  }

  return(
    <Modal onClose={onModalClose}>
      <DashboardBox title={<Translate name="contactUs" />} primary>
        <form onSubmit={submitHandler} className="form">
          <div className="form__group form__group--break-sm">
            <Input
              label={<Translate name="name" />}
              name="name"
              value={formData.name.value}
              onChange={changeHandler}
              error={errors.name}
              dashboardLight
            />
            <Input
              label={<Translate name="email" />}
              name="email"
              value={formData.email.value}
              onChange={changeHandler}
              error={errors.email}
              dashboardLight
            />
          </div>
          <Textarea
            label={<Translate name="yourMessage" />}
            name="message"
            value={formData.message.value}
            onChange={changeHandler}
            error={errors.message}
            dashboardLight
          />
          <div className="form__foot form__foot--2">
            <Button type="button" text={<Translate name="cancel" />} normalPrimary onButtonClick={onModalClose} />
            <Button text={<Translate name="send" />} primary minimal disabled={sendDisabled} />
          </div>

          { alertMessage.length > 0 && <><br/><br/><Alert type={ alertType } message={ alertMessage } /></> }
        </form>
      </DashboardBox>
    </Modal>
  );
}

ContactUsModal.propTypes = {
  onModalClose: PropTypes.func.isRequired
}

export default ContactUsModal;