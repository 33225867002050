import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import Alert from '../UI/Alert';
import Translate from '../../i18n/Translate';
import { LangContext } from '../../context/lang/langState';

const initialData = {
  email: {
    email: true,
    value: ''
  }
};

const ForgotPassword = () => {
  const { formData, errors, changeHandler, setErrors, isValid } = useForm(initialData, validate);
  const [showText, setShowText] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const { translate } = useContext(LangContext);

  const submitHandler = async (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0 && showText) {
      setShowText(false);
      setDisableInput(true);

      let text = 'Forgot password e-mail sent!'
      let alertType = 'success';
      try {
        await firebase.auth().sendPasswordResetEmail(formData.email.value)
      } catch (error) {
        console.log(error);
        text = 'Something went wrong...';
        alertType = 'error';
      }

      setAlertMessage(text);
      setAlertType(alertType);

      setShowText(true);
      setDisableInput(false);
      setTimeout(() => {
        setAlertMessage('');
        setAlertType('');
      }, 3000);
    }
  }

  return(
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_top d-flex justify-content-between">
          <h6><Translate name="resetYourPassword" /></h6>
        </div>
        <div className="auth__box_main">
          <form onSubmit={submitHandler}>
            <Input
              name="email"
              type="email"
              value={formData.email.value}
              onChange={changeHandler}
              error={errors.email}
              iconEl={<MdEmail />}
              placeholder={translate('email')}
              disabled={disableInput}
            />
            <Button
              text={showText || !isValid ? <Translate name="sendResetEmail" /> : <Loader small />}
              primary
              fullwidth
              disabled={!isValid}
              loading={!showText && isValid}
            />
          </form>
          { alertMessage.length > 0 && <><br/><Alert type={ alertType } message={ alertMessage } /></> }
        </div>
      </div>
      <div className="auth__note">
        <p className="auth__note_account"><Translate name="dontHaveAnAccount" /> <Link to="/signup"><Translate name="signUpHere" /></Link></p>
      </div>
    </div>
  );
}

export default ForgotPassword;