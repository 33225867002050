import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { MdCloudUpload } from 'react-icons/md';

import Translate from '../../i18n/Translate';

const FileUpload = ({ label, name, id, onChange, file, error, allowedTypes, maxFileSize }) => {
  const fileInput = useRef();

  const fileBtnClickHandler = () => {
    fileInput.current.click();
  }

  const onFileSubmitted = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = files[0].name.split('.')
        const format = components[components.length - 1].toLowerCase()
        const type = files[0].type
        const size = files[0].size
        components.splice(components.length - 1, 1)
        const name = components.join('.') // to keep any dots that were part of file name
        const data = e.target.result
        onChange('logo', { name, format, data, type, size })       
        fileInput.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(files[0]);
    }
  }

  const buttonStyle = () => {
    const acceptedImageFormats = ['png', 'jpeg', 'jpg']
    let backgroundUrl
    if(file.data) {
      if(acceptedImageFormats.includes(file.format.toLowerCase())) {
        backgroundUrl = file.data
      }
    } else if(file.url) {
      backgroundUrl = file.url
    }
    if(backgroundUrl) {
      return {
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }
    } else {
      return {}
    }
  }

  return(
    <div className="file-upload">
      {label && <label className="file-upload__label">{label}</label>}
      <input type="file" name={name} id={id ? id : name} onChange={onFileSubmitted} className="file-upload__field" ref={fileInput} accept={allowedTypes.join(', ')} />
      <div className="file-upload__group">
        <div className="file-upload__btn" onClick={fileBtnClickHandler} style={buttonStyle()}>
          <MdCloudUpload />
        </div>
        <div className="file-upload__name">{file.name ? file.name : file.url ? <Translate name="uploadFile" /> : <Translate name="noFileSelected" />}</div>
      </div>
      {error && <div className="file-upload__error">{error}</div>}
    </div>
  );
}

FileUpload.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  file: PropTypes.object,
  error: PropTypes.string,
  allowedTypes: PropTypes.array
}

export default FileUpload;