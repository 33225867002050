import React from 'react';
import PropTypes from 'prop-types';
import { MdDone } from 'react-icons/md';
import { Link } from 'react-router-dom';

const DefaultCheckbox = ({ name, value, onChange, label, labelLinkTo, labelLinkText, error }) => {
  return(
    <div className="default-checkbox">
      <label>
        <input type="checkbox" name={name} value={value} onChange={onChange} checked={value} />
        <div className="default-checkbox__button"><MdDone /></div>
        <span className="default-checkbox__text">{label} {labelLinkText && <Link to={labelLinkTo}>{labelLinkText}</Link>}</span>
      </label>
      {error && <div className="default-checkbox__error">{error}</div>}
    </div>
  );
}

DefaultCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

export default DefaultCheckbox;