import React, { Fragment } from 'react';

import Header from '../sections/Header';
import Footer from '../sections/Footer';
import TextLink from '../UI/TextLink';
import Translate from '../../i18n/Translate';

const CookiePolicy = () => {
  return(
    <Fragment>
      <Header />
      <section className="text-section">
        <div className="container">
          <div className="text-section__inner">
            <section>
              <h1><Translate name="cookiePolicy" /></h1>
              <p><Translate name="cookiePolicyText1" /></p>
            </section>

            <section>
              <h3><Translate name="cookiePolicyText2" /></h3>
              <p><Translate name="cookiePolicyText3" /></p>
              <p><Translate name="cookiePolicyText4" /></p>
              <p><Translate name="cookiePolicyText5" /></p>
              <p><Translate name="cookiePolicyText6" /></p>
              <p><Translate name="cookiePolicyText7" /></p>
              <p><Translate name="cookiePolicyText8" /></p>
              <p><Translate name="cookiePolicyText9" /></p>
              <p><Translate name="cookiePolicyText10" /></p>
              <p><Translate name="cookiePolicyText11" /></p>
              <p><Translate name="cookiePolicyText12" /></p>
            </section>

            <section>
              <h3><Translate name="cookiePolicyText13" /></h3>
              <p className="bold"><Translate name="cookiePolicyText14" /></p>
              <p><Translate name="cookiePolicyText15" /></p>
              <p className="bold"><Translate name="cookiePolicyText16" /></p>
              <p><Translate name="cookiePolicyText17" /></p>
              <p className="bold"><Translate name="cookiePolicyText18" /></p>
              <p><Translate name="cookiePolicyText19" /></p>
              <p className="bold"><Translate name="cookiePolicyText20" /></p>
              <p><Translate name="cookiePolicyText21" /></p>
              <p className="bold"><Translate name="cookiePolicyText22" /></p>
              <p><Translate name="cookiePolicyText23" /></p>
              <p className="bold"><Translate name="cookiePolicyText24" /></p>
              <p><Translate name="cookiePolicyText25" /></p>
              <p><Translate name="cookiePolicyText26" /></p>
              <p><Translate name="cookiePolicyText27" /></p>
              <p><Translate name="cookiePolicyText28" /></p>
            </section>

            <section>
              <h3><Translate name="cookiePolicyText29" /></h3>
              <p><Translate name="cookiePolicyText30" /></p>
              <p><Translate name="cookiePolicyText31" /></p>
              <p><Translate name="cookiePolicyText32" /></p>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default CookiePolicy;