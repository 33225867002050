import React, { createContext, useState } from 'react';
import EtexApi from '../services/EtexApi';

export const LocalizedContext = createContext();

const LocalizedState = (props) => {
  const [fireStoppingTypeCopy, setFireStoppingTypeCopy] = useState({})
  const [penetrationMaterialCopy, setPenetrationMaterialCopy] = useState({})
  const [penetrationProductTypeCopy, setPenetrationProductTypeCopy] = useState({})
  const [compartmentationTypeCopy, setCompartmentationTypeCopy] = useState({})
  const [insulationTypeCopy, setInsulationTypeCopy] = useState({})
  const [penetrationPositionCopy, setPenetrationPositionCopy] = useState({})

  const fetchFieldOptions = async () => {
    let promises = []
    promises.push(fetchType('fireStoppingType', setFireStoppingTypeCopy))
    promises.push(fetchType('penetrationMaterial', setPenetrationMaterialCopy))
    promises.push(fetchType('penetrationProductType', setPenetrationProductTypeCopy))
    promises.push(fetchType('compartmentationType', setCompartmentationTypeCopy))
    promises.push(fetchType('pipeInsulationType', setInsulationTypeCopy))
    promises.push(fetchType('penetrationPosition', setPenetrationPositionCopy))
    await Promise.all(promises)
  }

  const fetchType = async (type, setter) => {
    let response = await EtexApi.getFieldOptions(type)
    if(!response.values) {
      return
    }
    let map = {}
    for(let option of response.values) {
      map[option.value] = {}
      for(let key in option.localized) {
        map[option.value][key] = option.localized[key].title
      }
    }
    setter({...map})
    return
  }

  const getLocalizedValue = (type, key, language) => {
    if(!type[key]) {
      return key
    } else if(!type[key][language]) {
      // try fallback on locale with the same lang
      let langShort = language.split('-')[0]
      for(let lang in type[key]) {
        if(lang.split('-')[0] === langShort) {
          return type[key][lang]
        }
      }
      return key
    } else {
      return type[key][language]
    }
  }

  return (
    <LocalizedContext.Provider
      value={{
        fireStoppingTypeCopy, setFireStoppingTypeCopy,
        penetrationMaterialCopy, setPenetrationMaterialCopy,
        penetrationProductTypeCopy, setPenetrationProductTypeCopy,
        compartmentationTypeCopy, setCompartmentationTypeCopy,
        insulationTypeCopy, setInsulationTypeCopy,
        penetrationPositionCopy, setPenetrationPositionCopy,
        fetchFieldOptions,
        getLocalizedValue
      }}
    >
      {props.children}
    </LocalizedContext.Provider>
  );
}

export default LocalizedState;