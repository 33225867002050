import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdHome, MdArrowBack, MdFolder, MdKeyboardArrowRight, MdCreateNewFolder } from 'react-icons/md';

import Button from '../UI/Button';
import Translate from '../../i18n/Translate';

const Popup = ({ folderStructure, solutions, onCancel, onCreateNewProject, onMoveToFolderId }) => {
  const [folderId, setFolderId] = useState('root');
  const selectedFolders = solutions.filter((solution) => solution.folder).map((solution) => solution.id);

  // const [level, setLevel] = useState(0);
  // const [selectedFolder, setSelectedFolder] = useState(0);

  // const folderSelectHandler = (folder, level) => {
  //   setSelectedFolder(folder);
  //   setLevel(level);
  // }

  return (
    <div className="popup">
      <div className="popup__head">
        {folderStructure[folderId].parent ?
          <div className="back">
            <MdArrowBack onClick={() => setFolderId(folderStructure[folderId].parent)} />
            <h5>{folderStructure[folderId].title}</h5>
          </div>
          :
          <div className="main">
            <MdHome />
            <h5>{folderStructure[folderId].title}</h5>
          </div>
        }
      </div>
      <div className="popup__body">
        <ul className="popup__body_list">
          { folderStructure[folderId].folders.filter((folder) => !selectedFolders.includes(folder.id)).map((folder) =>
            <li key={folder.id}>
              <div className="root" onClick={() => setFolderId(folder.id)}>
                <MdFolder className="pre-icon" />
                <p>{ folderStructure[folder.id].title }</p>
                <span><MdKeyboardArrowRight /></span>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="popup__foot">
        <div className="new" onClick={() => onCreateNewProject(folderId)}>
          <MdCreateNewFolder />
        </div>
        <Button text={<Translate name="cancel" />} small normal onButtonClick={onCancel} />
        <Button onButtonClick={() => { onMoveToFolderId(folderId); onCancel() }} text={<Translate name="moveHere" />} small secondary />
      </div>
    </div>
  );
}

Popup.propTypes = {
  onCancel: PropTypes.func.isRequired
}

export default Popup;