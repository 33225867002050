import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ label, type, placeholder, value, onChange, name, error, iconEl, id, dashboard, small, dashboardLight, disabled }) => {
  let inputClasses = 'form-input';

  if(error) {
    inputClasses += ' error';
  }

  if(iconEl) {
    inputClasses += ' with-icon';
  }

  if(dashboard) {
    inputClasses += ' form-input--dashboard';
  }

  if(dashboardLight) {
    inputClasses += ' form-input--dashboard-light';
  }

  if(small) {
    inputClasses += ' form-input--sm';
  }

  return(
    <div className={inputClasses}>
      <div className="form-input__inner">
        {iconEl && <label htmlFor={id ? id : name} className="form-input__icon">{iconEl}</label>}
        {label && <label htmlFor={id ? id : name} className="form-input__label">{label}</label>}
        <input 
          type={type ? type : "text"}
          className="form-input__field"
          placeholder={placeholder}
          name={name}
          id={id ? id : name}
          value={value}
          onChange={onChange}
          autoComplete="off"
          disabled={disabled}
        />
      </div>
      {error && <div className="form-input__error">{error}</div>}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  iconEl: PropTypes.node,
  dashboard: PropTypes.bool,
  small: PropTypes.bool,
  dashboardLight: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Input;