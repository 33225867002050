import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Translate from '../../i18n/Translate';

const PasswordChange = ({ label, onButtonClick }) => {
  return(
    <div className="password-change">
      <p className="password-change__label">{label}</p>
      <Button type="button" text={<Translate name="change" />} onButtonClick={onButtonClick} primary />
    </div>
  );
}

PasswordChange.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onButtonClick: PropTypes.func.isRequired
}

export default PasswordChange;