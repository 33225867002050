import React from 'react';
import PropTypes from 'prop-types';
import { MdDone } from 'react-icons/md';

const Checkbox = ({ id, name, onChange, checked, primary }) => {
  let checkboxClasses = 'checkbox';

  if(primary) {
    checkboxClasses += ' checkbox--primary';
  }

  return(
    <div className={checkboxClasses}>
      <label htmlFor={id}>
        <input type="checkbox" name={name} id={id} onChange={onChange} checked={checked} />
        <div className="checkbox__btn">
          <MdDone />
        </div>
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  primary: PropTypes.bool
}

export default Checkbox;