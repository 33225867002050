import React, { useContext } from 'react';

import TextLink from '../UI/TextLink';
import { LangContext } from '../../context/lang/langState';

const Footer = () => {
  const { translate } = useContext(LangContext);
  const year = new Date().getFullYear();

  return(
    <footer className="footer">
      <p>Promat ® {year} - <TextLink to="/privacy-policy" text={translate('signUpTermsLinkText')} /> - <TextLink to="/cookie-policy" text={translate('cookiePolicy')} /> - <TextLink to="/disclaimer" text={translate('disclaimer')} /></p>
    </footer>
  );
}

export default Footer;