import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import MainLayout from '../layouts/Main';
import Intro from '../sections/Intro';
import Button from '../UI/Button';
import Solution from '../UI/Solution';
import Translate from '../../i18n/Translate';
import Loader from '../UI/Loader';

import EtexApi from '../../services/EtexApi';
import { LangContext } from '../../context/lang/langState';
import { UserContext } from '../../context/userState';

const MultiplePenetration = () => {
  const { apiLanguage } = useContext(LangContext);
  const { updateUser, user } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const data = location.state && location.state.data;
  const [deleting, setDeleting] = useState(false);

  const trimmedSolutionTitle = (title) => {
    return title[apiLanguage]?.split('|')[0].trim()
  }

  const deleteMPHandler = async () => {
    setDeleting(true);
    const newSystemVariants = [...user.systemVariants].filter((systemVariant) => data.id !== systemVariant.id);
    await updateUser({ systemVariants: newSystemVariants });
    history.push('/dashboard');
  }

  if(!data) {
    return(
      <MainLayout>
        <Intro />
        <p><Translate name="multiplePenetrationNotFound" /></p>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Intro />
      <div className="multiple-penetration-wrapper">
        <div className="solutions__inner solutions__inner--list">
          {data.variants.length > 0 ? data.variants.map((solution) => (
            <Solution
              key={solution.id}
              image={EtexApi.assetUrl(solution.relatedResourceIds?.find(Boolean), 'thumbnail')}
              title={trimmedSolutionTitle(solution.name)}
              fields={solution.fields}
              onButtonClick={() => history.push(`/solution/${solution.id}`)}
              choices={[]}
              listView
              listViewCentered
            />
          ))
            :
            <p><Translate name="noSolutionsInThisMP" /></p>
          }
        </div>
        <div className="multiple-penetration-box">
          <h3><Translate name="multiplePenetration" /></h3>
          <p><Translate name="youHave" /> {data.solutions} <Translate name="solutionsInMP" /></p>
          <Button
            text={deleting ? <Loader normalWhite small /> : <Translate name="deleteThisMP" />}
            medium fullwidth
            onButtonClick={deleteMPHandler}
            disabled={data.solutions.length === 0 || deleting}
          />
          <p className="disclaimer"><Translate name="multiplePenetrationDisclaimer" /></p>
        </div>
      </div>
    </MainLayout>
  );
}

export default MultiplePenetration;