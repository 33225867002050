import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga';

import ArrowButton from '../UI/ArrowButton';

import { LangContext } from '../../context/lang/langState'
import { StepsContext } from '../../context/stepsState';
import { cookiesAccepted } from '../../utils';
import EtexApi from '../../services/EtexApi';

import { env } from '../../config.json'

const FILTER_KEY_DIAMETER = 'sysVarProductDiameter'
const FILTER_KEY_PENPOS = 'sysVarPenetrationPosition'

const FILTER_VALUE_ALL = 'ALL'

const ProductList = ({ onStepChange, stepName, stepIndex, stepData }) => {

  const { translate, apiLanguage } = useContext(LangContext);
  const {
    products,
  } = useContext(StepsContext);

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(`/step-${stepIndex}`, [], stepName);
    }
    // console.log(`/step-${stepIndex}`, [], stepName);
    // ReactGA.event({
    //   category: stepName,
    //   action: stepName
    // });
    // eslint-disable-next-line
  }, []);

  return(
    <div className="step">
      <ArrowButton text={translate("seeAllSolutions")} onButtonClick={() => onStepChange(null, 'product')} className="mb-3 btn-arrow--full-break-sm-max" />
      <div className="step__buttons">
        {/* { products.map((product, productIdx) =>
          <div key={productIdx}>
            <ArrowButton text={extractName(product)} onButtonClick={() => onStepChange(product.itemId, 'product') } subtitle={env !== 'production' ? product.itemId : ''} />
          </div>
        )} */}
        { stepData.map((name, ni) =>
          <div key={`product_${ni}`}>
            <ArrowButton text={name} onButtonClick={() => onStepChange(name, 'product') } />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;
