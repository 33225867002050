import React from 'react';
import PropTypes from 'prop-types';

const Button = (
  { text, iconEl, onButtonClick, medium, primary, fullwidth, className, large, iconPrimary,
  secondary, centered, textSmall, bold, outlineSecondary, transparent, small, normal, minimal, type,
  normalPrimary, mini, disabled, loading, rotateIconOnHover, lightSecondary, error, outlinePrimary }
) => {
  let btnClasses = 'btn';

  if(iconEl) {
    btnClasses += ' btn--icon';
  }

  if(medium) {
    btnClasses += ' btn--md';
  }

  if(large) {
    btnClasses += ' btn--lg';
  }

  if(primary) {
    btnClasses += ' btn--primary';
  }

  if(fullwidth) {
    btnClasses += ' btn--fullwidth';
  }

  if(className) {
    btnClasses += ` ${className}`;
  }

  if(iconPrimary) {
    btnClasses += ' btn--icon-primary';
  }

  if(secondary) {
    btnClasses += ' btn--secondary';
  }

  if(centered) {
    btnClasses += ' btn--centered';
  }

  if(textSmall) {
    btnClasses += ' btn--text-small';
  }

  if(bold) {
    btnClasses += ' btn--bold';
  }

  if(outlineSecondary) {
    btnClasses += ' btn--outline-secondary';
  }

  if(transparent) {
    btnClasses += ' btn--transparent';
  }

  if(small) {
    btnClasses += ' btn--sm';
  }

  if(normal) {
    btnClasses += ' btn--normal';
  }

  if(minimal) {
    btnClasses += ' btn--min';
  }

  if(normalPrimary) {
    btnClasses += ' btn--normal-primary';
  }

  if(mini) {
    btnClasses += ' btn--mini';
  }

  if(loading) {
    btnClasses += ' btn--loading';
  }

  if(rotateIconOnHover) {
    btnClasses += ' btn--icon-rotate';
  }

  if(lightSecondary) {
    btnClasses += ' btn--light-secondary';
  }

  if(error) {
    btnClasses += ' btn--error';
  }

  if(outlinePrimary) {
    btnClasses += ' btn--outline-primary';
  }

  return(
    <button className={btnClasses} onClick={onButtonClick} type={type ? type : 'submit'} disabled={disabled}>
      {iconEl && <span className="btn__icon">{iconEl}</span>}
      <span className="btn__text">{text}</span>
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  iconEl: PropTypes.node,
  onButtonClick: PropTypes.func,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  primary: PropTypes.bool,
  fullwidth: PropTypes.bool,
  className: PropTypes.string,
  iconPrimary: PropTypes.bool,
  secondary: PropTypes.bool,
  centered: PropTypes.bool,
  textSmall: PropTypes.bool,
  bold: PropTypes.bool,
  outlineSecondary: PropTypes.bool,
  transparent: PropTypes.bool,
  small: PropTypes.bool,
  normal: PropTypes.bool,
  type: PropTypes.string,
  normalPrimary: PropTypes.bool,
  mini: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  rotateIconOnHover: PropTypes.bool,
  lightSecondary: PropTypes.bool
}

export default Button;