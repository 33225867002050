import React, { useState, useEffect, useContext, Fragment } from 'react';
import ReactGA from 'react-ga';
import { Link, useHistory } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Select from '../UI/Select';

import Solution from '../UI/Solution';

import { LangContext } from '../../context/lang/langState'
import { StepsContext } from '../../context/stepsState';
import { MPContext } from '../../context/multiplePenetrationState';
import { UserContext } from '../../context/userState';

import EtexApi from '../../services/EtexApi';
import Button from '../UI/Button';
import Translate from '../../i18n/Translate';
import Loader from '../UI/Loader';
import Notification from '../UI/Notification';
import { mpCompatibilityError, cookiesAccepted, isMultiplePenetrationEnabled } from '../../utils';
import { LocalizedContext } from '../../context/localizedState';

const FILTER_KEY_DIAMETER = 'sysVarProductDiameter'
const FILTER_KEY_PENPOS = 'sysVarPenetrationPosition'
const FILTER_KEY_INSULATION = 'sysVarPipeInsulationType'

const FILTER_VALUE_ALL = 'ALL'

const SolutionList = ({ fireStoppingType, onStepChange, stepName, stepIndex }) => {

  const { translate, apiLanguage, apiTranslate } = useContext(LangContext);
  const {
    systemVariants, selectedProductId,
    filteredSystemVariants, setFilteredSystemVariants,
    filterOptions, setFilterOptions,
    filtersData, setFiltersData,
  } = useContext(StepsContext);
  const { addSolutionToMP, mpSolutionsIds, mpMode, mpSolutions, removeSolutionFromMP, setMPMode, clearMP } = useContext(MPContext);
  const { user, addNewSystemVariant, addNewMultiplePenetration } = useContext(UserContext);
  const { getLocalizedValue, penetrationPositionCopy, insulationTypeCopy } = useContext(LocalizedContext)
  const [savingMP, setSavingMP] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(`/step-${stepIndex}`, [], stepName);
      ReactGA.event({
        category: stepName,
        action: stepName
      });
    }
    // eslint-disable-next-line
  }, []);

  const [documentFilterValue, setDocumentFilterValue] = useState('')
  const [sortedSystemVariants, setSortedSystemVariants] = useState([]);

  useEffect(() => {
    filterSystemVariants()
    populateFilterOptions()
  }, [filtersData, selectedProductId, systemVariants, documentFilterValue])

  const filterSystemVariants = () => {
    let fsv = []
    for (let i in systemVariants) {
      let valid = true
      for (let filterKey in filtersData) {
        if (!systemVariants[i].fields[filterKey]) continue;

        let { value } = extractValueAndLabel(systemVariants[i], filterKey)
        if (!!filtersData[filterKey] && filtersData[filterKey] !== FILTER_VALUE_ALL && value !== filtersData[filterKey]) {
          valid = false
          break
        }
      }
      if (documentFilterValue === 'yes') {
        if (!systemVariants[i].relatedDocumentIds || systemVariants[i].relatedDocumentIds.length === 0) {
          valid = false
        }
      } else if (documentFilterValue === 'no') {
        if (systemVariants[i].relatedDocumentIds && systemVariants[i].relatedDocumentIds.length > 0) {
          valid = false
        }
      }

      if (valid) {
        fsv.push(systemVariants[i])
      }
    }
    setFilteredSystemVariants([...fsv])
    // setFilteredSystemVariants([...systemVariants])
  }

  const changeHandler = (name, value, e) => {
    setFiltersData({ ...filtersData, [name]: value })
  }


  const extractValueAndLabel = (systemVariant, fieldHandle) => {
    let value, label
    switch (fieldHandle) {
      case FILTER_KEY_DIAMETER:
        value = systemVariant.fields[fieldHandle].value.rawValue
        label = `${systemVariant.fields[fieldHandle].value.rawValue} ${systemVariant.fields[fieldHandle].value.unit}`
        break
      case FILTER_KEY_PENPOS:
      case FILTER_KEY_INSULATION:
        if (systemVariant.fields[fieldHandle].value === 'NONE') {
          value = 'NONE';
          label = 'Unspecified';
          break;
        }

        value = systemVariant.fields[fieldHandle].value.rawValue
        if(systemVariant.fields[fieldHandle].value.displayValue) {
          label = apiTranslate(systemVariant.fields[fieldHandle].value.displayValue)
        } else if(systemVariant.fields[fieldHandle].value.rawValue) {
          label = systemVariant.fields[fieldHandle].value.displayValue
        } else {
          label = "Unspecified"
          value = "NONE"
        }

        // if (!!systemVariant.fields[fieldHandle].value.displayValue) {
        // }

        //
        // XXX[2020-10-19 @TomasHromada]: Previous versions of field formats that are not used anymore
        //
        // if (!!systemVariant.fields[fieldHandle].localized) {
        //   label = systemVariant.fields[fieldHandle].localized[apiLanguage]
        //   if(!label) {
        //     const translateLabel = translate(systemVariant.fields[fieldHandle].value)
        //     label = translateLabel ? translateLabel : systemVariant.fields[fieldHandle].localized.en // use default when localized values are missing
        //   }
        // }

        // if (!!systemVariant.fields[fieldHandle].displayName) {
        //   label = systemVariant.fields[fieldHandle].displayName[apiLanguage]
        // }

        break
      default:
        label = "Unspecified"
        value = "NONE"
        break;
    }
    return { value, label }
  }

  const populateFilterOptions = () => {
    if (!systemVariants) {
      return
    }
    let fo = {
      [FILTER_KEY_DIAMETER]: [],
      [FILTER_KEY_PENPOS]: [],
      [FILTER_KEY_INSULATION]: []
    }
    let foValues = {
      [FILTER_KEY_DIAMETER]: [],
      [FILTER_KEY_PENPOS]: [],
      [FILTER_KEY_INSULATION]: [],
    }
    for (let i in systemVariants) {
      for (let filterKey in fo) {
        if (!systemVariants[i].fields[filterKey]) continue;
        let { value, label } = extractValueAndLabel(systemVariants[i], filterKey)
        if (!foValues[filterKey].includes(value)) {
          fo[filterKey].push({ value, label })
          foValues[filterKey].push(value)
        }
      }
    }
    let sorter = (a, b) => {
      if ((parseInt(a.value) || a.value) > (parseInt(b.value) || b.value)) return 1
      else if ((parseInt(a.value) || a.value) < (parseInt(b.value) || b.value)) return -1
      return 0
    }
    for (let key in fo) {
      fo[key].sort(sorter)
      fo[key].splice(0, 0, { value: FILTER_VALUE_ALL, label: defaultLabelForFilterKey(key) })
    }
    setFilterOptions({ ...fo })
  }

  const defaultLabelForFilterKey = (filterKey) => {
    switch (filterKey) {
      case FILTER_KEY_DIAMETER:
        return translate("diameter")
      case FILTER_KEY_PENPOS:
        return translate("penetrationsPosition")
      case FILTER_KEY_INSULATION:
        return translate("insulation")
    }
  }

  const trimmedSolutionTitle = (title) => {
    return title[apiLanguage]?.split('|')[0].trim()
  }

  useEffect(() => {
    if (filteredSystemVariants.length > 0) {
      const sorted = [...filteredSystemVariants].sort((a, b) => {
        if (a.fields.sysVarProductDiameter && a.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/) && b.fields.sysVarProductDiameter && b.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/)) {
          if (+a.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/)[0] > +b.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/)[0]) {
            return 1;
          } else if (+b.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/)[0] > +a.fields.sysVarProductDiameter.value.rawValue.match(/(\d+)/)[0]) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a.name[apiLanguage].match(/(\d+)/) && b.name[apiLanguage].match(/(\d+)/)) {
            if (+a.name[apiLanguage].match(/(\d+)/)[0] > +b.name[apiLanguage].match(/(\d+)/)[0]) {
              return 1;
            } else if (+b.name[apiLanguage].match(/(\d+)/)[0] > +a.name[apiLanguage].match(/(\d+)/)[0]) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        }
      });
      setSortedSystemVariants(sorted);
    }
  }, [filteredSystemVariants]);

  useEffect(() => {
    return () => {
      setSortedSystemVariants([]);
      setFilteredSystemVariants([]);
    };
    // eslint-disable-next-line
  }, []);

  const addToMPHandler = (solution, cb) => {
    let error = mpCompatibilityError(solution, mpSolutions, apiLanguage)
    if(!error) {
      setShowNotification(true)
      addSolutionToMP({...solution})
      cb()
    } else {
      setErrorNotification(error)
    }
  }

  const removeFromMPHandler = (solution) => {
    removeSolutionFromMP(solution);
  }

  const isAlreadyInMP = (id) => {
    const solutionsLS = localStorage.getItem('mpSolutions') ? JSON.parse(localStorage.getItem('mpSolutions')) : [];
    if (solutionsLS.length === 0) {
      return false;
    }
    if (mpSolutionsIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  const saveMPHandler = async () => {
    if (user) {
      try {
        const variants = [...mpSolutions].map(s => ({
          id: s.id,
          name: s.name,
          fields: s.fields,
          relatedResourceIds: s.relatedResourceIds ? s.relatedResourceIds : []
        }));
        setSavingMP(true);
        await addNewMultiplePenetration(variants);
        clearMP();
        setSavingMP(false);
        setMPMode(false);
        history.push('/dashboard');
      } catch (err) {
        console.log(err);
        setSavingMP(false)
      }
    } else {
      history.push('/signin');
    }
  }

  const deleteMPHandler = () => {
    clearMP();
  }

  return (
    <div className="step">
      {}
      {!mpMode && <div className="filters">
        <h6>{translate('filters')}</h6>
        <div className="filters__inner">
          {fireStoppingType !== 'blankseal' && (<Select
            options={filterOptions[FILTER_KEY_DIAMETER]}
            name={FILTER_KEY_DIAMETER}
            onChange={changeHandler}
            value={filtersData[FILTER_KEY_DIAMETER]}
            defaultText={translate('diameter')}
            className="mr-4"
          />)}
          <Select
            options={[...filterOptions[FILTER_KEY_PENPOS]].map((opt, i) => { 
              return i === 0 
                ? { ...opt, label: translate('penetrationsPosition') } 
                : { ...opt, label: getLocalizedValue(penetrationPositionCopy, opt.value, apiLanguage) } //  translate(`PENETRATION_POSITION.${opt.value}`) }
            })}
            // options={filterOptions[FILTER_KEY_PENPOS]}
            name={FILTER_KEY_PENPOS}
            onChange={changeHandler}
            value={filtersData[FILTER_KEY_PENPOS]}
            defaultText={translate("penetrationsPosition")}
            className="mr-4"
          />
          {fireStoppingType !== 'blankseal' && <Select
            options={[...filterOptions[FILTER_KEY_INSULATION]].map((opt, i) => { 
              return i === 0 
                ? { ...opt, label: translate('insulation') } 
                : { ...opt, label: getLocalizedValue(insulationTypeCopy, opt.value, apiLanguage) } //translate(`INSULATION_TYPE.${opt.value}`) }
            })}
            // options={filterOptions[FILTER_KEY_INSULATION]}
            name={FILTER_KEY_INSULATION}
            onChange={changeHandler}
            value={filtersData[FILTER_KEY_INSULATION]}
            defaultText={translate("insulation")}
          />}
        </div>
      </div>}
      <div className="solutions">
        {!mpMode ? <Fragment>
          <h6>{translate('solutionsFounded')} ({filteredSystemVariants.length})</h6>
          <div className="solutions__inner">
            {sortedSystemVariants.map((systemVariant, systemVariantIdx) => {
              return (
                <Solution
                  image={EtexApi.assetUrl(systemVariant.relatedResourceIds?.find(Boolean), 'thumbnail')}
                  key={systemVariantIdx}
                  title={trimmedSolutionTitle(systemVariant.name)}
                  fields={systemVariant.fields}
                  onButtonClick={() => onStepChange(systemVariant.id, 'solution')}
                  choices={[]}
                  showMPBtn={isMultiplePenetrationEnabled(systemVariant, apiLanguage)}
                  onMPAdd={(cb) => addToMPHandler(systemVariant, cb)}
                  alreadyAddedToMP={isAlreadyInMP(systemVariant.id)}
                />
              )
            })}
          </div>
        </Fragment>
          :
          <Fragment>
            <Button
              text={<Translate name="backToSolutions" />}
              onButtonClick={() => setMPMode(false)}
              className="mr-3 mb-3 btn--icon-animate"
              iconEl={<MdKeyboardArrowLeft />}
            />
            <div className="multiple-penetration-wrapper">
              <div className="solutions__inner solutions__inner--list">
                {mpSolutions.length > 0 ? mpSolutions.map((solution) => (
                  <Solution
                    key={solution.id}
                    image={EtexApi.assetUrl(solution.relatedResourceIds?.find(Boolean), 'thumbnail')}
                    title={trimmedSolutionTitle(solution.name)}
                    fields={solution.fields}
                    onButtonClick={() => onStepChange(solution.id, 'solution', solution)}
                    choices={[]}
                    onMPRemove={() => removeFromMPHandler(solution)}
                    listView
                    removeFromMPBtn
                  />
                ))
                  :
                  <p><Translate name="noSolutionsInThisMP" /></p>
                }
              </div>
              <div className="multiple-penetration-box">
                <h3><Translate name="multiplePenetration" /></h3>
                <p><Translate name="youHave" /> {mpSolutions.length} <Translate name="solutionsInMP" /></p>
                <Button
                  text={!savingMP ? <Translate name="saveThisMP" /> : <Loader small normalWhite />}
                  medium primary fullwidth
                  onButtonClick={saveMPHandler}
                  className="mb-4"
                  disabled={mpSolutions.length === 0 || savingMP}
                />
                <Button
                  text={<Translate name="deleteThisMP" />}
                  medium fullwidth
                  onButtonClick={deleteMPHandler}
                  disabled={mpSolutions.length === 0}
                />
                <p className="disclaimer"><Translate name="multiplePenetrationDisclaimer" /></p>
              </div>
            </div>
          </Fragment>
        }
      </div>
      {showNotification && <Notification
        onClose={() => setShowNotification(false)}
        type="success"
        message={
          <p><Translate name="solutionAddedTo" /> <Link to="/" onClick={(e) => { e.preventDefault(); setMPMode(true); setShowNotification(false); }}><Translate name="multiplePenetration" /></Link></p>
        }
      />}
      {errorNotification && <Notification
        onClose={() => setErrorNotification(null)}
        type="error"
        message={
          <p><Translate name={`mp_error.${errorNotification}`} /></p>
        }
      />}
    </div>
  );
}

export default SolutionList;
