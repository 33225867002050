import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MdLock } from 'react-icons/md';

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Modal from '../UI/Modal';
import DashboardBox from '../UI/DashboardBox';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Translate from '../../i18n/Translate';
import { LangContext } from '../../context/lang/langState';

const initialData = {
  password: {
    value: '',
    required: true
  },
  confirm_password: {
    value: '',
    required: true,
    matchWith: 'password'
  }
}

const ChangePasswordModal = ({ onModalClose, onSubmit }) => {
  const { formData, errors, setErrors, changeHandler } = useForm(initialData, validate);
  const { translate } = useContext(LangContext);

  const submitHandler = (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      onSubmit(formData.password.value)
      onModalClose()
    }
  }

  return (
    <Modal small onClose={onModalClose}>
      <DashboardBox title={<Translate name="newPassword" />} small primary>
        <form className="form" onSubmit={submitHandler}>
          <Input
            name="password"
            iconEl={<MdLock />}
            placeholder={translate('newPassword')}
            value={formData.password.value}
            onChange={changeHandler}
            error={errors.password}
            type="password"
          />
          <Input
            name="confirm_password"
            iconEl={<MdLock />}
            placeholder={translate('confirmPassword')}
            value={formData.confirm_password.value}
            onChange={changeHandler}
            error={errors.confirm_password}
            type="password"
          />
          <div className="form__foot">
            <Button type="button" text={<Translate name="cancel" />} normalPrimary onButtonClick={onModalClose} />
            <Button text={<Translate name="changePassword" />} primary />
          </div>
        </form>
      </DashboardBox>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  onModalClose: PropTypes.func.isRequired
}

export default ChangePasswordModal;