import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import placeholder from '../../assets/images/placeholder-image.png';
import Button from './Button';
import Radio from './Radio';
import Translate from '../../i18n/Translate';
import { LangContext } from '../../context/lang/langState'
import Loader from '../UI/Loader';

const Solution = ({ image, title, fields, onButtonClick, choices, choicesName, onChoiceChange, showMPBtn, onMPAdd = () => {}, alreadyAddedToMP, listView, removeFromMPBtn, onMPRemove = () => {}, listViewCentered }) => {
  const { apiLanguage, translate } = useContext(LangContext)
  const [isLoading, setIsLoading] = useState(true)
  const [addToMPBtnDisabled, setAddToMPBtnDisabled] = useState(alreadyAddedToMP);

  let fireRatingPerformanceE = fields.sysVarFireRatingPerformanceE?.value.displayValue || { [apiLanguage]: '', en: '' }
  let fireRatingPerformanceEI = fields.sysVarFireRatingPerformanceEI?.value.displayValue || { [apiLanguage] : '', en: '' }
  let pipeEndConfiguration = fields.sysVarPipeEndConfiguration?.value.displayValue || { [apiLanguage]: '', en: '' }
  let performance = ''
  if(fireRatingPerformanceEI[apiLanguage] || fireRatingPerformanceEI.en) {
    performance += fireRatingPerformanceEI[apiLanguage] || fireRatingPerformanceEI.en
  }
  if(fireRatingPerformanceE[apiLanguage] || fireRatingPerformanceE.en) {
    performance += performance ? ' - ' : ''
    performance += fireRatingPerformanceE[apiLanguage] || fireRatingPerformanceE.en
  }
  if(pipeEndConfiguration) {
    performance += performance ? ' ' : ''
    performance += pipeEndConfiguration[apiLanguage] || pipeEndConfiguration.en
  }
  let fireRating = `${translate('DETAIL.fireRatingPerformance')}: ${performance}`

  const addToMPHandler = () => {
    onMPAdd(() => {
      setAddToMPBtnDisabled(true);
    });
  }

  return (
    <div className={!listView ? "solution" : listViewCentered ? "solution solution--list solution--list-centered" : "solution solution--list"}>
      <div className="solution__img" onClick={onButtonClick}>
        <img
          className={!image ? 'default' : ''}
          src={image ? image : placeholder}
          alt={title}
          onError={(e) => { e.target.onerror = null; e.target.src = placeholder }}
          onLoad={() => setIsLoading(false)}
        />
        {isLoading && <Loader small normal/>}
      </div>
      <div className="solution__info">
        <h6 className="solution__heading">{title}</h6>
        <div className="solution__desc">{fireRating || ''}</div>
        {/* <div className="solution__choice">
          { choices.length > 0 && (
            <>
              <p><Translate name="multiplePenetration" /></p>
              <Radio
                name={choicesName}
                choices={choices}
                onChange={onChoiceChange}
              />
            </>
          )}
        </div> */}
        <Button
          text={<Translate name="displaySolutionDetail" />}
          onButtonClick={onButtonClick}
          medium
          primary
          fullwidth
        />
        {showMPBtn && <Button 
          text={!addToMPBtnDisabled ? <Translate name="addSolutionToMP" /> : <Translate name="alreadyAdded" />}
          onButtonClick={addToMPHandler}
          medium
          textSmall
          fullwidth
          className="mt-2"
          disabled={addToMPBtnDisabled}
        />}
        {removeFromMPBtn && <Button 
          text={<Translate name="remove" />}
          onButtonClick={onMPRemove}
          medium
          fullwidth
          className="mt-2"
        />}
      </div>
    </div>
  );
}

Solution.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired
}

export default Solution;