import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { MdSearch, MdKeyboardArrowLeft, MdHelpOutline } from 'react-icons/md';
import SVG from 'react-inlinesvg';
import ReactGA from 'react-ga';

import Button from '../UI/Button';
import SolutionImages from '../UI/SolutionImages';
import SolutionInfo from '../UI/SolutionInfo';
import ContactUsModal from '../sections/ContactUsModal';
import timeIcon from '../../assets/images/solution-time-icon.svg';
import throughIcon from '../../assets/images/solution-though-icon.svg';
import diameterIcon from '../../assets/images/solution-diameter-icon.svg';
import peIcon from '../../assets/images/solution-pe-icon.svg';
import plasticIcon from '../../assets/images/solution-plastic-icon.svg';
import widthIcon from '../../assets/images/solution-width-icon.svg';
// import timeIcon from '../../assets/images/solution-icon-blank-2.png';
// import throughIcon from '../../assets/images/solution-icon-blank-1.png';
// import diameterIcon from '../../assets/images/solution-icon-blank-3.png';
// import peIcon from '../../assets/images/solution-icon-blank-5.png';
// import plasticIcon from '../../assets/images/solution-icon-blank-6.png';
// import widthIcon from '../../assets/images/solution-icon-blank-4.png';

import imagesLoaded from '../../helpers/imagesLoaded';
import PageLoader from '../UI/PageLoader';
import Translate from '../../i18n/Translate';
import ReactTooltip from 'react-tooltip';
import Notification from '../UI/Notification';
import { LangContext } from '../../context/lang/langState';

import { StepsContext } from '../../context/stepsState';
import { UserContext } from '../../context/userState';
import { MPContext } from '../../context/multiplePenetrationState';
import { mpCompatibilityError, cookiesAccepted, isMultiplePenetrationEnabled } from '../../utils';
import { env } from '../../config.json'

import mime from 'mime-types'

import EtexApi from '../../services/EtexApi';
import axios from 'axios';
import Bugsnag from '@bugsnag/browser';

const SolutionDetail = ({ onBackToSolutionList, onBackToStep, singleSystemVariant, stepName, stepIndex }) => {
  const { systemVariantDetail, setLoadedStep, setSteps, steps, setSystemVariantDetail, setStep, systems, setSystems } = useContext(StepsContext);
  
  const { user, addNewSystemVariant } = useContext(UserContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { language, apiLanguage } = useContext(LangContext)
  const { addSolutionToMP, setMPMode, mpSolutionsIds, mpSolutions } = useContext(MPContext);
  const langShort = language.split('-')[0]

  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(null);
  const [loadingDocumentation, setLoadingDocumentation] = useState(false);
  const history = useHistory();

  const systemVariant = singleSystemVariant || systemVariantDetail;
  // const isBlankseal = systemVariant.fields.sysVarFireStoppingType?.value === 'blankseal';

  const [icons, setIcons] = useState([
    { icon: timeIcon, loading: true },
    { icon: widthIcon, loading: true },
    { icon: peIcon, loading: true },
    { icon: throughIcon, loading: true },
    { icon: plasticIcon, loading: true },
    { icon: diameterIcon, loading: true },
  ]);
  useEffect(() => {
    if (imagesLoaded(icons, 'loading')) {
      setIsLoading(false);
    }
  }, [icons]);

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(`/step-${stepIndex}`, [], stepName);
    }
    // console.log(`/step-${stepIndex}`, [], stepName);
    // ReactGA.event({
    //   category: stepName,
    //   action: stepName
    // });
    // eslint-disable-next-line
  }, []);

  const imageLoadHandler = (info) => {
    const iconsClone = [...icons];
    const infoObj = icons.filter(obj => obj.icon === info.icon)[0];
    infoObj.loading = false;
    setIcons(iconsClone);
  }

  const cleanObject = (object) => Object.entries(object).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});

  const saveSolution = async () => {
    if (user) {
      setSaveDisabled(true);

      try {
        await addNewSystemVariant(systemVariant.id, systemVariant.name);

        if(cookiesAccepted()) {
          ReactGA.event({
            category: 'Solution detail',
            action: 'Save Solution'
          });
        }

        history.push('/dashboard');
      } catch(e) {
        // TODO handle error
        console.log('error', e);
      }
      setSaveDisabled(false);
    } else {
      history.push('/signin');
    }
  }

  const extractField = (systemVariant, field, withUnit = false) => {
    let result = systemVariant?.fields[field];

    if (typeof result === 'undefined') return 'N/A';

    if (typeof result !== 'number' && typeof result !== 'string') {
      let label = systemVariant.fields[field].label;
      label = label[apiLanguage] || label.en
      let value = systemVariant.fields[field].value;
      let unit = '';

      if (typeof value !== 'number' && typeof value !== 'string') {
        unit = value.unit
        if(value.displayValue) {
          value = value.displayValue
        } else {
          value = value.rawValue;
        }
      }

      if (value[apiLanguage] || value.en) {
        // localized
        value = value[apiLanguage] || value.en
      }

      if (withUnit) {
        result = `${label ? `${label}: ` : ''}${value} ${unit || ''}`
      } else {
        result = `${label ? `${label}: ` : ''}${value}`
      }
    }

    return result
  }

  const fireResistance = extractField(systemVariant, 'sysVarFireResistance', true);
  const diameter = extractField(systemVariant, 'sysVarProductDiameter', true);
  const thickness = extractField(systemVariant, 'sysVarCompartmentationThickness', true);
  const penetrationMaterial = extractField(systemVariant, 'sysVarPenetrationMaterial', false);
  const penetrationProductType = extractField(systemVariant, 'sysVarPenetrationProductType', false);
  const compartmentationType = extractField(systemVariant, 'sysVarCompartmentationType', false);

  const downloadDocumentation = async () => {
    let fetchDocumentRequestData = await EtexApi.fetchDocument(systemVariant.relatedDocumentIds?.find(Boolean), 'original')
    // console.log(fetchDocumentRequestData);

    setLoadingDocumentation(true)
    return axios.get(fetchDocumentRequestData.data.url, { headers: fetchDocumentRequestData.data.headers, responseType: 'arraybuffer' })
    .then(apiResponse => {
      // console.log(apiResponse);
      let base64 = Buffer.from(apiResponse.data, 'binary').toString('base64')

      const type = apiResponse.headers['content-type'] || 'application/pdf'

      const linkSource = `data:${type};base64,${base64}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `Promat - ${systemVariant.name[apiLanguage]} - Documentation.${mime.extension(type)}`;
      downloadLink.click();
      downloadLink.remove();
      setLoadingDocumentation(false);
    })
    .catch(err => {
      console.log('fetch document error')
      console.log(err)
      setLoadingDocumentation(false)
      return { error: err }
    })
  }   


  let solutionIcons = [];
  
  if (singleSystemVariant) {
    solutionIcons = [
      <div data-tip={fireResistance}><SVG src={diameterIcon} onLoad={() => imageLoadHandler(icons[0])} /></div>,
      <div data-tip={diameter}><SVG src={peIcon} onLoad={() => imageLoadHandler(icons[1])} /></div>,
      <div data-tip={thickness}><SVG src={plasticIcon} onLoad={() => imageLoadHandler(icons[2])} /></div>,
      <div data-tip={penetrationMaterial}><SVG src={timeIcon} onLoad={() => imageLoadHandler(icons[3])} /></div>,
      <div data-tip={penetrationProductType}><SVG src={throughIcon} onLoad={() => imageLoadHandler(icons[4])} /></div>,
      <div data-tip={compartmentationType}><SVG src={widthIcon} onLoad={() => imageLoadHandler(icons[5])} /></div>,
    ]
  } else {
    solutionIcons = [
      <div data-tip={fireResistance}><SVG src={diameterIcon} onLoad={() => imageLoadHandler(icons[0])} onClick={() => onBackToStep(3)} /></div>,
      <div data-tip={diameter}><SVG src={peIcon} onLoad={() => imageLoadHandler(icons[1])} onClick={() => onBackToStep(6)} /></div>,
      <div data-tip={thickness}><SVG src={plasticIcon} onLoad={() => imageLoadHandler(icons[2])} onClick={() => onBackToStep(2)} /></div>,
      <div data-tip={penetrationMaterial}><SVG src={timeIcon} onLoad={() => imageLoadHandler(icons[3])} onClick={() => onBackToStep(5)} /></div>,
      <div data-tip={penetrationProductType}><SVG src={throughIcon} onLoad={() => imageLoadHandler(icons[4])} onClick={() => onBackToStep(4)} /></div>,
      <div data-tip={compartmentationType}><SVG src={widthIcon} onLoad={() => imageLoadHandler(icons[5])} onClick={() => onBackToStep(2)} /></div>,
    ]
  }

  const addToMPHandler = () => {
    let error = mpCompatibilityError(systemVariant, mpSolutions, apiLanguage)
    if(!error) {
      setShowNotification(true)
      addSolutionToMP({...systemVariant})
    } else {
      setErrorNotification(error)
    }
  }

  const isAlreadyInMP = (id) => {
    const solutionsLS = localStorage.getItem('mpSolutions') ? JSON.parse(localStorage.getItem('mpSolutions')) : [];
    if (solutionsLS.length === 0) {
      return false;
    }
    if (mpSolutionsIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  const mpLinkClickHandler = (e) => {
    e.preventDefault();
    setMPMode(true);
    setShowNotification(false);
    setSystemVariantDetail(null);
    const isBlankseal = steps.find((step) => step.stepKey === 'fireStoppingType')?.key === 'blankseal';
    const sliceStepIndex = isBlankseal ? 5 : 7;
    setStep(7);
    setLoadedStep(sliceStepIndex);
    setSteps(steps.slice(0, sliceStepIndex));
  }

  useEffect(() => {
    if(!systems || !systemVariant || !systemVariant.relatedSystemIds || systemVariant.relatedSystemIds.length === 0) {
      return
    }
    if(!systems[systemVariant.relatedSystemIds[0]]) {
      fetchSystem(systemVariant.relatedSystemIds[0])
    }
  }, [systems, systemVariant])

  const systemForSolution = () => {
    if(!systemVariant || !systemVariant.relatedSystemIds || systemVariant.relatedSystemIds.length === 0) {
      return
    }
    if(systemVariant.relatedSystemIds.length > 1) {
      Bugsnag.notify(new Error(`Encountered systemVariant with multiple parent systems, Id: ${systemVariant.id}, relatedSystemIds: ${systemVariant.relatedSystemIds.join(',')}, Environment: ${env}`))
    }
    let systemId = systemVariant.relatedSystemIds[0]
    if(!systems || !systems[systemId]) {
      return
    }
    return systems[systemId]
  }

  const fetchSystem = async (systemId) => {
    const systemData = await EtexApi.getSystem(systemId, apiLanguage)
    setSystems({
      ...systems,
      [systemId]: systemData
    })
  }

  return (
    <Fragment>
      {isLoading && <PageLoader />}
      <div className="solution-detail">
        <div className="solution-detail__top">
          <div className="mr-auto pr-3 d-flex align-items-center flex-wrap">
            {/* {!singleSystemVariant && <Button
              text={<Translate name="backToSolutions" />}
              onButtonClick={onBackToSolutionList}
              className="mr-3 mb-3 btn--icon-animate"
              iconEl={<MdKeyboardArrowLeft />}
            />} */}
            <div>
              <h2>{systemVariant?.name[apiLanguage] || systemVariant?.name['en']}</h2>
              { <span className="solution-detail__subtitle">{ systemVariant?.id }</span> }
            </div>
          </div>
          <Button
            text={<Translate name="askAQuestion" />}
            onButtonClick={() => setShowContactUsModal(true)}
            className="icon-small"
            iconEl={<MdHelpOutline />}
          />
        </div>
        <div className="solution-detail__main">
          <SolutionImages
            images={systemVariant?.relatedResourceIds?.map((resourceId) => EtexApi.assetUrl(resourceId, 'original'))}
          />
          <SolutionInfo
            printBtnText={<Translate name="printOrDownload" />}
            printIcon={<i className="custom-icon-print-outline"></i>}
            saveBtnText={<Translate name="saveSolution" />}
            saveBtnDisabled={saveDisabled}
            onPrintButtonClick={() => window.print()}
            onSaveButtonClick={saveSolution}
            // hasInstallationDetails={!!firebase.auth().currentUser && (!!singleSystemVariant && systemVariant.relatedDocumentIds && systemVariant.relatedDocumentIds.length > 0)}
            hasInstallationDetails={false}
            installBtnText={<Translate name="documentation" />}
            onInstallButtonClick={downloadDocumentation}
            isLoadingDocumentation={loadingDocumentation}
            title={systemVariant?.name[apiLanguage]}
            systemVariantFields={systemVariant?.fields}
            // icons={['time', 'diameter', 'width', 'pe', 'plastic', 'through']}
            icons={solutionIcons}
            showMPBtn={(isMultiplePenetrationEnabled(systemVariant, apiLanguage)) && !singleSystemVariant}
            onAddToMP={addToMPHandler}
            addToMPBtnDisabled={isAlreadyInMP(systemVariant?.id)}
            system={systemForSolution()}
          />
        </div>
      </div>

      {showContactUsModal && <ContactUsModal onModalClose={() => setShowContactUsModal(false)} solutionId={systemVariant?.id} />}
      <ReactTooltip />
      {showNotification && <Notification 
        onClose={() => setShowNotification(false)}
        type="success" 
        message={
          <p><Translate name="solutionAddedTo" /> <Link to="/" onClick={mpLinkClickHandler}><Translate name="multiplePenetration" /></Link></p>
        } 
      />}
      {errorNotification && <Notification
        onClose={() => setErrorNotification(null)}
        type="error"
        message={
          <p><Translate name={`mp_error.${errorNotification}`} /></p>
        }
      />}
    </Fragment>
  );
}

SolutionDetail.propTypes = {
  onNumericStepChange: PropTypes.func,
}

export default SolutionDetail;
