import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

const Select = ({ label, onChange, name, value, defaultText, className, error, options }) => {
  const field = useRef();
  const input = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(value === '' ? defaultText : value);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  const changeSelected = (item) => {
    const value = item.value;
    const label = item.label;
    setSelectedLabel(label);
    setShowDropdown(false);
    onChange(name, value);
    input.current.value = value;
  }

  const handleClickOutside = useCallback((e) => {
    if(e.target.closest('.select') !== field.current) {
      setShowDropdown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [handleClickOutside]);

  let selectClasses = 'select';

  if(className) {
    selectClasses += ` ${className}`;
  }

  if(error) {
    selectClasses += ' error';
  }

  return(
    <div className={selectClasses} ref={field}>
      {label && <label className="select__label">{label}</label>}
      <input type="hidden" name={name} onChange={onChange} value={value} ref={input} />
      <div className="select__main">
        <div className={showDropdown ? 'select__main_selected opened' : 'select__main_selected'} onClick={toggleDropdown}>{selectedLabel}</div>
        {showDropdown &&
          <ul className="select__main_dropdown">
            {options.map(item => (
              <li key={item.value} onClick={() => changeSelected(item)}>{item.label}</li>
            ))}
          </ul>
        }
      </div>
      {error && <div className="select__error">{error}</div>}
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), 
  onChange: PropTypes.func.isRequired, 
  name: PropTypes.string, 
  value: PropTypes.string.isRequired, 
  defaultText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]), 
  className: PropTypes.string, 
  error: PropTypes.string, 
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.string]) 
}

export default Select;