import React from 'react';
import PropTypes from 'prop-types';

const AccountReadField = ({ label, value }) => {
  return(
    <div className="account-read__box">
      <div className="account-read__box_label">{label}</div>
      <div className="account-read__box_field">{value}</div>
    </div>
  );
}

AccountReadField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired
}

export default AccountReadField;