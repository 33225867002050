import React, { Fragment } from 'react';

import Header from '../sections/Header';
import Footer from '../sections/Footer';
import Translate from '../../i18n/Translate';

const Disclaimer = () => {
  return(
    <Fragment>
      <Header />
      <section className="text-section">
        <div className="container">
          <div className="text-section__inner">
            <section>
              <h1><Translate name="disclaimer" /></h1>
              <p><Translate name="disclaimerText1" /></p>
              <p><Translate name="disclaimerText2" /></p>
              <p><Translate name="disclaimerText3" /></p>
              <p><Translate name="disclaimerText4" /></p>
              <p><Translate name="disclaimerText5" /></p>
              <p><Translate name="disclaimerText6" /></p>
              <p><Translate name="disclaimerText7" /></p>
              <p><Translate name="disclaimerText8" /></p>
              <p><Translate name="disclaimerText9" /></p>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Disclaimer;