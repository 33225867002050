import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Header from '../sections/Header';
import Footer from '../sections/Footer';

const Main = ({ children }) => {

  return(
    <div className="main-layout">
      <Header />
      <div className="container">
        {children}
      </div>
      <Footer />
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node
}

export default Main;