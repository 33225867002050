export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_LOADER = 'SET_LOADER';

export const SET_STEP = 'SET_STEP';
export const SET_STEP_LOADING = 'SET_STEP_LOADING';

// Multiple penetration
export const ADD_TO_MP = 'ADD_TO_MP';
export const REMOVE_FROM_MP = 'REMOVE_FROM_MP';
export const SET_MP_MODE = 'SET_MP_MODE';
export const CLEAR_MP = 'CLEAR_MP';