import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MdEmail, MdLock } from 'react-icons/md';
import ReactGA from 'react-ga';

import firebase from "firebase/app";
import "firebase/auth";

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { LangContext } from '../../context/lang/langState';
import Translate from '../../i18n/Translate';
import Alert from '../UI/Alert';
import { cookiesAccepted } from '../../utils';

const initialData = {
  email: {
    email: true,
    value: ''
  },
  password: {
    required: true,
    value: ''
  }
};

const SignIn = () => {
  const { formData, errors, changeHandler, setErrors, isValid } = useForm(initialData, validate);
  const [showText, setShowText] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const history = useHistory();
  const { translate } = useContext(LangContext);

  const submitHandler = (e) => {
    e.preventDefault();
    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0 && showText) {
      setShowText(false);
      setDisableInput(true);
    
      firebase.auth().signInWithEmailAndPassword(formData.email.value, formData.password.value).then(() => {
        if(cookiesAccepted()) {
          ReactGA.event({
            category: 'Login',
            action: 'Login'
          });
        }
      }).catch((error) => {
        setShowText(true);
        setDisableInput(false);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        const alertType = "error"
        const text = errorMessage
        setAlertMessage(text)
        setAlertType(alertType)
      });
      
    }
  }

  return(
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_top d-flex justify-content-between">
          <h6><Translate name="signIn" /></h6>
          <p><Link to="/forgot-password"><Translate name="forgotPassword" /></Link></p>
        </div>
        <div className="auth__box_main">
          <form onSubmit={submitHandler}>
            <Input
              name="email"
              type="email"
              value={formData.email.value}
              onChange={changeHandler}
              error={errors.email}
              iconEl={<MdEmail />}
              placeholder={translate('email')}
              disabled={disableInput}
            />
            <Input
              name="password"
              value={formData.password.value}
              onChange={changeHandler}
              error={errors.password}
              iconEl={<MdLock />}
              placeholder={translate('password')}
              type="password"
              disabled={disableInput}
            />
            <Button
              text={showText || !isValid ? <Translate name="signIn" /> : <Loader small normalWhite />}
              primary
              fullwidth
              disabled={!isValid}
              loading={!showText && isValid}
            />
          </form>
          { alertMessage && <><br/><Alert type={ alertType } message={ alertMessage } /></> }
        </div>
      </div>
      <div className="auth__note">
        <p className="auth__note_account"><Translate name="dontHaveAnAccount" /> <Link to="/signup"><Translate name="signUpHere" /></Link></p>
      </div>
    </div>
  );
}

export default SignIn;