import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import Intro from '../sections/Intro';
import Footer from '../sections/Footer';

const Authentication = (props) => {
  const history = useHistory();

  return(
    <div className="authentication">
      <div className="authentication__close" onClick={() => history.push('/')}>
        <i className="custom-icon-close"></i>
      </div>
      <div className="authentication__inner">
        <header className="authentication__header">
          <Link to="/">
            <img src={logo} alt="Promat"/>
          </Link>
        </header>
        <Intro />
        <div className="authentication__content">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Authentication;