import React, { Fragment, useState, useEffect, useContext } from 'react';
import { MdApps, MdReorder, MdDescription, MdCreateNewFolder } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';

import ProjectGrid from '../../UI/ProjectGrid';
import ProjectList from '../../UI/ProjectList';
import Button from '../../UI/Button';
import SelectedProjects from '../../sections/SelectedProjects';
import CreateNewProjectModal from '../../sections/CreateNewProjectModal';
import NoProjects from '../../sections/NoProjects';

import { UserContext } from '../../../context/userState';
import { LangContext } from '../../../context/lang/langState';

const MyProjects = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState('grid');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showCreateNewProjectModal, setShowCreateNewProjectModal] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);

  const [folderStructure, setFolderStructure] = useState([]);
  const [systemVariantReferences, setSystemVariantReferences] = useState({});
  const [projectsData, setProjectsData] = useState([]);
  const { user, addNewFolder, updateFolder, deleteFolder, deleteSystemVariant, updateUser, selectedFolder, setSelectedFolder } = useContext(UserContext);
  const { apiLanguage, translate } = useContext(LangContext);

  useEffect(() => {
    // construct projects data from folders and system variants

    const allFolders = {
      root: { id: 'root', title: translate('myProjects'), folders: [], systemVariants: [] },
    };
    const newSystemVariantReferences = [];

    for (const folder of user.folders) {
      allFolders[folder.id] = { reference: folder, id: folder.id, parent: folder.parent, title: folder.title, folders: [], systemVariants: [] };
    }

    for (const folder of user.folders) {
      if (!allFolders[folder.parent]) allFolders[folder.id].parent = 'root';

      allFolders[allFolders[folder.id].parent].folders.push(allFolders[folder.id]);
    }

    for (const systemVariant of user.systemVariants) {
      newSystemVariantReferences[systemVariant.id] = systemVariant;

      const formattedSystemVariant = { reference: systemVariant, parent: systemVariant.parent, id: systemVariant.id, title: systemVariant.title };
      if (!allFolders[formattedSystemVariant.parent]) formattedSystemVariant.parent = 'root';

      allFolders[formattedSystemVariant.parent].systemVariants.push(formattedSystemVariant);
    }

    let newProjectsData = [];
    for (const folder of allFolders[selectedFolder].folders.sort((folder) => folder.title)) {
      newProjectsData.unshift({ parent: folder.parent, id: folder.id, title: folder.title, solutions: folder.systemVariants.length, folder: true });
    }

    for (const systemVariant of allFolders[selectedFolder].systemVariants.sort((systemVariant) => systemVariant.title)) {
      newProjectsData.unshift({ parent: systemVariant.parent, id: systemVariant.id, title: systemVariant.title, solutions: systemVariant.reference.multiplePenetration ? systemVariant.reference.variants.length : 0, folder: false, multiplePenetration: systemVariant.reference.multiplePenetration, variants: systemVariant.reference.multiplePenetration ? systemVariant.reference.variants : [] });
    }

    setFolderStructure(allFolders);
    setSystemVariantReferences(newSystemVariantReferences);
    setProjectsData(newProjectsData);
  }, [selectedFolder].concat([(user?.folders || []).map((folder) => folder.id + folder.parent + folder.title).join('')]).concat([(user?.systemVariants || []).map((systemVariant) => systemVariant.id + systemVariant.parent).join('')]))

  const createNewFolder = async (folderName) => {
    await addNewFolder(folderName, selectedFolder);
  }

  const removeFolder = async (folderId) => {
    await deleteFolder(folderStructure[folderId].reference);
  }

  const editFolderName = (folderId) => {
    setEditingFolder(folderStructure[folderId].reference);
  }

  const updateFolderName = async (folderReference, folderName) => {
    await updateFolder(folderReference, folderName);
  }

  const removeSystemVariant = async (systemVariantId) => {
    await deleteSystemVariant(systemVariantReferences[systemVariantId]);
  }

  const moveSelectedToFolder = async (newParentId) => {
    const systemVariantIds = selectedProjects.filter((project) => !project.folder).map((project) => project.id);
    const folderIds = selectedProjects.filter((project) => project.folder).map((project) => project.id);

    let newFolders = user.folders.map((folder) => {
      if (!folderIds.includes(folder.id)) return folder;
      return { ...folder, parent: newParentId };
    })

    let newSystemVariants = user.systemVariants.map((systemVariant) => {
      if (!systemVariantIds.includes(systemVariant.id)) return systemVariant;
      return { ...systemVariant, parent: newParentId };
    })

    await updateUser({ folders: newFolders, systemVariants: newSystemVariants });

    setSelectedProjects([]);
  }

  const removeSelectedItems = async () => {
    const systemVariantIds = selectedProjects.filter((project) => !project.folder).map((project) => project.id);
    const folderIds = selectedProjects.filter((project) => project.folder).map((project) => project.id);

    let newFolders = user.folders.filter((folder) => {
      return !folderIds.includes(folder.id);
    })

    let newSystemVariants = user.systemVariants.filter((systemVariant) => {
      return !systemVariantIds.includes(systemVariant.id);
    })

    setSelectedProjects([]);

    await updateUser({ folders: newFolders, systemVariants: newSystemVariants });
  }

  //   { title: 'Project Antwerpen', solutions: 3, folder: true },
  //   { title: 'PROMASTOP-FC3 Wall massive Pipe PE 90 mm', solutions: 0, folder: false },
  //   { title: 'Project Antwerpen', solutions: 3, folder: true },
  //   { title: 'Project Antwerpen', solutions: 3, folder: true },
  //   { title: 'Project Antwerpen', solutions: 3, folder: true },
  //   { title: 'Project Antwerpen', solutions: 3, folder: true }
  // ]);



  let projectsClasses = '';

  if (view === 'grid') {
    projectsClasses = "projects__body projects__body--grid";
  } else if (view === 'list') {
    projectsClasses = "projects__body projects__body--list";
  }

  if(projectsData.length === 0) {
    projectsClasses = "projects__body";
  }

  if(isLoading) {
    projectsClasses = "projects__body projects__body--grid";
  }

  const projectSelectHandler = (project, e) => {
    if(e.target.checked && selectedProjects.filter(obj => obj.id === project.id).length === 0) {
      setSelectedProjects(prev => [...prev, project]);
    }else if(!e.target.checked && selectedProjects.filter(obj => obj.id === project.id).length === 1){
      setSelectedProjects(prev => [...prev].filter(obj => obj.id !== project.id));
    }
  }

  const removeLastSolutionHandler = () => {
    const selectedProjectsClone = [...selectedProjects];
    selectedProjectsClone.pop();
    setSelectedProjects(selectedProjectsClone);
  }

  const createNewProjectClickHandler = (e) => {
    e.preventDefault();
    setShowCreateNewProjectModal(true);
  }

  const viewChangeHandler = (view, e) => {
    e.preventDefault();
    setView(view);
  }

  return (
    <Fragment>
      <div className={view === 'grid' ? 'projects projects--grid' : 'projects'}>
        { !!folderStructure[selectedFolder]?.parent && <Button outlineSecondary onButtonClick={() => setSelectedFolder(folderStructure[selectedFolder].parent) } text={ `${translate('goBackTo')} ${folderStructure[folderStructure[selectedFolder].parent].title}` } /> }
        <div className="projects__head">
          <h4>{ folderStructure[selectedFolder]?.title }</h4>
          <div className="projects__head_right">
            <a href="/#" className={view === 'grid' ? 'active' : ''} onClick={(e) => viewChangeHandler('grid', e)}><MdApps /></a>
            <a href="/#" className={view === 'list' ? 'active' : ''} onClick={(e) => viewChangeHandler('list', e)}><MdReorder /></a>
          </div>
        </div>
        <div className={projectsClasses}>
          { !isLoading ?
              projectsData.length === 0
                ?
                  <NoProjects
                    onShowNewProjectModal={setShowCreateNewProjectModal}
                  />
                :
                  view === 'grid'
                    ?
                      <Fragment>
                        <ProjectGrid
                          newProject
                          onShowNewProjectModal={setShowCreateNewProjectModal}
                        />
                        {projectsData.map((project, index) => (<ProjectGrid
                            key={project.id}
                            projectId={project.id}
                            title={typeof project.title === 'object' ? project.title[apiLanguage] : project.title}
                            solutions={project.solutions}
                            onCheckboxChange={(e) => projectSelectHandler(project, e)}
                            selected={[...selectedProjects].filter(obj => obj.id === project.id).length > 0}
                            folder={project.folder}
                            onClickFolder={(folderId) => setSelectedFolder(folderId) }
                            onClickSolution={(solutionId) => history.push(`/solution/${systemVariantReferences[solutionId].systemVariantId}`)}
                            onClickMP={() => history.push({ pathname: '/multiple-penetration', state: { data: project }})}
                            onRemoveFolder={(folderId) => removeFolder(folderId)}
                            onRenameFolder={(folderId) => editFolderName(folderId)}
                            onRemoveSolution={(systemVariantId) => removeSystemVariant(systemVariantId)}
                            multiplePenetration={project.multiplePenetration}
                          />
                        ))}
                      </Fragment>
                    :
                    <Fragment>
                    <div className="projects__body_top">
                      <ul>
                        <li>
                          <Link to="/">
                            <i className="custom-icon-document-outline"></i> { translate('addNewSolution') }
                          </Link>
                        </li>
                        <li>
                          <Link to="/dashboard" onClick={createNewProjectClickHandler}>
                            <i className="custom-icon-folder-outline"></i> { translate('newProject') }
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="project__body_main">
                      {projectsData.map((project, index) => (
                        <ProjectList
                          key={project.id}
                          title={typeof project.title === 'object' ? project.title[apiLanguage] : project.title}
                          projectId={project.id}
                          solutions={project.solutions}
                          onCheckboxChange={(e) => projectSelectHandler(project, e)}
                          selected={[...selectedProjects].filter(obj => obj.id === project.id).length > 0}
                          folder={project.folder}
                          onClickFolder={(folderId) => setSelectedFolder(folderId) }
                          onClickSolution={(solutionId) => history.push(`/solution/${systemVariantReferences[solutionId].systemVariantId}`)}
                          onClickMP={() => history.push({ pathname: '/multiple-penetration', state: { data: project }})}
                          onRemoveFolder={(folderId) => removeFolder(folderId)}
                          onRenameFolder={(folderId) => editFolderName(folderId)}
                          onRemoveSolution={(systemVariantId) => removeSystemVariant(systemVariantId)}
                          multiplePenetration={project.multiplePenetration}
                        />
                      ))}
                    </div>
                  </Fragment>
            :
              <Fragment>
                <ProjectGrid
                  placeholder
                  onCheckboxChange={() => {}}
                />
                <ProjectGrid
                  placeholder
                  onCheckboxChange={() => {}}
                />
                <ProjectGrid
                  placeholder
                  onCheckboxChange={() => {}}
                />
                <ProjectGrid
                  placeholder
                  onCheckboxChange={() => {}}
                />
                <ProjectGrid
                  placeholder
                  onCheckboxChange={() => {}}
                />
              </Fragment>
          }
        </div>
      </div>

      <SelectedProjects
        solutions={selectedProjects}
        folderStructure={folderStructure}
        onMoveToFolderId={(folderId) => moveSelectedToFolder(folderId) }
        onRemoveSelectedItems={removeSelectedItems}
        onCancel={() => setSelectedProjects([])}
        onRemoveLastAddedSolution={removeLastSolutionHandler}
      />

      {showCreateNewProjectModal && <CreateNewProjectModal onModalClose={() => setShowCreateNewProjectModal(false)} onCreateNewFolder={createNewFolder} />}
      {editingFolder && <CreateNewProjectModal editingProjectName={editingFolder.title} onModalClose={() => setEditingFolder(null)} onUpdateNewFolder={(folderName) => updateFolderName(editingFolder, folderName)}/>}
    </Fragment>
  );
}

export default MyProjects;