import React, { useState, useContext } from 'react';
import ReactGA from 'react-ga';

import useForm from '../../../hooks/useForm';
import validate from '../../../helpers/validate';
import DashboardBox from '../../UI/DashboardBox';
import Input from '../../UI/Input';
import Textarea from '../../UI/Textarea';
import Button from '../../UI/Button';
import Alert from '../../UI/Alert';
import Translate from '../../../i18n/Translate';
import { LangContext } from '../../../context/lang/langState';
import { cookiesAccepted } from '../../../utils';
import EtexApi from '../../../services/EtexApi';

const initialData = {
  name: {
    required: true,
    value: ''
  },
  email: {
    email: true,
    value: ''
  },
  message: {
    required: true,
    value: ''
  }
};

const ContactUs = () => {
  const { formData, errors, setErrors, changeHandler } = useForm(initialData, validate);
  const { translate, language } = useContext(LangContext);
  
  const [sendDisabled, setSendDisabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      let name = formData.name.value;
      let email = formData.email.value;
      let message = formData.message.value;

      name = name.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      message = message.replace(/</g, "&lt;").replace(/>/g, "&gt;");

      setSendDisabled(true);
      let newAlertMessage = translate('sendEmailSuccess')
      let newAlertType = 'success';

      try {
        await EtexApi.sendEmail(name, email, message, language);
        if(cookiesAccepted()) {
          ReactGA.event({
            category: 'Contact',
            action: 'Email sent'
          });
        }
      } catch (error) {
        console.log(error)
        newAlertMessage = translate('sendEmailError');
        newAlertType = 'error';
      }

      setSendDisabled(false);
      setAlertMessage(newAlertMessage);
      setAlertType(newAlertType);
      setTimeout(() => {
        setAlertMessage('');
        setAlertType('');
      }, 1800);
    }
  }

  return(
    <DashboardBox title={translate('contactUs')}>
      <form onSubmit={submitHandler} className="form">
        <div className="form__group form__group--break-sm">
          <Input
            label={<Translate name="name" />}
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            dashboard
          />
          <Input
            label={<Translate name="email" />}
            name="email"
            value={formData.email.value}
            onChange={changeHandler}
            error={errors.email}
            dashboard
          />
        </div>
        <Textarea
          name="message"
          label={<Translate name="yourMessage" />}
          value={formData.message.value}
          onChange={changeHandler}
          error={errors.message}
          dashboard
        />
        <Button
          text={<Translate name="send" />}
          primary
          minimal
          disabled={sendDisabled}
        />

        { alertMessage.length > 0 && <><br/><br/><Alert type={ alertType } message={ alertMessage } /></> }
      </form>
    </DashboardBox>
  );
}

export default ContactUs;