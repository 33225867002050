import React, { useState, useContext } from 'react';
import { MdLock, MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Input from '../UI/Input';
import DefaultCheckbox from '../UI/DefaultCheckbox';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { LangContext } from '../../context/lang/langState';
import Translate from '../../i18n/Translate';
import Alert from '../UI/Alert';
import { cookiesAccepted } from '../../utils';

const initialData = {
  firstName: {
    required: true,
    value: ''
  },
  lastName: {
    required: true,
    value: ''
  },
  email: {
    email: true,
    value: ''
  },
  password: {
    required: true,
    password: true,
    value: ''
  },
  terms: {
    required: true,
    value: false
  },
  marketing: {
    touched: true,
    value: false
  }
}

const SignUp = () => {
  const { formData, errors, changeHandler, setErrors, isValid } = useForm(initialData, validate);
  const [showText, setShowText] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const { translate } = useContext(LangContext);

  const submitHandler = (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0 && showText) {
      setShowText(false);
      setDisableInput(true);

      firebase.auth().createUserWithEmailAndPassword(formData.email.value, formData.password.value).then(() => {
        const user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: `${formData.firstName.value} ${formData.lastName.value}`,
        });
        firebase.firestore().collection("users").doc(user.uid).set({
          firstName: formData.firstName.value,
          lastName: formData.lastName.value,
          marketing: formData.marketing.value,
          terms: formData.terms.value,
          folders: [], // { name: 'Folder name', parent: 'root OR folderId' }
          systemVariants: [], // { systemVariantId: 'pi12345', name: 'Some name', parent: 'root OR folderId' }
          company: {}
        });
        if(cookiesAccepted()) {
          ReactGA.event({
            category: 'Sign Up',
            action: 'Create account'
          });
        }

      }).catch((error) => {
        setShowText(true);
        setDisableInput(false);

        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        const alertType = "error"
        const text = errorMessage
        setAlertMessage(text)
        setAlertType(alertType)
      });

    }
  }

  return(
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_top d-flex justify-content-between">
          <h6><Translate name="createAnAccount" /></h6>
          <p><MdLock /></p>
        </div>
        <div className="auth__box_main">
          <form onSubmit={submitHandler} className="form">
            <div className="form__group form__group--break-xxs">
              <Input
                name="firstName"
                value={formData.firstName.value}
                onChange={changeHandler}
                error={errors.firstName}
                placeholder={translate('firstName')}
                disabled={disableInput}
              />
              <Input
                name="lastName"
                value={formData.lastName.value}
                onChange={changeHandler}
                error={errors.lastName}
                placeholder={translate('lastName')}
                disabled={disableInput}
              />
            </div>
            <Input
              name="email"
              type="email"
              value={formData.email.value}
              onChange={changeHandler}
              error={errors.email}
              placeholder={translate('email')}
              iconEl={<MdEmail />}
              disabled={disableInput}
            />
            <Input
              name="password"
              value={formData.password.value}
              onChange={changeHandler}
              error={errors.password}
              placeholder={translate('createAPassword')}
              type="password"
              iconEl={<MdLock />}
              disabled={disableInput}
            />
            <DefaultCheckbox
              name="terms"
              onChange={changeHandler}
              label={translate('signUpTermsText')}
              labelLinkTo="/privacy-policy"
              labelLinkText={translate('signUpTermsLinkText')}
              error={errors.terms}
            />
            <DefaultCheckbox
              name="marketing"
              onChange={changeHandler}
              label={translate('signUpMarketingText')}
              labelLinkText={translate('signUpMarketingLinkText')}
              labelLinkTo="/privacy-policy"
            />
            <Button
              text={showText || !isValid ? <Translate name="signUpForFree" /> : <Loader small />}
              primary
              fullwidth
              loading={!showText && isValid}
              disabled={!isValid}
            />
          </form>
          { alertMessage && <><br/><Alert type={ alertType } message={ alertMessage } /></> }
        </div>
      </div>
      <div className="auth__note">
        <p className="auth__note_terms"><Translate name="signUpTerms" /></p>
        <p className="auth__note_account"><Translate name="alreadyHaveAccount" /> <Link to="/signin"><Translate name="signInHere" /></Link></p>
      </div>
    </div>
  );
}

export default SignUp;