import React, { useState } from 'react';
import PropTypes from 'prop-types';

import placeholder from '../../assets/images/placeholder-image.png';
import ZoomImage from './ZoomImage';
import Loader from '../UI/Loader';

const SolutionImages = ({ images }) => {
  const [mainImage, setMainImage] = useState((!!images?.length && images.length > 0) ? images[0] : placeholder);
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className="solution-images">
      <div className={`solution-images__main ${!images?.length || isLoading ? 'solution-images__main--default' : ''}`}>
        <ZoomImage img={mainImage} setLoadingImage={setIsLoading}/>
        {isLoading && <Loader small normal/>}
      </div>
      <div className="solution-images__thumbs">
        {images?.map((img, index) => (
          <div key={index} className="solution-images__thumbs_thumb" onClick={() => setMainImage(img)}>
            <img src={img} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

SolutionImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}

export default SolutionImages;