import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Modal from '../UI/Modal';
import DashboardBox from '../UI/DashboardBox';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { LangContext } from '../../context/lang/langState';
import { UserContext } from '../../context/userState';
import Translate from '../../i18n/Translate';

let initialData = {
  project_name: {
    required: true,
    value: ''
  }
}

const CreateNewProjectModal = ({ editingProjectName, onModalClose, onCreateNewFolder, onUpdateNewFolder }) => {
  const [ saveDisabled, setSaveDisabled ] = useState(false);

  if (editingProjectName) {
    initialData.project_name.value = editingProjectName;
  }

  const { formData, errors, setErrors, changeHandler } = useForm(initialData, validate);
  const { translate } = useContext(LangContext);

  const submitHandler = async (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      if (editingProjectName) {
        try {
          setSaveDisabled(true);
          await onUpdateNewFolder(formData.project_name.value);
        } catch {}

        setSaveDisabled(false);
        onModalClose();
      } else {
        try {
          setSaveDisabled(true);
          await onCreateNewFolder(formData.project_name.value);
        } catch {}

        setSaveDisabled(false);
        onModalClose();
      }
    }
  }

  return(
    <Modal onClose={onModalClose} small>
      <DashboardBox title={<Translate name={ editingProjectName ? "updateProject" : "newProject" } />} small primary>
        <form className="form" onSubmit={submitHandler}>
          <Input
            name="project_name"
            placeholder={translate('nameOfTheProject')}
            value={formData.project_name.value}
            onChange={changeHandler}
            error={errors.project_name}
          />
          <div className="form__foot">
            <Button type="button" text={<Translate name="cancel" />} normalPrimary onButtonClick={onModalClose} />
            <Button text={<Translate name={ editingProjectName ? "saveChanges" : "createTheProject" } />} primary disabled={saveDisabled} />
          </div>
        </form>
      </DashboardBox>
    </Modal>
  );
}

CreateNewProjectModal.propTypes = {
  onModalClose: PropTypes.func.isRequired
}

export default CreateNewProjectModal;