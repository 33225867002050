import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { MdRemoveCircleOutline, MdDeleteForever, MdSubdirectoryArrowLeft } from 'react-icons/md';

import Popup from './Popup';
import CreateNewProjectModal from './CreateNewProjectModal';
import Translate from '../../i18n/Translate';
import { UserContext } from '../../context/userState';

const SelectedProjects = ({ solutions, folderStructure, onMoveToFolderId, onCancel, onRemoveSelectedItems, onRemoveLastAddedSolution }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(null);
  const { addNewFolder } = useContext(UserContext);

  const openCreateProjectModalHandler = (selectedFolder) => {
    setShowProjectModal(selectedFolder);
  }

  const createNewFolder = async (folderName, selectedFolder) => {
    await addNewFolder(folderName, selectedFolder);
  }

  return(
    <Fragment>

      <div className={solutions.length ? 'selected-projects opened' : 'selected-projects'}>
        <div className="selected-projects__inner">
          <div className="selected-projects__remove">
            <MdRemoveCircleOutline onClick={onRemoveLastAddedSolution} />
          </div>
          <div className="selected-projects__text">
            <p>{solutions.length} <Translate name="projectsSelected" /></p>
          </div>
          <div className="selected-projects__actions">
            <div className="selected-projects__actions_popup-wrap">
              <div className="selected-projects__actions_btn" onClick={() => setShowPopup(!showPopup)}><MdSubdirectoryArrowLeft /></div>
              {showPopup && <Popup solutions={ solutions } folderStructure={folderStructure} onMoveToFolderId={onMoveToFolderId} onCancel={() => setShowPopup(false)} onCreateNewProject={(selectedFolder) => openCreateProjectModalHandler(selectedFolder)} />}
            </div>
            <div className="selected-projects__actions_btn">
              <MdDeleteForever onClick={onRemoveSelectedItems} />
            </div>
          </div>
        </div>
      </div>

      {!!showProjectModal && <CreateNewProjectModal onModalClose={() => setShowProjectModal(false)} onCreateNewFolder={(newFolderName) => createNewFolder(newFolderName, showProjectModal)} />}
    </Fragment>
  );
}

SelectedProjects.propTypes = {
  solutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemoveLastAddedSolution: PropTypes.func.isRequired
}

export default SelectedProjects;