import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import ArrowButton from '../UI/ArrowButton';
import TextLink from '../UI/TextLink';
import Button from '../UI/Button';
import Translate from '../../i18n/Translate';
import ContactUsModal from '../sections/ContactUsModal';
import { cookiesAccepted } from '../../utils';

const Step = ({ onStepChange, title, stepData, step, link, linkTo, stepName, stepIndex }) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  // const data = 
  //   step === 'compartmentation' 
  //     ? [...stepData].filter(d => d.key.compartmentationType !== 'rigidfloor' || d.key.compartmentationThickness !== '≥100') 
  //     : [...stepData];

  const data = [...stepData]

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(`/step-${stepIndex}`, [], stepName);
    }
    // console.log(`/step-${stepIndex}`, [], stepName);
    // ReactGA.event({
    //   category: stepName,
    //   action: stepName
    // });
    // eslint-disable-next-line
  }, []);

  return(
    <div className="step">
      {title && <p className="step__title is-size-medium">{title}</p>}

      {data.length > 0 ?
        <div className="step__buttons">
          {data.map(data => (
            <div key={data.name}>
              {data.icon ?
                <ArrowButton  text={data.name} icon={<img src={data.icon} alt={data.name} />} onButtonClick={() => onStepChange(data.key, step)} />
                :
                <ArrowButton  text={data.name} onButtonClick={() => onStepChange(data.key, step)} />
              }
            </div>
          ))}
        </div>
        :
        <div className="step__no-solutions">
          <p><Translate name="noSolutionsToAdd" /></p>
          <Button onButtonClick={() => setShowContactUsModal(true)} text={<Translate name="contactPromatForMoreInfo" />} />
        </div>
      }


      {link && <TextLink to={linkTo} text={link} />}
      {showContactUsModal && <ContactUsModal onModalClose={() => setShowContactUsModal(false)} />}
    </div>
  );
}

Step.propTypes = {
  onStepChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  stepData: PropTypes.arrayOf(PropTypes.object),
  step: PropTypes.string.isRequired
}

export default Step;