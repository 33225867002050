import React, { createContext, useState, useEffect, useContext } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import { LangContext } from './lang/langState';
import EtexApi from '../services/EtexApi'

export const UserContext = createContext();

const UserState = (props) => {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState('root')
  const { language } = useContext(LangContext);


  useEffect(() => {
    let detachUserListener = null;
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const uid = firebaseUser.uid

        detachUserListener = firebase.firestore().collection("users").doc(uid).onSnapshot(function(userDoc) {
          setUser({ uid: uid, email: firebaseUser.email, ...userDoc.data() });
          setUserLoaded(true);
        });
      } else {
        if (detachUserListener) detachUserListener();

        setUser(null);
        setUserLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if(!user) return
    if (!userLoaded) return;
    if (!!user.consentSentAt) return;

    const sendConsent = async (email, marketing) => {
      try {
        await EtexApi.submitConsent(email, marketing, language);
      } catch {
        return;
      }
      await updateUser({ consentSentAt: Date.now() });
    }

    sendConsent(user.email, !!user.marketing);
  }, [userLoaded])

  const addNewFolder = async (folderName, parent) => {
    // console.log('creating a new folder', folderName);

    await updateUser({
      folders: firebase.firestore.FieldValue.arrayUnion({ id: uuidv4(), title: folderName, parent: parent || 'root' }),
    });
  }

  const deleteFolder = async (folder) => {
    // console.log('removing a folder', folder);

    await updateUser({
      folders: firebase.firestore.FieldValue.arrayRemove(folder),
    });
    // removeFolder
  }

  const updateFolder = async (folder, folderName) => {
    // console.log('updating folder name', folder, folderName);

    const batch = firebase.firestore().batch();
    const userDoc = firebase.firestore().collection("users").doc(user.uid);

    batch.update(userDoc, { folders: firebase.firestore.FieldValue.arrayRemove(folder) });
    batch.update(userDoc, { folders: firebase.firestore.FieldValue.arrayUnion({ ...folder, title: folderName }) });

    await batch.commit();
  }

  const addNewSystemVariant = async (systemVariantId, systemVariantTitle) => {
    // console.log('adding a new system variant', systemVariantId);
    await updateUser({
      systemVariants: firebase.firestore.FieldValue.arrayUnion({ id: uuidv4(), parent: 'root', title: systemVariantTitle, systemVariantId: systemVariantId }),
    });
  }

  const addNewMultiplePenetration = async (variants) => {
    // console.log('adding a new multiple penetration', variants);
    await updateUser({
      systemVariants: firebase.firestore.FieldValue.arrayUnion({ id: uuidv4(), parent: 'root', title: 'Multiple penetration', systemVariantId: null, variants: variants, multiplePenetration: true }),
    });
  }

  const deleteSystemVariant = async (systemVariant) => {
    // console.log('removing a system variant', systemVariant);
    await updateUser({
      systemVariants: firebase.firestore.FieldValue.arrayRemove(systemVariant),
    })
  }

  const updateUser = async (newData) => {
    // console.log('updating user', newData, user);
    await firebase.firestore().collection("users").doc(user.uid).update(newData);
  }

  const logout = async () => {
    try {
      await firebase.auth().signOut()
    } catch (error) {
      // TODO: handle sign out error on frontend
      console.log('error', error);
    }
  }

  return(
    <UserContext.Provider value={{ user, userLoaded, addNewFolder, deleteFolder, updateFolder, addNewSystemVariant, deleteSystemVariant, updateUser, logout, selectedFolder, setSelectedFolder, addNewMultiplePenetration }} >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserState;
