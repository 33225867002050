import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { MdDashboard, MdCreateNewFolder, MdHelp, MdMail } from 'react-icons/md';

import CreateNewProjectModal from './CreateNewProjectModal';
import Translate from '../../i18n/Translate';
import { UserContext } from '../../context/userState';

const SideMenu = ({ shrinked, onMenuToggle, icons, numberOfProjects }) => {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const { addNewFolder, selectedFolder } = useContext(UserContext);

  const openCreateProjectModalHandler = (e) => {
    e.preventDefault();
    setShowProjectModal(true);
  }

  const createNewFolder = async (folderName) => {
    await addNewFolder(folderName, selectedFolder);
  }

  return(
    <Fragment>
      <div className={shrinked ? "side-menu shrinked" : "side-menu"}>
        <div className="side-menu__top">
          <div className="side-menu__top_toggle" onClick={onMenuToggle}>
            <div><span></span></div>
            <div><span></span></div>
            <div><span></span></div>
          </div>
          <Link to="/dashboard">{icons[0].icon}</Link>
        </div>
        <div className="side-menu__main">
          <div className="side-menu__main_section">
            <h6><Translate name="menu" /></h6>
            <ul>
              <li>
                <NavLink to="/dashboard" exact>
                  <span className="icon"><MdDashboard /></span>
                  <span className="text"><Translate name="myProjects" /> </span>
                  { numberOfProjects > 0 && <span className="number">{ numberOfProjects }</span> }
                </NavLink>
              </li>
              <li>
                <Link to="/dashboard" onClick={openCreateProjectModalHandler}>
                  <span className="icon"><MdCreateNewFolder /></span>
                  <span className="text"><Translate name="newProject" /></span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="side-menu__main_section">
            <h6><Translate name="support" /></h6>
            <ul>
              {/* TODO: enable when help content is prepared
              <li>
                <NavLink to="/dashboard/help" className="icon-smaller">
                  <span className="icon"><MdHelp /></span>
                  <span className="text"><Translate name="needHelp" /></span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/dashboard/contact-us" className="icon-smaller">
                  <span className="icon"><MdMail /></span>
                  <span className="text"><Translate name="contactUs" /></span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="side-menu__footer">
          <p className="side-menu__footer_title"><Translate name="appTitle" /></p>
          <p className="side-menu__footer_version"><Translate name="version" /> 1.0</p>
        </div>
      </div>

      {showProjectModal && <CreateNewProjectModal onModalClose={() => setShowProjectModal(false)} onCreateNewFolder={createNewFolder} />}
    </Fragment>
  );
}

SideMenu.propTypes = {
  shrinked: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  hideMenuText: PropTypes.bool,
  icons: PropTypes.arrayOf(PropTypes.object)
}

export default SideMenu;