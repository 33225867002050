import React from 'react';
import { useHistory } from 'react-router-dom';

import icon404 from '../../assets/images/icon-404.svg';
import Button from '../UI/Button';
import Translate from '../../i18n/Translate';

const Page404 = () => {
  const history = useHistory();

  return(
    <div className="page-404">
      <div className="page-404__icon">
        <img src={icon404} alt="" />
      </div>
      <div className="page-404__text">
        <h1><Translate name="404" /></h1>
        <Button 
          text={<Translate name="backToHomepage" />}
          primary
          fullwidth
          onButtonClick={() => history.push('/')}
        />
      </div>
    </div>
  );
}

export default Page404;