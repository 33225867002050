import React, { Fragment } from 'react';

import Header from '../sections/Header';
import Footer from '../sections/Footer';
import TextLink from '../UI/TextLink';
import Translate from '../../i18n/Translate';

const PrivacyPolicy = () => {

  return(
    <Fragment>
      <Header />
      <section className="text-section">
        <div className="container">
          <div className="text-section__inner">
            {/* <section>
              <h1>Terms &amp; Conditions</h1>
              <p>This web application (desktop/App) is intended to provide information on Etex products, either by selecting adequate solutions (Promat Selector) or by recording installed solutions (Promat Reporter). </p>
              <p>Although Etex makes reasonable efforts to present information which it believes is up-to-date and accurate, Etex makes no representation or warranty as to the adequacy, accuracy, completeness or correctness of such information nor does it warrant or represent that the site/App is complete in every respect.</p>
              <p>Neither Etex, nor any company that is a member of the Etex group, shall have any liability resulting from the use of the information herein provided, the absence of any specific information on this web site, the possible interruptions or technical errors of this web site and /or the content of external web sites with which this web site may have links.</p>
              <p>The names and logos that appear in this site are protected as trademarks, trade names and / or by any other intellectual property rights.</p>
            </section> */}

            <section>
              <h2><Translate name="privacyPolicyText1" /></h2>
              <h3><Translate name="privacyPolicyText2" /></h3>
              <p><Translate name="privacyPolicyText3" /></p>
              <p><Translate name="privacyPolicyText4" /></p>
              <p><Translate name="privacyPolicyText5" /></p>
              <p><Translate name="privacyPolicyText6" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText7" /></h3>
              <p><Translate name="privacyPolicyText8" /></p>
              <p><Translate name="privacyPolicyText9" /></p>
              <ul>
                <li><Translate name="privacyPolicyText10" /></li>
                <li><Translate name="privacyPolicyText11" /></li>
                <li><Translate name="privacyPolicyText12" /></li>
                <li><Translate name="privacyPolicyText13" /></li>
                <li><Translate name="privacyPolicyText14" /></li>
                <li><Translate name="privacyPolicyText15" /></li>
                <li><Translate name="privacyPolicyText16" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText17" /></h3>
              <p><Translate name="privacyPolicyText18" /></p>
              <p><Translate name="privacyPolicyText19" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText20" /></h3>
              <p><Translate name="privacyPolicyText21" /></p>
              <p><Translate name="privacyPolicyText22" /></p>
              <ul>
                <li><Translate name="privacyPolicyText23" /></li>
                <li><Translate name="privacyPolicyText24" /></li>
                <li><Translate name="privacyPolicyText25" /></li>
                <li><Translate name="privacyPolicyText26" /></li>
                <li><Translate name="privacyPolicyText27" /></li>
                <li><Translate name="privacyPolicyText28" /></li>
                <li><Translate name="privacyPolicyText29" /></li>
                <li><Translate name="privacyPolicyText30" /></li>
                <li><Translate name="privacyPolicyText31" /></li>
                <li><Translate name="privacyPolicyText32" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText33" /></h3>
              <p><Translate name="privacyPolicyText34" /></p>
              <p><Translate name="privacyPolicyText35" /></p>
              <ul>
                <li><Translate name="privacyPolicyText36" /></li>
                <li><Translate name="privacyPolicyText37" /></li>
                <li><Translate name="privacyPolicyText38" /></li>
                <li><Translate name="privacyPolicyText39" /></li>
                <li><Translate name="privacyPolicyText40" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText41" /></h3>
              <p><Translate name="privacyPolicyText42" /></p>
              <p><Translate name="privacyPolicyText43" /></p>
              <ul>
                <li><Translate name="privacyPolicyText44" /></li>
                <li><Translate name="privacyPolicyText45" /></li>
                <li><Translate name="privacyPolicyText46" /></li>
                <li><Translate name="privacyPolicyText47" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText48" /></h3>
              <p><Translate name="privacyPolicyText49" /></p>
              <p><Translate name="privacyPolicyText50" /></p>
              <ul>
                <li><Translate name="privacyPolicyText51" /></li>
                <li><Translate name="privacyPolicyText52" /></li>
                <li><Translate name="privacyPolicyText53" /></li>
                <li><Translate name="privacyPolicyText54" /></li>
                <li><Translate name="privacyPolicyText55" /></li>
                <li><Translate name="privacyPolicyText56" /></li>
                <li><Translate name="privacyPolicyText57" /></li>
                <li><Translate name="privacyPolicyText58" /></li>
                <li><Translate name="privacyPolicyText59" /></li>
                <li><Translate name="privacyPolicyText60" /></li>
                <li><Translate name="privacyPolicyText61" /></li>
                <li><Translate name="privacyPolicyText62" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText63" /></h3>
              <p><Translate name="privacyPolicyText64" /></p>
              <p><Translate name="privacyPolicyText65" /></p>
              <ul>
                <li><Translate name="privacyPolicyText66" /></li>
                <li><Translate name="privacyPolicyText67" /></li>
                <li><Translate name="privacyPolicyText68" /></li>
                <li><Translate name="privacyPolicyText69" /></li>
                <li><Translate name="privacyPolicyText70" /></li>
                <li><Translate name="privacyPolicyText71" /></li>
                <li><Translate name="privacyPolicyText72" /></li>
              </ul>
              <p><Translate name="privacyPolicyText73" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText74" /></h3>
              <p><Translate name="privacyPolicyText75" /></p>
              <p><Translate name="privacyPolicyText76" /></p>
              <p><Translate name="privacyPolicyText77" /></p>
              <p><Translate name="privacyPolicyText78" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText79" /></h3>
              <p><Translate name="privacyPolicyText80" /></p>
              <p><Translate name="privacyPolicyText81" /></p>
              <p><Translate name="privacyPolicyText82" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText83" /></h3>
              <p><Translate name="privacyPolicyText84" /></p>
              <p><Translate name="privacyPolicyText85" /></p>
              <ul>
                <li><Translate name="privacyPolicyText86" /></li>
                <li><Translate name="privacyPolicyText87" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText88" /></h3>
              <p><Translate name="privacyPolicyText89" /></p>
              <p><Translate name="privacyPolicyText90" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText91" /></h3>
              <p><Translate name="privacyPolicyText92" /></p>
              <p><Translate name="privacyPolicyText93" /></p>
              <ul>
                <li><Translate name="privacyPolicyText94" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText95" /></h3>
              <p><Translate name="privacyPolicyText96" /></p>
              <p><Translate name="privacyPolicyText97" /></p>
              <ul>
                <li><Translate name="privacyPolicyText98" /></li>
                <li><Translate name="privacyPolicyText99" /></li>
                <li><Translate name="privacyPolicyText100" /></li>
                <li><Translate name="privacyPolicyText101" />
                  <ul>
                    <li><Translate name="privacyPolicyText102" /></li>
                    <li><Translate name="privacyPolicyText103" /></li>
                  </ul>
                </li>
                <li><Translate name="privacyPolicyText104" /></li>
                <li><Translate name="privacyPolicyText105" /></li>
                <li><Translate name="privacyPolicyText106" /></li>
                <li><Translate name="privacyPolicyText107" /></li>
                <li><Translate name="privacyPolicyText108" /></li>
                <li><Translate name="privacyPolicyText109" /></li>
                <li><Translate name="privacyPolicyText110" /></li>
              </ul>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText111" /></h3>
              <p><Translate name="privacyPolicyText112" /></p>
              <p><Translate name="privacyPolicyText113" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText114" /></h3>
              <p><Translate name="privacyPolicyText115" /></p>
              <p><Translate name="privacyPolicyText116" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText117" /></h3>
              <p><Translate name="privacyPolicyText118" /></p>
              <p><Translate name="privacyPolicyText119" /></p>
              <p><Translate name="privacyPolicyText120" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText121" /></h3>
              <p><Translate name="privacyPolicyText122" /></p>
              <address>
                <p>The Privacy Officer</p>
                <p>Etex SA/NV</p>
                <p>PassPort Building</p>
                <p>Luchthaven Brussel Nationaal, Gebouw 1K</p>
                <p>1930 Zaventem</p>
                <p>Belgium</p>
              </address>
              <p><TextLink to="mailto:info@etexgroup.com" text="info@etexgroup.com" /></p>
            </section>

            <section>
              <h3><Translate name="privacyPolicyText123" /></h3>
              <ul>
                <li><Translate name="privacyPolicyText124" /></li>
                <li><Translate name="privacyPolicyText125" /></li>
                <li><Translate name="privacyPolicyText126" /></li>
                <li><Translate name="privacyPolicyText127" /></li>
                <li><Translate name="privacyPolicyText128" /></li>
                <li><Translate name="privacyPolicyText129" /></li>
                <li><Translate name="privacyPolicyText130" /></li>
                <li><Translate name="privacyPolicyText131" /></li>
                <li><Translate name="privacyPolicyText132" /></li>
                <li><Translate name="privacyPolicyText133" /></li>
                <li><Translate name="privacyPolicyText134" /></li>
                <li><Translate name="privacyPolicyText135" /></li>
              </ul>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default PrivacyPolicy;