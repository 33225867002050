import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import PropTypes from 'prop-types';

const ArrowButton = ({ icon, text, onButtonClick, className, subtitle }) => {
  return(
    <button className={icon ? `btn-arrow btn-arrow--icon ${className}` : `btn-arrow ${className}`} onClick={onButtonClick}>
      {icon && <span className="btn-arrow__icon">{icon}</span>}
      <span className="btn-arrow__text">{text}</span>
      { subtitle && <span className="btn-arrow__subtitle">{ subtitle }</span> }
      <span className="btn-arrow__arrow"><MdKeyboardArrowRight /></span>
    </button>
  );
}

ArrowButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onButtonClick: PropTypes.func.isRequired
}

export default ArrowButton;