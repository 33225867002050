import React from 'react';
import PropTypes from 'prop-types';

const SolutionInfoIcon = ({ iconEl }) => {
  return(
    <div className="solution-info__icons_box">
      {iconEl}
    </div>
  );
}

SolutionInfoIcon.propTypes = {
  iconEl: PropTypes.node.isRequired
}

export default SolutionInfoIcon;