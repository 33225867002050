import React, { createContext, useState, useEffect } from 'react';

export const StepsContext = createContext();

const FILTER_KEY_DIAMETER = 'sysVarProductDiameter'
const FILTER_KEY_PENPOS = 'sysVarPenetrationPosition'
const FILTER_KEY_INSULATION = 'sysVarPipeInsulationType'

const initFilterState = {
  [FILTER_KEY_DIAMETER]: '',
  [FILTER_KEY_PENPOS]: '',
  [FILTER_KEY_INSULATION]: '',
}

const StepsState = (props) => {
  const [steps, setSteps] = useState([]);
  const [facets, setFacets] = useState({});
  const [products, setProducts] = useState([]);
  const [fireStoppingType, setFireStoppingType] = useState(null);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [loadedStep, setLoadedStep] = useState(0);
  const [systemVariants, setSystemVariants] = useState([]);
  const [systemVariantDetail, setSystemVariantDetail] = useState(null);
  const [singleSystemVariant, setSingleSystemVariant] = useState(null);
  const [step, setStep] = useState(0);
  const [stepLoading, setStepLoading] = useState(false);

  const [filteredSystemVariants, setFilteredSystemVariants] = useState([])
  const [filterOptions, setFilterOptions] = useState(initFilterState)
  const [filtersData, setFiltersData] = useState(initFilterState);

  const [systems, setSystems] = useState({})

  const resetFiltersData = () => setFiltersData(initFilterState)

  useEffect(() => {
    if (step <= 7) setSystemVariantDetail(null);
    if (step < 6) setProductsLoaded(false);
    if (step < 2) setFireStoppingType(null);
    // removed because of reset to loaded step 0 on country change
    // if (loadedStep > step) {
    //   setLoadedStep(step);
    // }
  }, [step])

  return (
    <StepsContext.Provider
      value={{
        steps, setSteps,
        facets, setFacets,
        products, setProducts,
        selectedProductId, setSelectedProductId,
        productsLoaded, setProductsLoaded,
        systemVariants, setSystemVariants,
        fireStoppingType, setFireStoppingType,
        systemVariantDetail, setSystemVariantDetail,
        singleSystemVariant, setSingleSystemVariant,
        step, setStep,
        loadedStep, setLoadedStep,
        stepLoading, setStepLoading,

        filteredSystemVariants, setFilteredSystemVariants,
        filterOptions, setFilterOptions,
        filtersData, setFiltersData, resetFiltersData,
        
        systems, setSystems
      }}
    >
      {props.children}
    </StepsContext.Provider>
  );
}

export default StepsState;