import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Country from '../UI/Country';
import Translate from '../../i18n/Translate';
import { cookiesAccepted, sortArrayOfObjects } from '../../utils';
import { LangContext } from '../../context/lang/langState'

const CountrySelect = ({ countries, onCountrySelect }) => {
  const { setLanguageSelected } = useContext(LangContext)
  const [regions] = useState([
    // {
    //   title: 'International',
    //   items: countries.filter(c => c.region === 'international')
    // }, 
    {
      title: <Translate name="europe" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'europe'), 'name')
    }, 
    {
      title: <Translate name="latin_america" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'latin_america'), 'name')
    },
    {
      title: <Translate name="asia_pacific" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'asia_pacific'), 'name')
    }
  ])

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(`/step-0`, [], 'Country select');
    }
  }, []);

  const handleCountrySelect = (e, lang, name) => {
    e.preventDefault()
    setLanguageSelected(true)
    onCountrySelect(lang, name)
  }

  return (
    <section className="country-select country-select--2">
      <p className="country-select__title is-size-medium"><Translate name="select_your_country" /></p>
      <div className="country-select__regions regions">
        {regions.map((region, idx) => {
          if(!region.items || region.items.length === 0) return null
          return (
            <section key={`${region.title}-${idx}`}>
              <h3>{region.title}</h3>
              <ul className={`items`}>
                {region.items.map((item, idx) => {
                  return (
                    <li key={`${item.name}-${idx}`}>
                      <a href="/#" onClick={e => handleCountrySelect(e, item.lang, item.name)}>
                        {item.name} {item.lang && <span>({item.lang.split('-')[0]})</span>}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </section>
          )
        })}
      </div>
      {/* <h5 className="country-select__heading"><Translate name="europe" /></h5>
      <div className="divider"></div>
      <div className="country-select__countries">
        <div className="country-select__countries_inner">
          {countries.map((country, i) => (
            <Country key={i} name={country.name} flag={country.flag} language={country.lang} onCountrySelect={() => onCountrySelect(country.lang, country.name)} />
          ))}
        </div>
      </div> */}
    </section>
  );
}

CountrySelect.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object)
}

export default CountrySelect;