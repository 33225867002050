import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import WebFontLoader from 'webfontloader';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/functions"

import './App.scss';

import MainLayout from './components/layouts/Main';
import AuthenticationLayout from './components/layouts/Authentication';
import Steps from './components/pages/Steps';
import SignIn from './components/pages/SignIn';
import SignUp from './components/pages/SignUp';
import SingleSolution from './components/pages/SingleSolution';
import MyProjects from './components/pages/dashboard/MyProjects';
import ContactUs from './components/pages/dashboard/ContactUs';
import CookiesSection from './components/sections/Cookies';
import Dashboard from './components/layouts/Dashboard';
import Alerts from './components/pages/dashboard/Alerts';
import MyAccount from './components/pages/dashboard/MyAccount';
import Help from './components/pages/dashboard/Help';
import ForgotPassword from './components/pages/ForgotPassword';
import PageLoader from './components/UI/PageLoader';
import Page404 from './components/pages/404';
import SolutionList from './components/sections/SolutionList';
import SolutionDetail from './components/sections/SolutionDetail';
import Intro from './components/sections/Intro';
import CookiePolicy from './components/pages/CookiePolicy';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Disclaimer from './components/pages/Disclaimer';
import MultiplePenetration from './components/pages/MultiplePenetration';
import Translate from './i18n/Translate';

import { UserContext } from './context/userState';
import { LangContext } from './context/lang/langState';
import { PROMAT_LANGUAGE, PROMAT_COUNTRY_COOKIE, GA_ID } from './constants/constants';
import { cookiesConsent, cookiesAccepted } from './utils';
import { useCookies } from 'react-cookie';
import EtexApi from './services/EtexApi'
import { LocalizedContext } from './context/localizedState';

WebFontLoader.load({
  custom: {
    families: ['Avenir Next Bold', 'Avenir Next Demi', 'Avenir Next', 'Avenir Next Medium', 'Promat-Custom'],
    // urls: ['./assets/scss/base/fonts.scss', './assets/scss/base/font-icons.scss']
  }
})

const firebaseConfig = {
  apiKey: "AIzaSyAdVlE5Gm72hx0j8_N9-rkbtv1fzG8AOGs",
  authDomain: "promat-product-selector-web.firebaseapp.com",
  databaseURL: "https://promat-product-selector-web.firebaseio.com",
  projectId: "promat-product-selector-web",
  storageBucket: "promat-product-selector-web.appspot.com",
  messagingSenderId: "415695083740",
  appId: "1:415695083740:web:522275fc608a277d13fc19",
  measurementId: "G-N6XPSHSS1Y"
};

if(firebase.apps.length === 0) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  // firebase.functions().useFunctionsEmulator('http://localhost:5001')
  // firebase.functions().useEmulator('localhost', '5000')
}


const App = () => {
  const [showCookie, setShowCookies] = useState(true);
  const [showTrackingCode, setShowTrackingCode] = useState(false);
  const location = useLocation();

  const [cookies, setCookie] = useCookies([PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE]);

  useEffect(() => {
    if(cookiesAccepted()) {
      // Google Analytics
      ReactGA.initialize(GA_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
      setShowTrackingCode(true);
    }else {
      // Remove ga cookies if previously added
      removeCookies();
      setShowTrackingCode(false);
    }
  }, [showCookie]);

  useEffect(() => {
    if(cookiesAccepted()) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  const removeCookies = () => {
    const cookiesNames = ['_ga', '_gid', '_gat', `_gat_${GA_ID}`, PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE];
    cookiesNames.forEach(name => {
      if(Cookies.get(name)) {
        Cookies.remove(name, { path: '/', domain: window.location.hostname });
        // console.log(name, '*removed*')
      }
    });
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const apiVersion = params.get('api') || 'v2';

  const { userLoaded, user } = useContext(UserContext);
  const { translate, countries, setLanguage, supportedLocales } = useContext(LangContext);
  const { fetchFieldOptions } = useContext(LocalizedContext)

  useEffect(() => {
    if(Cookies.get(PROMAT_LANGUAGE) && Cookies.get(PROMAT_COUNTRY_COOKIE)) {
      setLanguage(Cookies.get(PROMAT_LANGUAGE), Cookies.get(PROMAT_COUNTRY_COOKIE));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchFieldOptions()
  }, [])

  useEffect(() => {
    const { pathname } = window.location
    let pathLocale = pathname.replace('/', '')
    let locale
    let country
    for(let i in supportedLocales) {
      if(pathLocale.toLowerCase() === supportedLocales[i].toLowerCase()) {
        locale = supportedLocales[i]
        break
      }
    }
    if(!locale) {
      return
    }
    for(let i in countries) {
      if(countries[i].lang === locale) {
        country = countries[i].name
        break
      } 
    }
    if(!country) {
      return
    }
    if(cookiesAccepted) {
      setCookie(PROMAT_COUNTRY_COOKIE, country, { path: '/' })
      setCookie(PROMAT_LANGUAGE, locale, { path: '/' })
    }
    ReactGA.event({
      category: 'Country selection',
      action: 'Select country from url'
    });
    setLanguage(locale, country)
    window.location.replace('/')
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      {/* {isLoading && <PageLoader />} */}
      <Helmet>
        <meta name="title" content="Promat® Selector" />
        <meta name="description" content={translate('appSubtitle')} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://selector.promat.com/" />
        <meta property="og:title" content="Promat® Selector" />
        <meta property="og:description" content={translate('appSubtitle')} />
        <meta property="og:image" content="" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://selector.promat.com/" />
        <meta property="twitter:title" content="Promat® Selector" />
        <meta property="twitter:description" content={translate('appSubtitle')} />
        <meta property="twitter:image" content="" />
        {showTrackingCode && <script>
          {
            `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KLLV2RL');
            `
          }
          </script>
        }
        {showTrackingCode && <script type="text/javascript">
          {
            `
            _linkedin_partner_id = "2860636"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            `
          }
          </script>
        }
        {showTrackingCode && <script type="text/javascript">
          {
            `
            (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();
            `
          }
          </script>
        }
        {showTrackingCode && <noscript>
          {
            `
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2860636&fmt=gif" />
            `
          }
          </noscript>
        }
      </Helmet>
      <Switch>
        <Route path="/" exact>
          <MainLayout>
            <Steps apiVersion={apiVersion} />
          </MainLayout>
        </Route>
        <Route path="/signin" exact>
          { !userLoaded ? <PageLoader /> : (
            user ? <Redirect to="/dashboard" /> :
              <AuthenticationLayout>
                <SignIn />
              </AuthenticationLayout>
          )}
        </Route>
        <Route path="/signup" exact>
          { !userLoaded ? <PageLoader /> : (
            user ? <Redirect to="/dashboard" /> :
              <AuthenticationLayout>
                <SignUp />
              </AuthenticationLayout>
          )}
        </Route>
        <Route path="/forgot-password" exact>
          { !userLoaded ? <PageLoader /> : (
            user ? <Redirect to="/dashboard" /> :
              <AuthenticationLayout>
                <ForgotPassword />
              </AuthenticationLayout>
          )}
        </Route>
        <Route path="/solution/:solutionId" exact>
          <MainLayout>
            <Intro />
            <SingleSolution />
          </MainLayout>
        </Route>
        <Route path="/multiple-penetration" exact>
          <MultiplePenetration />
        </Route>
        <Route path="/cookie-policy" exact>
          <CookiePolicy />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/disclaimer" exact>
          <Disclaimer />
        </Route>
        { !userLoaded ? <PageLoader /> : (
            user ?
            <Dashboard>
              <Route path="/dashboard" exact>
                <MyProjects />
              </Route>
              <Route path="/dashboard/contact-us" exact>
                <ContactUs />
              </Route>
              <Route path="/dashboard/account" exact>
                <MyAccount />
              </Route>
              {/* <Route path="/dashboard/help" exact>
                <Help />
              </Route> */}
            </Dashboard>
            :
            <Redirect to="/" />
        )}
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>

      {!cookiesConsent() && showCookie && <CookiesSection onCookieReply={() => setShowCookies(false)} />}
    </Fragment>
  );
}

export default App;
