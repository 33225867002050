import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Input from '../UI/Input';
import PasswordChange from '../UI/PasswordChange';
import ChangePasswordModal from './ChangePasswordModal';
import Translate from '../../i18n/Translate';
import Button from '../UI/Button';
import { LangContext } from '../../context/lang/langState';
import { UserContext } from '../../context/userState';

const MyAccountForm = ({onSubmit, onCancel, onPasswordChange}) => {
  const initialData = {
    firstName: {
      value: '',
      required: true
    },
    lastName: {
      value: '',
      required: true
    },
    email: {
      value: '',
      required: true
    }
  }
  const { formData, errors, changeHandler, setErrors, setDataAndErrors } = useForm(initialData, validate);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const history = useHistory();
  const { translate } = useContext(LangContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if(!user) {
      return
    }
    let accountData = {
      firstName: { value: user.firstName, required: true },
      lastName: { value: user.lastName, required: true },
      email: { value: user.email, required: true }
    }
    setDataAndErrors(accountData)
  }, [user])

  const submitHandler = (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      onSubmit(formData)
    }
  }

  return (
    <Fragment>
      <form className="form" onSubmit={submitHandler}>
        <div className="form__group form__group--break-sm">
          <Input
            name="firstName"
            value={formData.firstName.value}
            onChange={changeHandler}
            error={errors.firstname}
            label={<Translate name="firstName" />}
            dashboard
          />
          <Input
            name="lastName"
            value={formData.lastName.value}
            onChange={changeHandler}
            error={errors.lastName}
            label={<Translate name="lastName" />}
            dashboard
          />
        </div>
        <div>
          <Input
            name="email"
            type="email"
            value={formData.email.value}
            onChange={changeHandler}
            error={errors.email}
            label={<Translate name="email" />}
            dashboard
          />
        </div>
        <PasswordChange label={<Translate name="password" />} onButtonClick={() => setShowChangePasswordModal(true)} />
        <div className="form__actions">
          <Button 
            text={<Translate name="cancel" />}
            outlineSecondary
            type="button"
            onButtonClick={onCancel}
          />
          <Button 
            text={<Translate name="saveChanges" />}
            secondary
          />
        </div>
      </form>

      {showChangePasswordModal && <ChangePasswordModal onSubmit={onPasswordChange} onModalClose={() => setShowChangePasswordModal(false)} />}
    </Fragment>
  );
}

export default MyAccountForm;