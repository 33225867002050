import React, { Fragment, useState, useContext } from 'react';

import firebase from 'firebase/app'
import 'firebase/auth'

import DashboardBox from '../../UI/DashboardBox';
import MyAccountRead from '../../sections/MyAccountRead';
import MyAccountForm from '../../sections/MyAccountForm';
import MyCompanyRead from '../../sections/MyCompanyRead';
import MyCompanyForm from '../../sections/MyCompanyForm';
import Translate from '../../../i18n/Translate';
import { UserContext } from '../../../context/userState';
import PageLoader from '../../UI/PageLoader';
import { uploadFile } from '../../../services/FirebaseStorage';

const MyAccount = () => {

  const { updateUser, user } = useContext(UserContext);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const saveAccount = async (formData) => {
    setIsLoading(true)
    await updateUser({
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      email: formData.email.value,
    })
    setIsEditingProfile(false)
    setIsLoading(false)
  }

  const saveCompany = async (formData) => {
    setIsLoading(true)
    let uploadResult
    if(formData.logo.value.data) {
      // should upload new logo
      uploadResult = await uploadFile(formData.logo.value.data, 'company_logo', formData.logo.value.format, formData.logo.value.type, firebase.auth().currentUser.uid)
    }
    await updateUser({ company: {
      name: formData.company_name.value,
      email: formData.contact_email.value,
      phone: formData.phone_number.value,
      vat_number: formData.vat_number.value,
      address_street: formData.street.value,
      address_number: formData.street_number.value,
      address_city: formData.city.value,
      address_zip: formData.zip_code.value,
      address_city: formData.city.value,
      address_country: formData.country.value,
      // type: formData.type.value,
      website: formData.website.value,
      logo: uploadResult?.url || formData.logo.value.url
    }})
    setIsEditingCompany(false)
    setIsLoading(false)
  }

  const submitPasswordChange = async (newPassword) => {
    setIsLoading(true)
    try {
      await firebase.auth().currentUser.updatePassword(newPassword)
    } catch(err) {
      alert(err.message)
    }
    setIsLoading(false)
  }

  return(
    <Fragment>
      { isLoading && <PageLoader />}
      { !isEditingProfile ? (
        <DashboardBox title={<Translate name="myAccount" />} editBtn edit editBtnOnClick={() => setIsEditingProfile(true)}>
          <MyAccountRead onPasswordChange={submitPasswordChange} />
        </DashboardBox>
      ) : ( 
        <DashboardBox title={<Translate name="myAccount" />}>
          <MyAccountForm onCancel={() => setIsEditingProfile(false)} onSubmit={saveAccount} onPasswordChange={submitPasswordChange} />
        </DashboardBox>
      )}
      { !isEditingCompany ? (
        <DashboardBox title={<Translate name="myCompany" />} logo={user.company?.logo} editBtn editBtnOnClick={() => setIsEditingCompany(true)}>
          <MyCompanyRead />
        </DashboardBox>
      ) : (
        <DashboardBox title={<Translate name="myCompany" />}>
          <MyCompanyForm onCancel={() => setIsEditingCompany(false)} onSubmit={saveCompany} />
        </DashboardBox>
      )}
    </Fragment>
  );
}

export default MyAccount;