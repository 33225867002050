import React, { useState, useContext } from 'react'
import { MdClose } from 'react-icons/md'
import { useCookies } from 'react-cookie'

import Modal from '../UI/Modal'
import Translate from '../../i18n/Translate'
import { LangContext } from '../../context/lang/langState'
import { StepsContext } from '../../context/stepsState'
import { PROMAT_LANGUAGE, PROMAT_COUNTRY_COOKIE } from '../../constants/constants'
import { cookiesAccepted, sortArrayOfObjects } from '../../utils'

const LanguageSelectorModal = ({ onClose }) => {
  const { setLanguage, countries, country, setLanguageSelected, setSelectedLanguage } = useContext(LangContext)
  const { setStep, resetFiltersData, setSteps, setLoadedStep } = useContext(StepsContext)
  const [cookies, setCookie] = useCookies([PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE])
  const [regions] = useState([
    // {
    //   title: 'International',
    //   items: countries.filter(c => c.region === 'international')
    // }, 
    {
      title: <Translate name="europe" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'europe'), 'name')
    }, 
    {
      title: <Translate name="latin_america" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'latin_america'), 'name')
    },
    {
      title: <Translate name="asia_pacific" />,
      items: sortArrayOfObjects(countries.filter(c => c.region === 'asia_pacific'), 'name')
    }
  ])

  const handleCountrySelect = (e, lang, country) => {
    e.preventDefault()
    if(cookiesAccepted()) {
      setCookie(PROMAT_COUNTRY_COOKIE, country, { path: '/' })
      setCookie(PROMAT_LANGUAGE, lang, { path: '/' })
    }
    setLanguage(lang, country)
    setLanguageSelected(true)
    setSelectedLanguage(lang)
    setSteps([{ stepKey: 'country', key: lang }])
    setStep(1) 
    setLoadedStep(0)
    resetFiltersData()
    onClose()
  }

  return (
    <Modal onClose={onClose} modalClass="modal--2" contentClassName="language-selector-modal">
      <div className="language-selector-modal__close" onClick={onClose}><MdClose /></div>
      <div className="language-selector-modal__head">
        <h2><Translate name="select_your_country" /></h2>
        {/* <p><Translate name="language_selector_info" /></p> */}
        <div className="language-selector-modal__head_selected">{country}</div>
      </div>
      <div className="language-selector-modal__regions regions">
        {regions.map((region, idx) => {
          return (
            <section key={`${region.title}-${idx}`}>
              <h3>{region.title}</h3>
              <ul className={`items`}>
                {region.items.map((item, idx) => {
                  return (
                    <li key={`${item.name}-${idx}`}>
                      <a href="/#" onClick={e => handleCountrySelect(e, item.lang, item.name)}>
                        {item.name} {item.lang && <span>({item.lang.split('-')[0]})</span>}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </section>
          )
        })}
      </div>
    </Modal>
  )
}

export default LanguageSelectorModal