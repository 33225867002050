import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from './Button';
import Translate from '../../i18n/Translate';

const DashboardBox = ({ title, children, small, primary, logo, editBtn, editBtnOnClick }) => {
  const history = useHistory();

  let boxClasses = 'dashboard-box';

  if(small) {
    boxClasses += ' dashboard-box--small';
  }

  if(primary) {
    boxClasses += ' dashboard-box--primary';
  }

  return(
    <div className={boxClasses}>
      <div className="dashboard-box__head">
        <h3>{title} {editBtn && <Button text={<Translate name="edit" />} mini onButtonClick={editBtnOnClick} />}</h3>
        {logo && <div className="dashboard-box__head_logo" style={{ 
          backgroundImage: `url(${logo})`, 
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'}} />}
      </div>
      <div className="dashboard-box__body">
        {children}
      </div>
    </div>
  );
}

DashboardBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),  
    PropTypes.element                   
  ]).isRequired,
  small: PropTypes.bool,
  primary: PropTypes.bool,
  logo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  editBtn: PropTypes.bool,
  editBtnOnClick: PropTypes.func
}

export default DashboardBox;