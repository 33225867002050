import Cookies from 'js-cookie';

const cookiesAccepted = () => {
  const cookiesAcc = Cookies.get('promat_consent');
  return cookiesAcc && JSON.parse(cookiesAcc).accepted;
}

const cookiesConsent = () => {
  const cookiesAcc = Cookies.get('promat_consent');
  return cookiesAcc;
}

const getWoolThickness = (sol, apiLanguage) => {
  let descObject = sol.name
  let descValue = descObject[apiLanguage] || descObject.en
  if(descValue.includes('1x50mm')) {
    return '1x50mm';
  } else if(descValue.includes('1x80mm')) {
    return '1x80mm';
  } else if(descValue.includes('2x50mm')) {
    return '2x50mm';
  } else if(descValue.includes('2x80mm')) {
    return '2x80mm'
  }
  return null
}

const isMultiplePenetrationEnabled = (systemVariant, apiLanguage) => {
  if(!systemVariant) {
    return false
  }
  let name = multipenetrationName(systemVariant, apiLanguage)
  return Boolean(name)
}

const mpCompatibilityError = (systemVariant, MPArray, apiLanguage) => {
  if(!systemVariant || !systemVariant.fields) {
    return 'solution_not_found'
  }

  if(MPArray.length === 0) {
    return null
  }

  return doesMatchMPFields(systemVariant, MPArray[0], apiLanguage)
}

const doesMatchMPFields = (a, b, apiLanguage) => {
  const mpNameA = multipenetrationName(a, apiLanguage)
  const mpNameB = multipenetrationName(b, apiLanguage)
  
  if(mpNameA !== mpNameB) {
    return 'solution_mismatch'
  }

  const propertiesToCheck = [
    { field: 'sysVarAperturePartThickness', propertyKey: 'value.rawValue', error: 'thickness_mismatch' },
    { field: 'sysVarCompartmentationType', propertyKey: 'value.rawValue', error: 'compartmentation_type_mismatch' },
    { field: 'sysVarCompartmentationThickness', propertyKey: 'value.rawValue', error: 'compartmentation_thickness_mismatch' },
    { field: 'sysVarFireRatingPerformanceE', propertyKey: 'value.rawValue', error: 'fire_rating_mismatch' },
    { field: 'sysVarFireRatingPerformanceEI', propertyKey: 'value.rawValue', error: 'fire_rating_mismatch' }
  ]

  for(let property of propertiesToCheck) {
    let valA = propertyWithCompositeKey(a.fields[property.field], property.propertyKey)
    let valB = propertyWithCompositeKey(b.fields[property.field], property.propertyKey)

    if(valA !== valB) {
      return property.error
    }
  }

  return null
}

const multipenetrationName = (systemVariant, apiLanguage) => {
  let name = systemVariant.name[apiLanguage] || systemVariant.name.en
  if(!name) {
    return ''
  }
  name = name.replace(/®/g, '')
  name = name.replace(/,/g, '')
  const validNames = [
    "PROMASTOP-CC",
    "PROMASTOP-I",
    "PROMASTOP-E",
    "PROMASTOP-FB",
    "PROMASTOP-FP",
    "PROMASTOP-Modulstein",
    "PROMASTOP-Modulstopfen",
    "PROMASTOP-Systemschaum",
    "PROMASTOP-M",
    "PROMASTOP-Brandschutzmörtel Typ S",
    "PROMAFOAM-2C"
  ]
  for(let n of validNames) {
    if(name.includes(n)) {
      return n
    }
  }
  if(systemVariant.sysVarName) {
    name = systemVariant.sysVarName
    name = name.replace(/®/g, '')
    name = name.replace(/,/g, '')
    for(let n of validNames) {
      if(name.includes(n)) {
        return n
      }
    }
  }
  return ''
}

// sort array of object by property(value should be string)
const sortArrayOfObjects = (arr, name) => {
  const collator = new Intl.Collator(undefined, {
    sensitivity: 'base'
  })
  return arr.sort((a, b) => {
    return collator.compare(a[name], b[name])
    // let stringA = a[name].toLowerCase()
    // let stringB = b[name].toLowerCase()
    // if (stringA < stringB) {
    //     return -1
    // }
    // if (stringA > stringB) {
    //     return 1
    // }
    // return 0
  })
}

const propertyWithCompositeKey = (object, key) => {
  let components = key.split('.')
  let source = object
  while(components.length > 1) {
    if(!source) {
      return
    }
    source = source[components[0]]
    components.splice(0, 1)
  }
  return source[components[0]]
}

export {
  cookiesAccepted,
  cookiesConsent,
  isMultiplePenetrationEnabled,
  mpCompatibilityError,
  sortArrayOfObjects,
  propertyWithCompositeKey
}
