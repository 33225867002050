import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdDescription, MdCreateNewFolder } from 'react-icons/md';

import Button from '../UI/Button';
import Translate from '../../i18n/Translate';

const NoProjects = ({ onShowNewProjectModal }) => {
  const history = useHistory();

  return(
    <div className="no-projects">
      <h2><Translate name="noProjects" /></h2>
      <div className="no-projects__btns">
        <Button
          text={<Translate name="newSolution" />}
          className="uppercase mb-2"
          iconEl={<MdDescription />}
          fullwidth
          outlineSecondary
          centered
          textSmall
          bold
          onButtonClick={() => history.push('/')}
        />
        <Button
          text={<Translate name="newProject" />}
          className="uppercase"
          iconEl={<MdCreateNewFolder />}
          fullwidth
          secondary
          centered
          textSmall
          bold
          onButtonClick={() => onShowNewProjectModal(true)}
        />
      </div>
    </div>
  );
}

NoProjects.propTypes = {
  onShowNewProjectModal: PropTypes.func
}

export default NoProjects;