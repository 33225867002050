import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { MdMoreHoriz, MdModeEdit, MdDeleteForever } from 'react-icons/md';

import projectImage from '../../assets/images/project.png';
import projectFolderImage from '../../assets/images/project_folder.png';

import Checkbox from '../UI/Checkbox';
import Translate from '../../i18n/Translate';

const ProjectList = ({ title, projectId, solutions, onCheckboxChange, selected, folder, onClickFolder, onClickSolution, onRemoveFolder, onRenameFolder, onRemoveSolution, multiplePenetration, onClickMP = () => {} }) => {
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const onClickLine = () => multiplePenetration ? onClickMP() : folder ? onClickFolder(projectId) : onClickSolution(projectId);

  return(
    <div className={selected ? "project-box project-list active" : "project-box project-list"}>
      <div className="project-list__inner">
        <div className="checkbox-wrapper">
          <Checkbox onChange={onCheckboxChange} checked={selected} primary />
        </div>
        <div className="project-list__icon" onClick={onClickLine}>
          {multiplePenetration ? 
            <div className="project-list__icon_mp">
              <img src={projectImage} alt="" />
              <img src={projectImage} alt="" />
              <img src={projectImage} alt="" />
            </div>
            :
            folder ? <img src={projectFolderImage} alt="" /> : <img src={projectImage} alt="" />
          }
        </div>
        <div className="project-list__title" onClick={onClickLine}>
          <h4>{multiplePenetration ? <Translate name="multiplePenetration" /> : title}</h4>
        </div>
        <div className="project-list__solutions" onClick={onClickLine}>
          {solutions > 1 && <p><span>{solutions}</span> <Translate name="solutionsInTheProject" /></p>}
        </div>
      </div>
      <div className="project-list__more">
        <div className="project-list__more_btn" onClick={() => setShowMoreDropdown(!showMoreDropdown)}><MdMoreHoriz /></div>
        {showMoreDropdown && <ul className="project-list__more_dropdown">
          { folder && <li onClick={() => onRenameFolder(projectId)} ><MdModeEdit /> <Translate name="rename" /></li> }
          <li onClick={() => folder ? onRemoveFolder(projectId) : onRemoveSolution(projectId)}><MdDeleteForever /> <Translate name="delete" /></li>
        </ul>}
      </div>
    </div>
  );
}

ProjectList.propTypes = {
  title: PropTypes.string.isRequired,
  solutions: PropTypes.number.isRequired,
  onCheckboxChange: PropTypes.func,
  selected: PropTypes.bool
};

export default ProjectList;