const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default (fields, submit = false, translate) => {
  let errors = {};

  for(let field in fields) {
    if(submit) {
      fields[field].touched = true;
    }

    const currentField = fields[field];
    
    if(currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : translate('requiredField');
    }

    if(currentField.file && currentField.required && Object.keys(currentField.value).length === 0 && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : translate('requiredField');
    }

    if(!errors[field] && currentField.email && submit && !validateEmail(currentField.value)) {
      errors[field] = currentField.emailMessage ? currentField.emailMessage : translate('invalidEmail');
    }

    if(!errors[field] && currentField.matchWith && currentField.value !== fields[currentField.matchWith].value && currentField.touched) {
      errors[field] = currentField.matchWithMessage ? currentField.matchWithMessage : translate('fieldValuesNotEqual');
    }

    if(!errors[field] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[field] = currentField.minLengthMessage ? currentField.minLengthMessage : `${translate('mustHaveAtLeast')} ${currentField.minLength} ${translate('characters')}`;
    }

    if(!errors[field] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[field] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `${translate('mustHaveLessThan')} ${currentField.maxLength} ${translate('characters')}`;
    }

    if(!errors[field] && currentField.file && currentField.touched && !!currentField.value.data && currentField.allowedTypes && !currentField.allowedTypes.includes(currentField.value.type)) {
      errors[field] = currentField.allowedTypesMessage ? currentField.allowedTypesMessage : translate('invalidFileType');
    }

    if(!errors[field] && currentField.file && currentField.touched && !!currentField.value.data && currentField.maxFileSize && (currentField.maxFileSize * 1024) < Math.round(currentField.value.size)) {
      errors[field] = currentField.maxFileSizeMessage ? currentField.maxFileSizeMessage : `${translate('fileTooLarge')}(${Math.round(currentField.value.size / 1024)}KB), ${translate('itCannotBeLargerThan')} ${currentField.maxFileSize}KB`;
    }

    if( currentField.password && currentField.value.length < 6 && currentField.touched ){
      errors[field] = translate('weakPassword')
    }
  }

  return errors;
}