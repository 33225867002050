import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ label, id, name, placeholder, value, onChange, error, dashboard, dashboardLight }) => {
  let textareaClasses = 'form-textarea';

  if(dashboard) {
    textareaClasses += ' form-textarea--dashboard';
  }

  if(dashboardLight) {
    textareaClasses += ' form-textarea--dashboard-light';
  }

  return(
    <div className={textareaClasses}>
      {label && <label htmlFor={id ? id : name} className="form-textarea__label">{label}</label>}
      <textarea
        className={error ? "form-textarea__field error" : "form-textarea__field"}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id ? id : name}
      >
      </textarea>
      {error && <div className="form-textarea__error">{error}</div>}
    </div>
  );
}

Textarea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  dashboard: PropTypes.bool,
  dashboardLight: PropTypes.bool
}

export default Textarea;