import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import CountrySelect from '../steps/CountrySelect';
// import austria from '../../assets/images/flags/austria.svg';
// import belgium from '../../assets/images/flags/belgium.svg';
// import germany from '../../assets/images/flags/germany.svg';
// import netherland from '../../assets/images/flags/netherland.svg';
// import poland from '../../assets/images/flags/poland.svg';
// import spain from '../../assets/images/flags/spain.svg';
// import onsiteIcon from '../../assets/images/onsite.svg';
// import productsIcon from '../../assets/images/products.svg';

import Step from '../steps/Step';
// import StepSeven from '../steps/StepSeven';
// import StepNine from '../steps/StepNine';
import Progress from '../sections/Progress';
import ProductList from '../sections/ProductList';
import SolutionList from '../sections/SolutionList';
import SolutionDetail from '../sections/SolutionDetail';
// import imagesLoaded from '../../helpers/imagesLoaded';
// import PageLoader from '../UI/PageLoader';
import Intro from '../sections/Intro';
import Loader from '../UI/Loader';
import { LangContext } from '../../context/lang/langState';
import { MPContext } from '../../context/multiplePenetrationState';

import EtexApi, {
  // FIRE_STOPPING_TYPE,
//   COMPARTMENTATION_TYPE,
//   COMPARTMENTATION_THICKNESS,
//   FIRE_RESISTANCE,
//   PENETRATION_PRODUCT_TYPE,
//   PENETRATION_MATERIAL,
} from '../../services/EtexApi';
import { StepsContext } from '../../context/stepsState';
import { PROMAT_LANGUAGE, PROMAT_COUNTRY_COOKIE } from '../../constants/constants';
import { cookiesAccepted } from '../../utils';
import { LocalizedContext } from '../../context/localizedState';

// const assignFireRatingIcons = FIRE_RESISTANCE.map((item, index) => [item, require(`../../assets/images/Flame_level_${index + 1}.svg`)])
// const objectFireRatingIcon = Object.fromEntries(new Map(assignFireRatingIcons))

const Steps = ({ apiVersion }) => {

  const { countries, setLanguage, translate, language, apiLanguage } = useContext(LangContext);
  const { getLocalizedValue, fireStoppingTypeCopy, penetrationMaterialCopy, penetrationProductTypeCopy, compartmentationTypeCopy } = useContext(LocalizedContext)
  const [showLoader, setShowLoader] = useState(true);

  const {
    steps, setSteps,
    facets, setFacets,
    productsLoaded, setProductsLoaded,
    setSelectedProductId,
    fireStoppingType, setFireStoppingType,
    systemVariants, setSystemVariants,
    setSystemVariantDetail,
    loadedStep, setLoadedStep,
    step, setStep,
    setStepLoading, stepLoading,
    resetFiltersData,
    systems, setSystems
  } = useContext(StepsContext);
  const { setMPMode, mpMode } = useContext(MPContext);
  const history = useHistory();

  const stepNames = () => {
    if (fireStoppingType === 'blankseal') return [
      translate('penetrationType'),
      translate('constructionType'),
      translate('fireRating'),
      translate('productsOverview'),
      translate('solutionsOverview'),
      translate('solutionsFounded'),
    ];

    return [
      translate('penetrationType'),
      translate('constructionType'),
      translate('fireRating'),
      translate('typeOfService'),
      translate('serviceDetail'),
      translate('productsOverview'),
      translate('solutionsOverview'),
      translate('solutionsFounded'),
    ];
  }

  const stepNamesEN = () => {
    if(fireStoppingType === 'blankseal') return[
      'Penetration type',
      'Construction type',
      'Fire rating',
      'Product overview',
      'Solutions overview',
      'Solutions found'
    ];

    return [
      'Penetration type',
      'Construction type',
      'Fire rating',
      'Type of service',
      'Service details',
      'Product overview',
      'Solutions overview',
      'Solutions found'
    ];
  }

  const stepData = {
    two: {
      stepKey: 'fireStoppingType',
      title: translate('selectPenetrationType'),
    },
    three: {
      stepKey: 'compartmentation',
      title: translate('selectConstructionType'),
    },
    four: {
      stepKey: 'fireResistance',
      title: translate('selectFireRating'),
    },
    five: {
      stepKey: 'penetrationProductType',
      title: translate('selectTypeOfService'),
    },
    six: {
      stepKey: 'penetrationMaterial',
      title: translate('selectServiceDetails'),
    }
  }

  const sortOptions = (options, key, number = false) => {
    options.sort((a,b) => {
      let valA = number ? parseFloat(a[key]) : a[key]
      let valB = number ? parseFloat(b[key]) : b[key]
      if(valA < valB) return -1
      if(valA > valB) return 1
      return 0
    })
  }

  const stepTwoData = (facets) => {
    const options = []
    if(!facets.fireStoppingType) {
      return options
    }
    for(let key of facets.fireStoppingType) {
      options.push({
        name: getLocalizedValue(fireStoppingTypeCopy, key, apiLanguage), //translate(`FIRE_STOPPING_TYPE.${key}`),
        key
      })
    }
    sortOptions(options, 'name')
    return options
  }

  const stepThreeData = (facets) => {
    const options = [];
    if(!facets.compartmentation) {
      return options
    }
    for(const opt of facets.compartmentation) {
      options.push({
        name: `${getLocalizedValue(compartmentationTypeCopy, opt.compartmentationType, apiLanguage)} ${opt.compartmentationThickness}`, //`${translate(`COMPARTMENTATION_TYPE.${opt.compartmentationType}`)} ${opt.compartmentationThickness}`,
        key: {
          compartmentationThickness: opt.compartmentationThickness,
          compartmentationType: opt.compartmentationType,
        },
      })
    }
    sortOptions(options, 'name')

    return options;
  };

  const stepFourData = (facets) => {
    const options = []
    if(!facets.fireResistance) {
      return options
    }
    const translateSuffixMinutes = translate('minutes')
    for(let fireResistance of facets.fireResistance) {
      options.push({
        name: `${fireResistance.toString()} ${translateSuffixMinutes}`,
        key: fireResistance
      })
    }
    sortOptions(options, 'key', true)
    const maxLevel = 6
    for(let i = 0; i < options.length; i++) {
      options[i].icon = require(`../../assets/images/Flame_level_${(i > maxLevel ? maxLevel : i) + 1}.svg`).default
    }
    return options
    // return stepFourOptions.filter(fireResistance => !facets.fireResistance || facets.fireResistance?.includes(fireResistance)).map(fireResistance => ({
    //   name: `${fireResistance.toString()} ${translateSuffixMinutes}`,
    //   icon: objectFireRatingIcon[fireResistance.toString()].default ? objectFireRatingIcon[fireResistance.toString()].default : objectFireRatingIcon[fireResistance.toString()],
    //   key: fireResistance,
    // }));
  }

  const stepFiveData = (facets) => {
    const options = []
    if(!facets.penetrationProductType) {
      return options
    }
    for(let penetrationProductType of facets.penetrationProductType) {
      options.push({
        name: getLocalizedValue(penetrationProductTypeCopy, penetrationProductType, apiLanguage), //translate(`PENETRATION_PRODUCT_TYPE.${penetrationProductType}`),
        key: penetrationProductType
      })
    }
    sortOptions(options, 'name')
    // const fac = {...facets, penetrationProductType: [...facets.penetrationProductType, 'multilayer', 'aluminiumcomposite']}
    // return stepFiveOptions.filter(penetrationProductType => !facets.penetrationProductType || facets.penetrationProductType?.includes(penetrationProductType)).map(penetrationProductType => ({
    //   name: penetrationProductType === 'NONE' ? 'None' : translate(`PENETRATION_PRODUCT_TYPE.${penetrationProductType}`),
    //   key: penetrationProductType,
    // }));
    return options
  }

  const stepSixData = (facets) => {
    const options = []
    if(!facets.penetrationMaterial) {
      return options
    }
    for(let key of facets.penetrationMaterial) {
      options.push({
        name: getLocalizedValue(penetrationMaterialCopy, key, apiLanguage), //translate(`PENETRATION_MATERIAL.${key}`),
        key
      })
    }
    sortOptions(options, 'name')
    // return stepSixOptions.filter(penetrationMaterial => !facets.penetrationProductType || facets.penetrationMaterial?.includes(penetrationMaterial)).map(penetrationMaterial => ({
    //   name: penetrationMaterial === 'NONE' ? 'None' : translate(`PENETRATION_MATERIAL.${penetrationMaterial}`),
    //   key: penetrationMaterial,
    // }))
    return options
  }

  const productOverviewData = (facets) => {
    return facets.name
  }

  const [cookies, setCookie] = useCookies([PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE]);

  // const imageLoadHandler = (info) => {
  //   const countriesClone = [...countries];
  //   const infoObj = countries.filter(obj => obj.name === info.name)[0];
  //   infoObj.loading = false;
  //   setCountries(countriesClone);
  // }

  useEffect(() => {
    if(!cookies[PROMAT_LANGUAGE]) return
    let country
    for(let c of countries) {
      if(c.lang === language) {
        country = c.name
        break
      }
    }
    if(country) {
      selectCountryHandler(language, country)
    }
  }, [language])

  const selectCountryHandler = (lang, country) => {
    if(cookiesAccepted()) {
      setCookie(PROMAT_COUNTRY_COOKIE, country, { path: '/' })
      setCookie(PROMAT_LANGUAGE, lang, { path: '/' })
      ReactGA.event({
        category: 'Country selection',
        action: 'Select country'
      });
      // Linkedin conversion
      const img = document.createElement('img');
      img.src = 'https://px.ads.linkedin.com/collect/?pid=2860636&conversionId=3894337&fmt=gif';
      img.style.display = 'none';
      img.height = 1;
      img.width = 1;
      img.alt = '';
      document.body.prepend(img);
    }
    setLanguage(lang, country);
    // reset to start whenever locale is changed
    setLoadedStep(0)
    setSteps([{ stepKey: 'country', key: lang }]);
  }

  useEffect(() => {
    const cookieLang = cookies[PROMAT_LANGUAGE]
    if (cookieLang) {
      const existingCountry = countries.find((country) => country.lang === cookieLang)
      if (existingCountry) {
        selectCountryHandler(existingCountry.lang, existingCountry.name);
      }
    }
  }, [])


  const stepChangeHandler = async (key, stepKey, solution = null) => {
    const stepIndex = steps.findIndex((step) => step.stepKey === stepKey);

    if(solution) {
      history.push({ pathname: '/', state: { solution }});
    }

    if (stepIndex !== -1) {
      setSteps(steps.slice(0, stepIndex).concat({ key: key, stepKey: stepKey }));
    } else {
      setSteps(steps.concat({ key: key, stepKey: stepKey }));
    }

    if (step > 0) {
      setShowLoader(true);
      setStepLoading(true);
    }
  }

  const numericStepChangeHandler = (stepIndex) => {
    if (stepIndex <= 7) setSystemVariantDetail(null);
    if (stepIndex < 6) setProductsLoaded(false);
    if (stepIndex < 2) setFireStoppingType(null);

    // We're skipping two steps for blanseal...
    const isBlankseal = steps.find((step) => step.stepKey === 'fireStoppingType')?.key === 'blankseal';
    const sliceStepIndex = (isBlankseal && stepIndex > 5) ? stepIndex - 2 : stepIndex;

    setLoadedStep(sliceStepIndex);
    setSteps(steps.slice(0, sliceStepIndex));
    setStep(stepIndex);


    if (stepIndex < 7) resetFiltersData()
  }

  useEffect(() => {
    if(step === 0 && !cookies[PROMAT_LANGUAGE]) {
      setShowLoader(false)
    }
  }, [step, cookies])

  useEffect(() => {
    if (steps.length === 0) {
      setStep(0);
      return;
    }

    // decide what to do based on the step information

    // 1. either move forward without an API call
    // 1. or use a facet search to search for new products
    // 2. or list the number of products
    
    const lastStepKey = steps[steps.length - 1].stepKey;
    if (lastStepKey === 'country') {
      fetchFacets(1)
      // setStep(1);
    } else if (lastStepKey === 'fireStoppingType') {
      setFireStoppingType(steps[steps.length - 1].key);
      // fetchFacets(2);
      fetchCompartmentationFacets(2)
    } else if (lastStepKey === 'compartmentation') {
      fetchFacets(3);
    } else if (lastStepKey === 'fireResistance') {
      if (fireStoppingType === 'blankseal') {
        fetchProducts();
      } else {
        fetchFacets(4);
      }
    } else if (lastStepKey === 'penetrationProductType') {
      fetchFacets(5);
    } else if (lastStepKey === 'penetrationMaterial') {
      fetchProducts();
    } else if (lastStepKey === 'product') {
      setSelectedProductId(steps[steps.length - 1].key);
      fetchSolutions(steps[steps.length - 1].key)

      // setShowLoader(false);
      // setStep(7);
      // setStepLoading(false);
    } else if (lastStepKey === 'solution') {
      fetchSolutionDetail(systemVariants.find((systemVariant) => systemVariant.id == steps[steps.length - 1].key));
    }

    // eslint-disable-next-line
  }, [steps, loadedStep]);

  const fetchCompartmentationFacets = async (nextStep) => {
    setShowLoader(true);
    setStepLoading(true);
    let compartmentationFacets = []
    if (apiVersion === 'v2' && loadedStep < nextStep) {
      
      const compartmentationTypesData = await EtexApi.getFacets(steps, apiLanguage, countryCode(), ['sysVarCompartmentationType']);
      if(!compartmentationTypesData.facets || !compartmentationTypesData.facets.compartmentationType) {
        setFacets(Object.assign({}, facets, { compartmentation: compartmentationFacets }))
        setShowLoader(false);
        setStepLoading(false);
        setStep(nextStep);
        return    
      }
      for(const compType of compartmentationTypesData.facets.compartmentationType) {
        let fakeSteps = [...steps]
        fakeSteps.push({ key: compType, stepKey: 'compartmentationType' })
        const compartmentationThicknessData = await EtexApi.getFacets(fakeSteps, apiLanguage, countryCode(), ['sysVarCompartmentationThickness'])

        // compartmentationThicknessData.facets.compartmentationThickness.sort()
        for(const compThickness of compartmentationThicknessData.facets.compartmentationThickness) {
          compartmentationFacets.push({
            compartmentationType: compType,
            compartmentationThickness: compThickness
          })
        }
      }

      setFacets(Object.assign({}, facets, { compartmentation: compartmentationFacets }))
    }

    setShowLoader(false);
    setStepLoading(false);
    setStep(nextStep);
  }

  const fetchFacets = async (nextStep) => {

    setShowLoader(true);
    setStepLoading(true);
    if (apiVersion === 'v2' && loadedStep < nextStep) {
      
      const newData = await EtexApi.getFacets(steps, apiLanguage, countryCode());

      if (newData?.count === 0) {
        setShowLoader(false);
        setStep(1999);
        setStepLoading(false);
        return;
      }

      setFacets(Object.assign({}, facets, newData.facets));
      setLoadedStep(nextStep);
    }

    setShowLoader(false);
    setStepLoading(false);
    setStep(nextStep);
  }

  const countryCode = () => {
    let cc = ''
    for(let i in steps) {
      if(steps[i].stepKey === 'country') {
        let key = steps[i].key
        if(!key) {
          break
        }
        let components = key.split('-')
        if(!components || components.length < 2) {
          break
        } 
        cc = components[1]
        continue
      }
    }
    return cc
  }

  const fetchProducts = async () => {
    if (!productsLoaded) {
      // const productsAndVariantsData = await EtexApi.getSystemVariants(steps);
      // const productsAndVariantsData = await EtexApi.getSystems(steps, apiLanguage, countryCode());
      
      const newData = await EtexApi.getSystems(steps, apiLanguage, countryCode());

      if (newData?.count === 0) {
        setShowLoader(false);
        setStep(1999);
        setStepLoading(false);
        return;
      }

      setSystems(Object.assign({}, systems, newData.systems))
      setFacets(Object.assign({}, facets, newData.facets));
      setProductsLoaded(true);
    }

    setShowLoader(false);
    setStep(6);
    setStepLoading(false);
  }

  const fetchSolutions = async (productId) => {
    setShowLoader(true)
    const variantsData = await EtexApi.getSystemVariants(steps, apiLanguage, countryCode(), productId)
    setSystemVariants(variantsData.variants)
    setStep(7)
    setShowLoader(false)
    setStepLoading(false)
  }

  const fetchSolutionDetail = async (systemVariant) => {
    setSystemVariantDetail(systemVariant);
    setShowLoader(false);
    setStep(8);
    setStepLoading(false);
  }

  useEffect(() => {
    if(step !== 7 && mpMode) {
      setMPMode(false);
    }
    // eslint-disable-next-line
  }, [step, setMPMode]);

  return (
    <Fragment>
      <Intro
        showButtons={step > 0}
        step={step}
        onResetSelector={() => numericStepChangeHandler(1)}
      />
      <div className={stepLoading ? "steps-wrapper loading" : "steps-wrapper"}>
        {showLoader && <div className="loading"></div>}
        {step === 0 
          ? showLoader 
            ? <div className="loader-wrapper"><Loader primary normal small /></div>
            : <CountrySelect countries={countries} onCountrySelect={selectCountryHandler} /> 
          : null
        }
        {step > 0 && step !== 8 && <Progress step={Math.min(step, steps.length)} onNumericStepChange={(num) => { fireStoppingType === 'blankseal' && num > 3 ? numericStepChangeHandler(num + 2) : numericStepChangeHandler(num) }} stepNames={stepNames()} />}
        {/* {step === 1 && <Step onStepChange={stepChangeHandler} title={translate('chosePreferredSelectionProcess')} stepData={stepData.one.options} step={stepData.one.stepKey} />} */}
        {step === 1 && <Step onStepChange={stepChangeHandler} title={stepData.two.title} stepData={stepTwoData(facets)} step={stepData.two.stepKey} stepName={stepNamesEN()[0]} stepIndex={step} />}
        {step === 2 && <Step onStepChange={stepChangeHandler} title={stepData.three.title} stepData={stepThreeData(facets)} step={stepData.three.stepKey} stepName={stepNamesEN()[1]} stepIndex={step} />}
        {step === 3 && <Step onStepChange={stepChangeHandler} title={stepData.four.title} stepData={stepFourData(facets)} step={stepData.four.stepKey} stepName={stepNamesEN()[2]} stepIndex={step} />}
        {step === 4 && <Step onStepChange={stepChangeHandler} title={stepData.five.title} stepData={stepFiveData(facets)} step={stepData.five.stepKey} stepName={stepNamesEN()[3]} stepIndex={step} />}
        {step === 5 && <Step onStepChange={stepChangeHandler} title={stepData.six.title} stepData={stepSixData(facets)} step={stepData.six.stepKey} stepName={stepNamesEN()[4]} stepIndex={step} />}
        {step === 6 && <ProductList onStepChange={stepChangeHandler} stepName="Product overview" stepIndex={step} stepData={productOverviewData(facets)} />}
        {step === 7 && <SolutionList fireStoppingType={fireStoppingType} onStepChange={stepChangeHandler} stepName="Solutions overview" stepIndex={step} />}
        {step === 8 && <SolutionDetail onBackToSolutionList={() => numericStepChangeHandler(7)} onBackToStep={(num) => { fireStoppingType === 'blankseal' && num > 3 && num < 6 ? numericStepChangeHandler(6) : numericStepChangeHandler(num) }} stepName="Solution detail" stepIndex={step} />}
        {step === 1999 && <Step onStepChange={stepChangeHandler} stepData={[]} step={'not-found'} stepName="Not found" stepIndex={step} />}
      </div>
    </Fragment>
  );
}

export default Steps;