import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import LangState from './context/lang/langState';
import StepsState from './context/stepsState';
import UserState from './context/userState';
import MPState from './context/multiplePenetrationState';

import AppVersion from './AppVersion.js'

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import LocalizedState from './context/localizedState';

Bugsnag.start({
  apiKey: 'f68ecb9fb007ff052272af50ed3de7de',
  appVersion: AppVersion || '0.0.1',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production', 'staging' ],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <StepsState>
        <LangState>
          <UserState>
            <LocalizedState>
              <MPState>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </MPState>
            </LocalizedState>
          </UserState>
        </LangState>
      </StepsState>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);