import React, { useState, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MdDashboard, MdCreateNewFolder, MdHelp, MdMail } from 'react-icons/md';

import CreateNewProjectModal from './CreateNewProjectModal';
import Translate from '../../i18n/Translate';
import { UserContext } from '../../context/userState';

const DashboardMobileMenu = () => {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const { addNewFolder } = useContext(UserContext);

  const openCreateProjectModalHandler = (e) => {
    e.preventDefault();
    setShowProjectModal(true);
  }

  const createNewFolder = async (folderName) => {
    await addNewFolder(folderName, 'root');
  }

  return(
    <Fragment>
      <div className="dashboard-mobile-menu">
        <Link to="/dashboard" className="dashboard-mobile-menu__link">
          <span className="dashboard-mobile-menu__link_icon"><MdDashboard /></span>
          <span className="dashboard-mobile-menu__link_text"><Translate name="myProjects" /></span>
        </Link>
        <Link to="/dashboard" className="dashboard-mobile-menu__link" onClick={openCreateProjectModalHandler}>
          <span className="dashboard-mobile-menu__link_icon"><MdCreateNewFolder /></span>
          <span className="dashboard-mobile-menu__link_text"><Translate name="newProject" /></span>
        </Link>
        {/* <Link to="/dashboard/help" className="dashboard-mobile-menu__link">
          <span className="dashboard-mobile-menu__link_icon dashboard-mobile-menu__link_icon--small"><MdHelp /></span>
          <span className="dashboard-mobile-menu__link_text"><Translate name="help" /></span>
        </Link> */}
        <Link to="/dashboard/contact-us" className="dashboard-mobile-menu__link">
          <span className="dashboard-mobile-menu__link_icon dashboard-mobile-menu__link_icon--small"><MdMail /></span>
          <span className="dashboard-mobile-menu__link_text"><Translate name="contactUs" /></span>
        </Link>
      </div>

      {showProjectModal && <CreateNewProjectModal onModalClose={() => setShowProjectModal(false)} onCreateNewFolder={createNewFolder} />}
    </Fragment>
  );
}

export default DashboardMobileMenu;