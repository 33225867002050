import React, { Fragment, useState, useContext } from 'react';

import PasswordChange from '../UI/PasswordChange';
import ChangePasswordModal from './ChangePasswordModal';
import AccountReadGroup from '../UI/AccountReadGroup';
import AccountReadField from '../UI/AccountReadField';
import Translate from '../../i18n/Translate';
import { UserContext } from '../../context/userState';

const MyAccountRead = ({ onPasswordChange }) => {
  const { user } = useContext(UserContext);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  return(
    <Fragment>
      <div className="account-read">
        <AccountReadGroup>
          <AccountReadField label={<Translate name="name" />} value={ `${user.firstName} ${user.lastName}` } />
          <AccountReadField label={<Translate name="email" />} value={ user.email } />
        </AccountReadGroup>
        <PasswordChange label={<Translate name="password" />} onButtonClick={() => setShowChangePasswordModal(true)} />
      </div>

      {showChangePasswordModal && <ChangePasswordModal onSubmit={onPasswordChange} onModalClose={() => setShowChangePasswordModal(false)} />}
    </Fragment>
  );
}

export default MyAccountRead;