import React from 'react';
import PropTypes from 'prop-types';

const AccountReadGroup = ({ children }) => {
  return(
    <div className="account-read__group">
      {children}
    </div>
  );
}

AccountReadGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),  
    PropTypes.element   
  ]).isRequired
}

export default AccountReadGroup;