import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { MdLanguage, MdAccountCircle, MdKeyboardArrowDown, MdExpandMore, MdDashboard, MdLockOpen } from 'react-icons/md';

import logo from '../../assets/images/logo.svg';
import { LangContext } from '../../context/lang/langState';
import { StepsContext } from '../../context/stepsState';
import { UserContext } from '../../context/userState';
import { MPContext } from '../../context/multiplePenetrationState';
// import { PROMAT_LANGUAGE, PROMAT_COUNTRY_COOKIE } from '../../constants/constants';

// import Country from '../UI/Country';
import DashboardLoader from '../UI/DashboardLoader';
import LanguageSelectorModal from './LanguageSelectorModal';
import Translate from '../../i18n/Translate';
import Button from '../UI/Button';
// import { cookiesAccepted } from '../../utils';

const Header = () => {
  // const [cookies, setCookie] = useCookies([PROMAT_COUNTRY_COOKIE, PROMAT_LANGUAGE]);
  const { setLanguage, countries, country, languageSelected, setLanguageSelected } = useContext(LangContext);
  const { setStep, stepLoading, resetFiltersData, step, setLoadedStep, setSteps, steps, setSystemVariantDetail } = useContext(StepsContext);
  const { user, logout } = useContext(UserContext);
  const { mpSolutions, setMPMode } = useContext(MPContext);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  // const [showDropdown, setShowDropdown] = useState(false);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] = useState(false)
  // const dropdownLiEl = useRef();
  const userDropdownEl = useRef();
  const history = useHistory();
  const location = useLocation();

  // Show language selection modal if lang not saved in a cookie
  // useEffect(() => {
  //   if(!cookiesAccepted() || !cookies[PROMAT_LANGUAGE]) {
  //     setShowLanguageSelectorModal(true)
  //   }
  // }, [])

  // const countrySelectHandler = (lang, country) => {
  //   if(cookiesAccepted()) {
  //     setCookie(PROMAT_COUNTRY_COOKIE, country, { path: '/' })
  //     setCookie(PROMAT_LANGUAGE, lang, { path: '/' })
  //   }
  //   setLanguage(lang, country);
  //   setSteps([{ stepKey: 'country', key: lang }]);
  //   setStep(1) // move to first step because there should be different content for different countries
  //   setShowDropdown(false);
  //   resetFiltersData()
  // }

  // const toggleDropdownHandler = (e) => {
  //   e.preventDefault();
  //   setShowDropdown(prevVal => !prevVal);
  // }

  // const handleClickOutside = useCallback((e) => {
  //   if(showDropdown && e.target.closest('.dropdown-li') !== dropdownLiEl.current) {
  //     setShowDropdown(false);
  //   }
  //   if(showUserDropdown && e.target.closest('.user_dropdown') !== userDropdownEl.current) {
  //     setShowUserDropdown(false);
  //   }
  // }, [showDropdown, showUserDropdown]);

  const logoutHandler = async (e) => {
    e.preventDefault();

    await logout();
  }

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   }
  // }, [handleClickOutside]);

  const logoClickHandler = (e) => {
    e.preventDefault();
    setSteps(steps => [...steps].slice(0,1));
    history.push('/');
  }

  // Render language selector trigger
  const renderLangSelectorTrigger = () => {
    const selectedCountry = countries.find(c => c.name === country)
    if(!selectedCountry || step === 0) return null 
    return (
      <div className="language-selector-trigger__selected" onClick={() => setShowLanguageSelectorModal(true)}>
        {selectedCountry.flag}
        {/* <span>{selectedCountry.lang}</span> */}
      </div>
    )
  }

  // Handle language selector modal close
  const handleLanguageSelectorModalClose = () => {
    setShowLanguageSelectorModal(false)
    // if(!languageSelected) {
    //   setLanguageSelected(true)
    // }
  } 

  return(
    <header className="header">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="header__brand">
          <Link to="/" onClick={logoClickHandler}>
            <img src={logo} alt="Promat"/>
          </Link>
        </div>
        <nav className="header__nav">
          <ul className="header__nav_list d-flex">
            {(step === 7 || step === 8) && location.pathname === '/' && <li className="multiple-penetration">
              <Button 
                iconEl={<i className="custom-icon-dynamic-feed"></i>} 
                text={<Translate name="multiplePenetration" />} 
                disabled={mpSolutions.length === 0}
                onButtonClick={() => {
                  setSystemVariantDetail(null);
                  setMPMode(true);
                  const isBlankseal = steps.find((step) => step.stepKey === 'fireStoppingType')?.key === 'blankseal';
                  const sliceStepIndex = isBlankseal ? 5 : 7;
                  setStep(7);
                  setLoadedStep(sliceStepIndex);
                  setSteps(steps.slice(0, sliceStepIndex));
                }} 
              /> 
              {mpSolutions.length > 0 && <span className="badge">{mpSolutions.length}</span>}
            </li>}
            <li className="language-selector-trigger">
              {renderLangSelectorTrigger()}
            </li>
            {/* <li className={showDropdown ? 'dropdown-li opened' : 'dropdown-li'} ref={dropdownLiEl}>
              <a href="/" className="d-flex" onClick={toggleDropdownHandler}>
                { country === 'default'
                  ? <MdLanguage />
                  : countries.filter(nextCountry => nextCountry.name === country)[0]?.flag
                }
                <MdKeyboardArrowDown />
              </a>
              { showDropdown && <ul className="lang-dropdown">
                  {countries.map((country, i) => (
                    <li key={i} className={language === country.lang ? 'active' : ''}>
                      <Country
                        small
                        name={country.name}
                        flag={country.flag}
                        language={country.lang}
                        onCountrySelect={() => countrySelectHandler(country.lang, country.name)}
                      />
                    </li>
                  ))}
                </ul>
              }
            </li> */}
            <li className="user-li">
              { user ?
                <div className="dashboard__content_top-right">
                  <div className={showUserDropdown ? "user active" : "user"} onClick={() => setShowUserDropdown(!showUserDropdown)}>
                    <MdAccountCircle />
                    <span>{ user.firstName } { user.lastName }</span>
                    <MdExpandMore />

                    {showUserDropdown && <ul className="user__dropdown" ref={userDropdownEl}>
                      <li><Link to="/dashboard"><MdDashboard /> <Translate name="myProjects" /></Link></li>
                      <li><Link to="/dashboard/account"><i className="custom-icon-contact-page"></i> <Translate name="myAccount" /></Link></li>
                      <li><Link to="" onClick={logoutHandler}><MdLockOpen /> <Translate name="logout" /></Link></li>
                    </ul>}
                  </div>
                </div>
                :
                <Link to="/signin">
                  <MdAccountCircle />
                </Link>
              }
            </li>
          </ul>
        </nav>
      </div>

      {showLanguageSelectorModal && <LanguageSelectorModal 
        onClose={handleLanguageSelectorModalClose} 
      />}

      {stepLoading && <DashboardLoader />}
    </header>
  );
}

export default Header;