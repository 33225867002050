import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Modal = ({ onClose, children, small, contentClassName, modalClass }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'visible';
    }
  }, []);

  let mainClass = 'modal'
  let modalBodyClasses = 'modal__body';

  if(modalClass) {
    mainClass += ` ${modalClass}`
  }
  if(small) {
    modalBodyClasses += ' modal__body--small';
  }
  if(contentClassName) {
    modalBodyClasses += ` ${contentClassName}`
  }

  return ReactDOM.createPortal(
    <div className={mainClass}>
      <div className="modal__bg" onClick={onClose}></div>
      <div className={modalBodyClasses}>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),  
    PropTypes.element                   
  ]).isRequired,
  small: PropTypes.bool
}

export default Modal;