import { useContext } from 'react';
import PropTypes from 'prop-types';

import { LangContext } from '../context/lang/langState';

const Translate = ({ name }) => {
  const { translate } = useContext(LangContext);
  return translate(name);
}

Translate.propTypes = {
  name: PropTypes.string.isRequired
}

export default Translate;
