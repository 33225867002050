import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MdBookmarkBorder, MdFileDownload } from 'react-icons/md';
import SVG from 'react-inlinesvg';

import Button from './Button';
import SolutionInfoIcon from './SolutionInfoIcon';
import Translate from '../../i18n/Translate';
import Loader from './Loader';
import downloadIcon from '../../assets/images/download-icon.svg';
import { LangContext } from '../../context/lang/langState';
import config from '../../config.json'

const SolutionInfo = ({ onPrintButtonClick, onSaveButtonClick, printBtnText, installBtnText, onInstallButtonClick, hasInstallationDetails, saveBtnText, saveBtnDisabled, icons, printIcon, showMPBtn, onAddToMP = () => {}, addToMPBtnDisabled, isLoadingDocumentation, systemVariantFields, system }) => {

  const { translate, apiLanguage } = useContext(LangContext)

  const descriptionTableArray = () => {
    const arr = []

    // Product
    
    let line = ''
    if(system?.fields?.commercialName?.value?.displayValue) {
      let commName
      if(typeof system.fields.commercialName.value.displayValue === 'string') {
        commName = system.fields.commercialName.value.displayValue
      } else {
        commName = system.fields.commercialName.value.displayValue[apiLanguage] || system.fields.commercialName.value.displayValue['en']
      }
      line += translate('DETAIL.product') + ': ' + commName
    } else {
      let commName = valueForKey('sysVarCommercialName')
      if(commName.value && typeof commName.value === 'string') {
        line += translate('DETAIL.product') + ': ' + commName.value
      } else if(commName.value?.displayValue) {
        line += translate('DETAIL.product') + ': ' + commName.value.displayValue
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Fire rating performance
    line = ''
    let sysVarFireRatingPerformanceE = valueForKey('sysVarFireRatingPerformanceE')
    let sysVarFireRatingPerformanceEI = valueForKey('sysVarFireRatingPerformanceEI')    
    let sysVarPipeEndConfiguration = valueForKey('sysVarPipeEndConfiguration')
    if(sysVarFireRatingPerformanceE.value || sysVarFireRatingPerformanceEI.value) {
      line += translate('DETAIL.fireRatingPerformance') + ': ' + sysVarFireRatingPerformanceE.value
      if(sysVarFireRatingPerformanceE.value && sysVarFireRatingPerformanceEI.value) {
        line += ' - ' + sysVarFireRatingPerformanceEI.value + ' ' + sysVarPipeEndConfiguration.value
      } else {
        line += ' ' + sysVarPipeEndConfiguration.value
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Fire rating direction
    line = ''
    let sysVarFireRatingDirection = valueForKey('sysVarFireRatingDirection')
    if(sysVarFireRatingDirection.value) {
      line += translate('DETAIL.fireRatingDirection') + ': ' + sysVarFireRatingDirection.value
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Service type
    line = ''
    let sysVarPenetrationProductType = valueForKey('sysVarPenetrationProductType')
    let sysVarPipeDiameter  = valueForKey('sysVarPipeDiameter')
    let sysVarPipeDiameterMax = valueForKey('sysVarPipeDiameterMax')
    let sysVarServiceMaterial = valueForKey('sysVarServiceMaterial')
    if(
      (sysVarPenetrationProductType.value && sysVarPenetrationProductType.rawValue !== 'NONE') ||
      sysVarPipeDiameter.value ||
      sysVarPipeDiameterMax.value ||
      (sysVarServiceMaterial.value && sysVarServiceMaterial.rawValue !== 'NONE')
    ) {
      line += translate('DETAIL.serviceType') + ': '
      if(sysVarPenetrationProductType.value) line += sysVarPenetrationProductType.value
      let diameter = ''

      if(sysVarPipeDiameter.value) diameter += + sysVarPipeDiameter.value
      if(sysVarPipeDiameter.value && sysVarPipeDiameterMax.value) diameter += '-'
      if(sysVarPipeDiameterMax.value) diameter += sysVarPipeDiameterMax.value
      if(sysVarPipeDiameter.value || sysVarPipeDiameterMax.value) diameter += 'mm'
      if(diameter) line += sysVarPenetrationProductType.value && ' ' + diameter
      if(sysVarServiceMaterial.value) line += ', ' + sysVarServiceMaterial.value
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Construction
    line = ''
    let sysVarCompartmentationType = valueForKey('sysVarCompartmentationType')
    let sysVarCompartmentationThickness = valueForKey('sysVarCompartmentationThickness')
    if(sysVarCompartmentationType.value) {
      line += translate('DETAIL.construction') + ': ' + sysVarCompartmentationType.value + ' ' + sysVarCompartmentationThickness.value + 'mm'
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Service Detail
    line = ''
    let sysVarProductDiameter = valueForKey('sysVarProductDiameter')
    let sysVarPipeWallThickness = valueForKey('sysVarPipeWallThickness')
    let sysVarPipeWallThicknessMax = valueForKey('sysVarPipeWallThicknessMax')
    let sysVarPenetrationPosition = valueForKey('sysVarPenetrationPosition')
    if(sysVarProductDiameter.value) {
      line += translate('DETAIL.serviceDetails') + ': ø' + sysVarProductDiameter.value + 'mm'
      if(sysVarPipeWallThickness.value) {
        line += ', ' + translate('DETAIL.pipeThickness') + ': ' + sysVarPipeWallThickness.value
        if(sysVarPipeWallThicknessMax.value) line += '-' + sysVarPipeWallThicknessMax.value
        line += 'mm'
        if(sysVarPenetrationPosition.value) {
          line += ', ' + sysVarPenetrationPosition.value
        }
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Insulation (in place)
    line = ''
    let sysVarInsulationInPlaceType = valueForKey('sysVarInsulationInPlaceType')
    let sysVarInsulationInPlaceThickness = valueForKey('sysVarInsulationInPlaceThickness')
    let sysVarInsulationInPlaceThicknessMax = valueForKey('sysVarInsulationInPlaceThicknessMax')
    let sysVarInsulationInPlaceConfiguration = valueForKey('sysVarInsulationInPlaceConfiguration')
    let sysVarInsulationInPlaceClass = valueForKey('sysVarInsulationInPlaceClass')
    let sysVarInsulationInPlaceDensity = valueForKey('sysVarInsulationInPlaceDensity')
    let sysVarInsulationInPlaceDensityMax = valueForKey('sysVarInsulationInPlaceDensityMax')
    if(sysVarInsulationInPlaceType.value) {
      line += translate('DETAIL.insulationInPlace') + ': ' + sysVarInsulationInPlaceType.value
      if(sysVarInsulationInPlaceThickness.value || sysVarInsulationInPlaceThicknessMax.value) {
        line += ', ' + translate('DETAIL.thickness') + ': '
      }
      if(sysVarInsulationInPlaceThickness.value) {
        line += sysVarInsulationInPlaceThickness.value
      }
      if(sysVarInsulationInPlaceThickness.value && sysVarInsulationInPlaceThicknessMax.value) {
        line += '-'
      } else if(!sysVarInsulationInPlaceThickness.value && sysVarInsulationInPlaceThicknessMax.value) {
        line += '≤'
      }
      if(sysVarInsulationInPlaceThicknessMax.value) {
        line += sysVarInsulationInPlaceThicknessMax.value
      }
      if(sysVarInsulationInPlaceThickness.value || sysVarInsulationInPlaceThicknessMax.value) {
        line += 'mm'
      }
      if(sysVarInsulationInPlaceConfiguration.value) {
        line += ', ' + translate('DETAIL.configuration') + ': ' + sysVarInsulationInPlaceConfiguration.value
      }
      if(sysVarInsulationInPlaceClass.value) {
        line += ', ' + translate('DETAIL.class') + ': ' + sysVarInsulationInPlaceClass.value
      }
      if(sysVarInsulationInPlaceDensity.value || sysVarInsulationInPlaceDensityMax.value) {
        let densityUnit = sysVarInsulationInPlaceDensity.unit || sysVarInsulationInPlaceDensityMax.unit || 'kg/m³'
        line += ', ' + translate('DETAIL.density') + ': '
        if(sysVarInsulationInPlaceDensity.value) {
          line += sysVarInsulationInPlaceDensity.value
        }
        if(sysVarInsulationInPlaceDensity.value && sysVarInsulationInPlaceDensityMax.value) {
          line += '-'
        }
        if(sysVarInsulationInPlaceDensityMax.value) {
          line += sysVarInsulationInPlaceDensityMax.value
        }
        line += densityUnit
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Insulation (to be installed)
    line = ''
    let sysVarInsulationToBeInstalledType = valueForKey('sysVarInsulationToBeInstalledType')
    let sysVarInsulationToBeInstalledThickness = valueForKey('sysVarInsulationToBeInstalledThickness')
    let sysVarInsulationToBeInstalledThicknessMax = valueForKey('sysVarInsulationToBeInstalledThicknessMax')
    let sysVarInsulationToBeInstalledDensity = valueForKey('sysVarInsulationToBeInstalledDensity')
    let sysVarInsulationToBeInstalledDensityMax = valueForKey('sysVarInsulationToBeInstalledDensityMax')
    let sysVarInsulationToBeInstalledConfiguration = valueForKey('sysVarInsulationToBeInstalledConfiguration')
    let sysVarInsulationToBeInstalledLocation = valueForKey('sysVarInsulationToBeInstalledLocation')
    let sysVarServicePartLocation = valueForKey('sysVarServicePartLocation')
    if(sysVarInsulationToBeInstalledType.value) {
      line += translate('DETAIL.insulationToBeInstalled') + ': ' + sysVarInsulationToBeInstalledType.value
      if(sysVarInsulationToBeInstalledThickness.value || sysVarInsulationToBeInstalledThicknessMax.value) {
        line += ', ' + translate('DETAIL.thickness') + ': '
        if(sysVarInsulationToBeInstalledThickness.value) {
          line += sysVarInsulationToBeInstalledThickness.value
        }
        if(sysVarInsulationToBeInstalledThickness.value && sysVarInsulationToBeInstalledThicknessMax.value) {
          line += '-'
        }
        if(sysVarInsulationToBeInstalledThicknessMax.value) {
          line += sysVarInsulationToBeInstalledThicknessMax.value
        }
        line += 'mm'
      }
      if(sysVarInsulationToBeInstalledDensity.value || sysVarInsulationToBeInstalledDensityMax.value) {
        let densityUnit = sysVarInsulationToBeInstalledDensity.unit || sysVarInsulationToBeInstalledDensityMax.unit || 'kg/m³'
        line += ', ' + translate('DETAIL.density') + ': '
        if(sysVarInsulationToBeInstalledDensity.value) {
          line += sysVarInsulationToBeInstalledDensity.value
        }
        if(sysVarInsulationToBeInstalledDensity.value && sysVarInsulationToBeInstalledDensityMax.value) {
          line += '-'
        }
        if(sysVarInsulationToBeInstalledDensityMax.value) {
          line += sysVarInsulationToBeInstalledDensityMax.value
        }
        line += densityUnit
      }
      if(sysVarInsulationToBeInstalledConfiguration.value) {
        line += ', ' + translate('DETAIL.configuration') + ': ' + sysVarInsulationToBeInstalledConfiguration.value
      }
      if(sysVarInsulationToBeInstalledLocation.value) {
        line += ', ' + translate('DETAIL.location') + ': ' + sysVarInsulationToBeInstalledLocation.value
      }
      if(sysVarServicePartLocation.value) {
        line += ', ' + translate('DETAIL.servicePart') + ': ' + sysVarServicePartLocation.value
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Annular gap
    line = ''
    let sysVarAnnularGapDescription = valueForKey('sysVarAnnularGapDescriptionCVL') || valueForKey('sysVarAnnularGapDescription')
    let sysVarAnnularGapWidth = valueForKey('sysVarAnnularGapWidth')
    let sysVarAnnularGapWidthMax = valueForKey('sysVarAnnularGapWidthMax')
    let sysVarAnnularGapRemarks = valueForKey('sysVarAnnularGapRemarks')
    if(sysVarAnnularGapDescription.value || sysVarAnnularGapWidth.value || sysVarAnnularGapWidthMax.value) {
      line += translate('DETAIL.annularGap') + ': '
      if(sysVarAnnularGapDescription.value) {
        line += sysVarAnnularGapDescription.value
      }
      if(exists(sysVarAnnularGapWidth.value) || exists(sysVarAnnularGapWidthMax.value)) {
        line += (sysVarAnnularGapDescription.value && ', ') + translate('DETAIL.width') + ': '
      }
      if(exists(sysVarAnnularGapWidth.value)) {
        line += sysVarAnnularGapWidth.value
      }
      if(exists(sysVarAnnularGapWidth.value) && exists(sysVarAnnularGapWidthMax.value)) {
        line += '-'
      }
      if(exists(sysVarAnnularGapWidthMax.value)) {
        line += sysVarAnnularGapWidthMax.value
      }
      if(exists(sysVarAnnularGapWidth.value) || exists(sysVarAnnularGapWidthMax.value)) {
        line += 'mm'
      }
      if(sysVarAnnularGapRemarks.value) {
        line += ', ' + translate('DETAIL.remark') + ': ' + sysVarAnnularGapRemarks.value
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Aperture type
    line = ''
    let sysVarAperturePartType = valueForKey('sysVarAperturePartType')
    let sysVarAperturePartThickness = valueForKey('sysVarAperturePartThickness')
    let sysVarAperturePartMaxOpening = valueForKey('sysVarAperturePartMaxOpening')
    let sysVarAperturePartConfiguration = valueForKey('sysVarAperturePartConfiguration')
    let sysVarServicePartRemarks = valueForKey('sysVarServicePartRemarks')
    if(
      exists(sysVarAperturePartType.value) || 
      exists(sysVarAperturePartThickness.value) || 
      exists(sysVarAperturePartMaxOpening.value) ||
      exists(sysVarAperturePartConfiguration.value)
    ) {
      line += translate('DETAIL.aperturePart') + ': '
      let apertureTypeLine = ''
      if(exists(sysVarAperturePartType.value)) {
        apertureTypeLine += sysVarAperturePartType.value
      }
      if(exists(sysVarAperturePartThickness.value)) {
        apertureTypeLine += (apertureTypeLine && ', ') + translate('DETAIL.thickness') + ': ' + sysVarAperturePartThickness.value
      }
      if(exists(sysVarAperturePartMaxOpening.value)) {
        apertureTypeLine += (apertureTypeLine && ', ') + translate('DETAIL.maxOpening') + ': ' + sysVarAperturePartMaxOpening.value
      }
      if(exists(sysVarAperturePartConfiguration.value)) {
        apertureTypeLine += (apertureTypeLine && ', ') + translate('DETAIL.configuration') + ': ' + sysVarAperturePartConfiguration.value
      }
      if(exists(sysVarServicePartRemarks.value)) {
        apertureTypeLine += (apertureTypeLine && ', ') + translate('DETAIL.remark') + ': ' + sysVarServicePartRemarks.value
      }            
      line += apertureTypeLine
    } 
    if(line.trim()) {
      arr.push(line)
    }

    // Service part
    line = ''
    let sysVarServicePartType = valueForKey('sysVarServicePartType')
    // let sysVarServicePartLocation = valueForKey('sysVarServicePartLocation')
    let sysVarServicePartConfiguration = valueForKey('sysVarServicePartConfiguration')
    let sysVarNumberOfLayers = valueForKey('sysVarNumberOfLayers')
    let sysVarServicePartFixing = valueForKey('sysVarServicePartFixing')
    if(sysVarServicePartType.value) {
      line += translate('DETAIL.servicePart') + ': ' + sysVarServicePartType.value
      if(sysVarServicePartLocation.value) {
        line += ', ' + translate('DETAIL.location') + ': ' + sysVarServicePartLocation.value
      }
      if(sysVarServicePartConfiguration.value) {
        line += ', ' + translate('DETAIL.configuration') + ': ' + sysVarServicePartConfiguration.value
      }
      if(exists(sysVarNumberOfLayers.value)) {
        line += ', ' + translate('DETAIL.numberOfLayers') + ': ' + sysVarNumberOfLayers.value
      }
      if(sysVarServicePartFixing.value) {
        line += ', ' + translate('DETAIL.fixings') + ': ' + sysVarServicePartFixing.value
      }
      if(sysVarServicePartRemarks.value) {
        line += ', ' + translate('DETAIL.remark') + ': ' + sysVarServicePartRemarks.value
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Minimum suspension
    line = ''
    let sysVarMinimumSuspension = valueForKey('sysVarMinimumSuspension')
    let sysVarSuspensionLocation = valueForKey('sysVarSuspensionLocation')
    if(exists(sysVarMinimumSuspension.value) && sysVarSuspensionLocation.rawValue !== 'NONE') {
      line += translate('DETAIL.minimumSuspension') + ': '
      if(exists(sysVarSuspensionLocation.value)) {
        line += sysVarSuspensionLocation.value + ', ' + sysVarMinimumSuspension.value + 'mm'
      }
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Report number
    line = ''
    let sysVarFireReportNumber = valueForKey('sysVarFireReportNumber')
    if(exists(sysVarFireReportNumber)) {
      line += translate('DETAIL.reportNumber') + ': ' + sysVarFireReportNumber.value
    }
    if(line.trim()) {
      arr.push(line)
    }

    // Test standard
    line = ''
    let sysVarTestingStandard = valueForKey('sysVarTestingStandard')
    if(exists(sysVarTestingStandard.value)) {
      line += translate('DETAIL.testStandard') + ': ' + sysVarTestingStandard.value
    }
    if(line.trim()) {
      arr.push(line)
    }  

    return arr
  }

  const valueForKey = (key, object = systemVariantFields) => {
    // including rawValue for NONE checks
    try {
      let fieldObj = object[key]
      if(!fieldObj) {
        return { value: '', rawValue: '', unit: '' }
      }
      let valueObj = fieldObj.value
      let unit = valueObj?.unit || ''
      if(!exists(valueObj) && exists(fieldObj.values)) {
        if(fieldObj.values.length === 0) {
          return { value: '', rawValue: 'NONE', unit: unit }
        }
        valueObj = { value: '', rawValue: '' }
        for(let i in fieldObj.values) {
          let v = fieldObj.values[i]
          let dv
          let rv = v.rawValue || ''
          if(exists(v.displayValue) && exists(v.displayValue[apiLanguage])) {
            dv = v.displayValue[apiLanguage]
          } else if(v.displayValue && v.displayValue.en) {
            dv = v.displayValue.en
          } else {
            dv = rv
          }
          if(exists(valueObj.value)) valueObj.value += '; '
          valueObj.value += dv
          if(exists(valueObj.rawValue)) valueObj.rawValue += '; '
          valueObj.rawValue += rv
        }
        return { value: valueObj.value, rawValue: valueObj.rawValue, unit: unit }
      } 
      if(!exists(valueObj)) {
        return { value: '', rawValue: '', unit: unit }
      }
      if(!exists(valueObj.displayValue) && exists(valueObj.rawValue)) {
        return { value: valueObj.rawValue, rawValue: valueObj.rawValue, unit: unit }
      }
      if(!exists(valueObj.displayValue[apiLanguage]) && exists(valueObj.displayValue.en)) {
        return { value: valueObj.displayValue.en, rawValue: valueObj.rawValue, unit: unit }
      }
      return { value: valueObj.displayValue[apiLanguage], rawValue: valueObj.rawValue, unit: unit }
    } catch(err) {
      console.log('error for', key)
      console.log(err)
      return { value: '', rawValue: '', unit: '' }
    }
  }

  // used for numeric values which could be 0 and fail basic boolean check
  const exists = (value) => {
    return typeof value !== 'undefined' && value !== null && value !== ''
  }

  return (
    <div className="solution-info">
      {/* <h2 className="solution-info__title">{title}</h2> */}
      <div className="solution-info__desc">
        { descriptionTableArray().map((text, index) => {
          const indexOfFirstColon = text.indexOf(':');
          const label = text.slice(0, indexOfFirstColon);
          const txt = text.slice(indexOfFirstColon + 1);
          return <p key={index}><strong>{label}</strong> <span>{txt}</span></p>
        })}
      </div>
      {/* <div className="solution-info__desc">
        {descArr.map((text, index) => {
        // {descriptionTableArray().map((text, index) => {
          // const [label, ...txt] = text.trim().split(':');
          const indexOfFirstColon = text.indexOf(':');
          const label = text.slice(0, indexOfFirstColon);
          const txt = text.slice(indexOfFirstColon + 1);
          return <p key={index}><strong>{label}</strong> <span>{txt}</span></p>
        })}
      </div> */}
      <div className="solution-info__icons">
        {icons.map((icon, index) => (
          <SolutionInfoIcon key={index} iconEl={icon} />
        ))}
      </div>
      <div className="solution-info__actions">
        <Button
          text={printBtnText}
          onButtonClick={onPrintButtonClick}
          iconEl={printIcon}
          centered
          primary
          large
        />
        <Button
          text={saveBtnText}
          onButtonClick={onSaveButtonClick}
          iconEl={<MdBookmarkBorder />}
          centered
          primary
          large
          disabled={saveBtnDisabled}
        />
      </div>
      { hasInstallationDetails && (
        <div className="solution-info__documentation">
          <Button
            text={isLoadingDocumentation ? <Loader normalWhite small /> : installBtnText}
            onButtonClick={onInstallButtonClick}
            iconEl={<SVG src={downloadIcon} />}
            centered
            outlinePrimary
            large
            bold
            loading={isLoadingDocumentation}
            disabled={isLoadingDocumentation}
          />
        </div>
      )}
      {showMPBtn && <Button 
        text={!addToMPBtnDisabled ? <Translate name="addSolutionToMP" /> : <Translate name="alreadyAdded" />}
        onButtonClick={onAddToMP}
        medium
        fullwidth
        className="solution-info__mp-btn"
        disabled={addToMPBtnDisabled}
      />}
      <div className="solution-info__disclaimer">
        <p className="solution-info__disclaimer_title"><Translate name="disclaimer" /></p>
        <p className="solution-info__disclaimer_text"><Translate name="disclaimerText" /></p>
      </div>
    </div>
  );
}

SolutionInfo.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  printBtnText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  saveBtnText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  icons: PropTypes.arrayOf(PropTypes.node)
}

export default SolutionInfo;