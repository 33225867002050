import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdCloudDownload } from 'react-icons/md';
import { isMobile } from "react-device-detect";
import Translate from '../../i18n/Translate';

const ZoomImage = ({ img, setLoadingImage }) => {
  const zoom = useRef();
  const zoomContainer = useRef();
  const handleMouseMove = (e) => {
    const { left, top, width, height } = zoom.current.getBoundingClientRect();
    // console.log(width, height, top, e.pageY, zoom.current.offsetTop);
    const x = ((e.pageX - left - window.pageXOffset) / width) * 100;
    const y = ((e.pageY - top - window.pageYOffset) / height) * 100;
    zoomContainer.current.style.left = -x + '%';
    zoomContainer.current.style.top = -y + '%';
  }
  return(
    <div className="zoom-image" onMouseMove={handleMouseMove} ref={zoom}>
      <img src={img} alt="" onLoad={() => setLoadingImage(false)}/>
      <div className="zoom-image__container" style={{backgroundImage: `url(${img})`}} ref={zoomContainer}></div>
      {!isMobile && <a href={img.replace('fetch_asset', 'download_asset')}><MdCloudDownload /> <Translate name="downloadImage" /></a>}
    </div>
  );
}

ZoomImage.propTypes = {
  img: PropTypes.string.isRequired
}

export default ZoomImage;