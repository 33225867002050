import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TextLink = ({ to, text }) => {
  return(
    <Link to={to} className="text-link">{text}</Link>
  );
}

TextLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired
}

export default TextLink;