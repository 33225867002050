import React from 'react';
import ReactDOM from 'react-dom';

import Loader from './Loader';

const PageLoader = ({ hidden }) => {
  return ReactDOM.createPortal(
    <div className={hidden ? "page-loader hidden" : "page-loader"}>
      <Loader primary normal />
    </div>,
    document.getElementById('loader-root')
  );
}

export default PageLoader;