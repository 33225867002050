import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import Input from '../UI/Input';
import Select from '../UI/Select';
import FileUpload from '../UI/FileUpload';
import Translate from '../../i18n/Translate';
import Button from '../UI/Button';
import { LangContext } from '../../context/lang/langState';
import { UserContext } from '../../context/userState';
import countries from '../../constants/countries.json';

const MyCompanyForm = ({onSubmit, onCancel}) => {
  const initialData = {
    company_name: {
      value: ''
    },
    contact_email: {
      value: ''
    },
    phone_number: {
      value: ''
    },
    vat_number: {
      value: ''
    },
    street: {
      value: ''
    },
    street_number: {
      value: ''
    },
    city: {
      value: ''
    },
    zip_code: {
      value: ''
    },
    country: {
      value: ''
    },
    type: {
      value: ''
    },
    website: {
      value: ''
    },
    logo: {
      value: {},
      file: true,
      allowedTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'],
      maxFileSize: 1024
    }
  }
  const { formData, errors, changeHandler, customSelectChangeHandler, setErrors, setDataAndErrors } = useForm(initialData, validate);
  const history = useHistory();
  const { translate } = useContext(LangContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if(!user) {
      return
    }
    let companyData = {
      company_name: {
        value: user.company?.name || ''
      },
      contact_email: {
        value: user.company?.email || ''
      },
      phone_number: {
        value: user.company?.phone || ''
      },
      vat_number: {
        value: user.company?.vat_number || ''
      },
      street: {
        value: user.company?.address_street || ''
      },
      street_number: {
        value: user.company?.address_number || ''
      },
      city: {
        value: user.company?.address_city || ''
      },
      zip_code: {
        value: user.company?.address_zip || ''
      },
      country: {
        value: user.company?.address_country || ''
      },
      // type: {
      //   value: user.company?.type
      // },
      website: {
        value: user.company?.website || ''
      },
      logo: {
        value: {
          url: user.company?.logo || ''
        },
        file: true,
        allowedTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'],
        maxFileSize: 1024
      }
    }
    setDataAndErrors(companyData)
  }, [user])

  const submitHandler = (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true, translate);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      onSubmit(formData)
    }
  }

  return(
    <form className="form" onSubmit={submitHandler}>
      <div className="form__section">
        <div className="form__group form__group--break-sm">
          <Input 
            label={<Translate name="companyName" />}
            name="company_name"
            value={formData.company_name.value}
            onChange={changeHandler}
            error={errors.company_name}
            dashboard
          />
          <Input 
            label={<Translate name="contactEmail" />}
            name="contact_email"
            type="email"
            value={formData.contact_email.value}
            onChange={changeHandler}
            error={errors.contact_email}
            dashboard
          />
        </div>
        <div className="form__group form__group--break-sm">
          <Input 
            label={<Translate name="phoneNumber" />}
            name="phone_number"
            value={formData.phone_number.value}
            onChange={changeHandler}
            error={errors.phone_number}
            dashboard
          />
          <Input 
            label={<Translate name="vatNumber" />}
            name="vat_number"
            value={formData.vat_number.value}
            onChange={changeHandler}
            error={errors.vat_number}
            dashboard
          />
        </div>
      </div>

      <div className="form__section">
        <div className="form__group form__group--break-sm">
          <Input 
            label={<Translate name="street" />}
            name="street"
            value={formData.street.value}
            onChange={changeHandler}
            error={errors.street}
            dashboard
          />
          <Input 
            label={<Translate name="streetNumber" />}
            name="street_number"
            value={formData.street_number.value}
            onChange={changeHandler}
            error={errors.street_number}
            dashboard
            small
          />
        </div>
        <div className="form__group form__group--break-sm">
          <Input 
            label={<Translate name="city" />}
            name="city"
            value={formData.city.value}
            onChange={changeHandler}
            error={errors.city}
            dashboard
          />
          <Input 
            label={<Translate name="zipCode" />}
            name="zip_code"
            value={formData.zip_code.value}
            onChange={changeHandler}
            error={errors.zip_code}
            dashboard
            small
          />
        </div>
        <div className="form__group form__group--break-sm">
          <Select 
            className="select--dashboard"
            label={<Translate name="country" />}
            name="country"
            value={formData.country.value}
            onChange={customSelectChangeHandler}
            error={errors.country}
            options={[
              { value: '', label: <Translate name="chooseCountry" /> },
              ...countries
            ]}
            defaultText={<Translate name="chooseCountry" />}
          />
        </div>
      </div>

      <div className="form__group form__group--break-sm">
        {/* TODO maybe bring "type" back, when we get actual values */}
        {/* <Select 
          className="select--dashboard"
          label={<Translate name="type" />}
          name="type"
          value={formData.type.value}
          onChange={customSelectChangeHandler}
          error={errors.type}
          options={[
            { value: '', label: <Translate name="chooseType" /> },
            { value: 'type-1', label: 'Type 1' },
            { value: 'type-2', label: 'Type 2' }
          ]}
          defaultText={<Translate name="chooseType" />}
        /> */} 
        <Input 
          label={<Translate name="website" />}
          name="website"
          value={formData.website.value}
          onChange={changeHandler}
          error={errors.website}
          dashboard
        />
      </div>

      <FileUpload 
        label={<Translate name="logo" />}
        name="logo"
        // fileName={Object.keys(formData.logo.value).length > 0 && formData.logo.value[0].name}
        file={formData.logo.value}
        error={errors.logo}
        allowedTypes={formData.logo.allowedTypes}
        maxFileSize={formData.logo.maxFileSize}
        onChange={customSelectChangeHandler}
      />

      <div className="form__actions">
        <Button 
          text={<Translate name="cancel" />}
          outlineSecondary
          type="button"
          onButtonClick={onCancel}
        />
        <Button 
          text={<Translate name="saveChanges" />}
          secondary
        />
      </div>
    </form>
  );
}

export default MyCompanyForm;