import React, { useState, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MdDeleteForever, MdModeEdit, MdMoreHoriz, MdRemoveRedEye, MdDescription, MdCreateNewFolder
} from 'react-icons/md';
import PropTypes from 'prop-types';

import Button from './Button';
import Checkbox from './Checkbox';
import newProjectImage from '../../assets/images/project_new.png';
import projectImage from '../../assets/images/project.png';
import projectFolderImage from '../../assets/images/project_folder.png';
import Translate from '../../i18n/Translate';

const ProjectGrid = ({ projectId, title, solutions, newProject, onCheckboxChange, selected, folder, placeholder, onShowNewProjectModal, onClickFolder, onClickSolution, onRemoveFolder, onRenameFolder, onRemoveSolution, multiplePenetration, onClickMP = () => {} }) => {
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const history = useHistory();

  let projectClass = 'project-box project-grid';
  if(newProject) {
    projectClass += ' project-grid--new';
  }
  if(selected){
    projectClass += ' active';
  }
  if(multiplePenetration) {
    projectClass += ' project-grid--mp';
  }

  return (
    <div className={projectClass}>
      <div className="project-grid__inner">
        <section>
          <div className="project-grid__wrapper">
            {newProject ?
              <Fragment>
                <div className="project-grid__icon">
                  <img src={newProjectImage} alt="" />
                </div>
                <div className="project-grid__content">
                  <Button
                    text={<Translate name="newSolution" />}
                    className="uppercase mb-2"
                    iconEl={<MdDescription />}
                    outlineSecondary
                    fullwidth
                    centered
                    textSmall
                    bold
                    onButtonClick={() => history.push('/')}
                  />
                  <Button
                    text={<Translate name="newProject" />}
                    className="uppercase"
                    iconEl={<MdCreateNewFolder />}
                    secondary
                    fullwidth
                    centered
                    textSmall
                    bold
                    onButtonClick={() => onShowNewProjectModal(true)}
                  />
                </div>
              </Fragment>
              :
              <Fragment>
                {placeholder &&
                  <div className="project-grid__placeholder">
                    <div className="project-grid__placeholder_icon">
                      <div></div>
                    </div>
                    <div className="project-grid__placeholder_text">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
                  {!multiplePenetration ?
                    <Fragment>
                      <div className="project-grid__icon">
                        {folder ? <img src={projectFolderImage} alt="" /> : <img src={projectImage} alt="" />}
                        <div className="project-grid__icon_hover">
                          <Checkbox onChange={onCheckboxChange} checked={selected} />
                          <div className={showMoreDropdown ? 'more active' : 'more'}>
                            <div onClick={() => setShowMoreDropdown(!showMoreDropdown)}>
                              <MdMoreHoriz />
                            </div>
                            {showMoreDropdown && <ul className="more__dropdown">
                              { folder && <li onClick={() => onRenameFolder(projectId)}><MdModeEdit /> <Translate name="rename" /></li> }
                              <li onClick={() => folder ? onRemoveFolder(projectId) : onRemoveSolution(projectId)}><MdDeleteForever /> <Translate name="delete" /></li>
                            </ul>}
                          </div>
                          <Button
                            text={<Translate name="view" />}
                            iconEl={<MdRemoveRedEye />}
                            centered
                            transparent
                            bold
                            textSmall
                            className="uppercase"
                            onButtonClick={() => folder ? onClickFolder(projectId) : onClickSolution(projectId)}
                          />
                        </div>
                      </div>
                      <div className="project-grid__content">
                        <h4>{title}</h4>
                        {solutions > 1 && <p><span>{solutions}</span> <Translate name="solutionsInTheProject" /></p>}
                      </div>
                    </Fragment>
                    :
                    <Fragment>
                      <div className="project-grid__icon">
                        <div className="project-grid__icon_mp">
                          <img src={projectImage} alt="" />
                          <img src={projectImage} alt="" />
                          <img src={projectImage} alt="" />
                        </div>
                        <div className="project-grid__icon_hover">
                          <Checkbox onChange={onCheckboxChange} checked={selected} />
                          <div className={showMoreDropdown ? 'more active' : 'more'}>
                            <div onClick={() => setShowMoreDropdown(!showMoreDropdown)}>
                              <MdMoreHoriz />
                            </div>
                            {showMoreDropdown && <ul className="more__dropdown">
                              <li onClick={() => onRemoveSolution(projectId)}><MdDeleteForever /> <Translate name="delete" /></li>
                            </ul>}
                          </div>
                          <Button
                            text={<Translate name="view" />}
                            iconEl={<MdRemoveRedEye />}
                            centered
                            transparent
                            bold
                            textSmall
                            className="uppercase"
                            onButtonClick={onClickMP}
                          />
                        </div>
                      </div>
                      <div className="project-grid__content">
                        <h4><Translate name="multiplePenetration" /> <span>{solutions}</span></h4>
                      </div>
                    </Fragment>
                  }
                
              </Fragment>
            }
          </div>
        </section>
      </div>
    </div>
  );
}

ProjectGrid.propTypes = {
  title: PropTypes.string,
  solutions: PropTypes.number,
  newProject: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  selected: PropTypes.bool,
  folder: PropTypes.bool,
  placeholder: PropTypes.bool,
  onShowNewProjectModal: PropTypes.func
}

export default ProjectGrid;