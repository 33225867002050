import React from 'react';
import PropTypes from 'prop-types';
// import { MdReplay, MdSearch } from 'react-icons/md';

// import Button from '../UI/Button';
// import Translate from '../../i18n/Translate';

const Progress = ({ step, onNumericStepChange, stepNames }) => {
  const steps = [];

  for(let i = 0; i < step; i++) {
    if(i < step - 1) {
      steps.push(
        <div key={i} className={i === step - 1 ? 'progress__step active' : 'progress__step'} onClick={() => onNumericStepChange(i + 1)}>
          <p>{stepNames[i]}</p>
        </div>
      );
    }else {
      steps.push(
        <div key={i} className={i === step - 1 ? 'progress__step active' : 'progress__step'}>
          <p>{stepNames[i]}</p>
        </div>
      );
    }
  }

  // if(step < 10) {
  //   steps.push(
  //     <div key="next" className="progress__step next">
  //       <p><Translate name="nextStep" /></p>
  //     </div>
  //   );
  // }

  return(
    <div className="progress">
      {/* <div className="progress__top">
        {step > 1 &&
          <div className="d-flex justify-content-end">
            { step === 8 &&
              <Button
                text={<Translate name="searchWithAnotherProduct" />}
                iconEl={<MdSearch />}
                onButtonClick={() => console.log('search...')}
                className="icon-small"
              />
            }
            <Button
              text={<Translate name="resetTheSelector" />}
              iconEl={<MdReplay />}
              onButtonClick={() => onStepChange(1)}
              rotateIconOnHover
              className="hide-text-sm"
            />
          </div>
        }
      </div> */}
      <div className="progress__main">
        <div className={`progress__main_inner step-${step}`}>
          {steps}
        </div>
      </div>
    </div>
  );
}

Progress.propTypes = {
  step: PropTypes.number.isRequired,
  onNumericStepChange: PropTypes.func.isRequired,
  stepNames: PropTypes.arrayOf(PropTypes.node)
}

export default Progress;