import React, { createContext, useReducer } from 'react';

import { ADD_TO_MP, REMOVE_FROM_MP, SET_MP_MODE, CLEAR_MP } from './types';

const mpReducer = (state, action) => {
  switch(action.type) {
    case ADD_TO_MP:
      return {
        ...state,
        solutions: action.payload.solutions,
        solutionsIds: action.payload.solutionsIds
      }
    case REMOVE_FROM_MP:
      return {
        ...state,
        solutions: action.payload.solutions,
        solutionsIds: action.payload.solutionsIds
      }
    case SET_MP_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case CLEAR_MP:
      return {
        ...state,
        solutions: [],
        solutionsIds: []
      }
    default:
      return state;
  }
};

export const MPContext = createContext();

const MPState = (props) => {
  const solutionsFromLS = localStorage.getItem('mpSolutions');
  const sIds = solutionsFromLS ? JSON.parse(solutionsFromLS).map(s => s.id) : [];

  const initialState = {
    solutions: solutionsFromLS ? JSON.parse(solutionsFromLS) : [],
    solutionsIds: sIds,
    mode: false
  }
  const [state, dispatch] = useReducer(mpReducer, initialState);

  const addSolutionToMP = (solution) => {
    const solutions = [...state.solutions];
    const solutionsIds = [...state.solutionsIds];
    solutions.unshift(solution);
    solutionsIds.unshift(solution.id);
    localStorage.setItem('mpSolutions', JSON.stringify(solutions));
    dispatch({
      type: ADD_TO_MP,
      payload: {
        solutions,
        solutionsIds
      }
    });
  }

  const removeSolutionFromMP = (solution) => {
    const solutions = [...state.solutions];
    const solutionsIds = [...state.solutionsIds];
    const filteredSolutions = solutions.filter(s => s.id !== solution.id); 
    const filteredSolutionsIds = solutionsIds.filter(sId => sId !== solution.id );
    localStorage.setItem('mpSolutions', JSON.stringify(filteredSolutions));
    dispatch({
      type: REMOVE_FROM_MP,
      payload: {
        solutions: filteredSolutions,
        solutionsIds: filteredSolutionsIds
      }
    });
  }

  const setMPMode = (value) => {
    dispatch({
      type: SET_MP_MODE,
      payload: value
    });
  }

  const clearMP = () => {
    localStorage.setItem('mpSolutions', JSON.stringify([]));
    dispatch({
      type: CLEAR_MP
    });
  }

  return(
    <MPContext.Provider
      value={{
        mpSolutions: state.solutions,
        mpSolutionsIds: state.solutionsIds,
        mpMode: state.mode,
        addSolutionToMP,
        removeSolutionFromMP,
        setMPMode,
        clearMP
      }}
    >
      {props.children}
    </MPContext.Provider>
  );
}

export default MPState;