import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Alert from './Alert';

let timeout;

const Notification = ({ type, message, onClose }) => {
  useEffect(() => {
    if(timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      onClose();
    }, 3000);
    // eslint-disable-next-line
  }, [message]);

  return ReactDOM.createPortal(
    <div className="notification">
      <Alert type={type} message={message} />
    </div>,
    document.getElementById('modal-root')
  );
}

export default Notification;